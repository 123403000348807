<div class="responsive" style="max-width: 100% !important; overflow-x: hidden !important;margin-bottom: -31px;" *ngIf="patient">
  <section class="fond2">
    <section class="container-fluid mb-3 p-sm-5 p-3">
      <div class="row mb-3">
        <div class="col-lg-5 col-md-6 col-sm-12">
          <h5>Détails patient</h5>
        </div>
      </div>
      <div class="row mb-n3">
        <div class="col-12">
          <!-- Patient Details Row -->
          <div class="row pat align-items-center">
            <div class="col-md-4 col-sm-12 mb-2 mb-md-0">
              <b>{{patient.nom}} {{patient.prenom}}</b>
            </div>
            <div class="col-md-1 col-sm-12 text-center mb-2 mb-md-0">
              <img src="assets/images/patsDetails/point2.png" class="pointImg">
            </div>
            <div class="col-md-4 col-sm-12 mb-2 mb-md-0">
              <b>{{patient.identifiantMedivisto}}</b>
            </div>
          </div>
        </div>

        <div class="col-12">
          <!-- Buttons Row -->
          <div class="row butts">
            <div class="col-md-1 col-sm-1 col-1 mb-2">
              <div class=" buttoncadre" *ngIf="patient.createdByDoctor">
                <a [routerLink]="['/medecin/patient', patient._id]" title="Modifier patient">
                  <img class="iconButt " src="assets/images/patsDetails/icon6.png"></a>
              </div>
            </div>
            <div class="col-md-1 col-sm-1 col-1 mb-2">
              <div class=" buttoncadre" *ngIf="medecin">
                <a title="Historique des consultations" (click)="scrollHistorique(historiqueExamen)">
                  <img class="iconButt " src="assets/images/patsDetails/icon2.png">
                </a>
              </div>
            </div>
            <div class="col-md-1 col-sm-1 col-1 mb-2">
              <div class=" buttoncadre" *ngIf="medecin">
                <a title="Ajouter une consultation" (click)="getPreviousConsultation(patient._id)" data-toggle="modal" data-target="#myModalExamen">
                  <img class="iconButt " src="assets/images/add-icon-green.png"></a>
              </div>
            </div>
          </div>

        </div>

        <!-- modal ajout motif de consultation -->
        <div class="modal fade" id="myModalExamen" tabindex="-1" role="dialog" aria-labelledby="myModalExamen" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content modal-content-scan">
              <div class="modal-header d-flex" style="border-bottom: 1px solid #e0e0e0;margin-top: auto;">
                <h4 class="modal-title" *ngIf="listpreviousconsultations && listpreviousconsultations.length <1">
                  Motif de consultation
                </h4>
                <h4 class="modal-title" *ngIf="listpreviousconsultations && listpreviousconsultations.length >0">
                  Modifier la consultation
                </h4>
                <a class="exit" aria-label="Close" data-dismiss="modal" (click)="deleteSelectedMotif($event)"><i class="fa-regular fa-circle-xmark"></i></a>
              </div>
              <div class="modal-body">
                <div>
                  <form [formGroup]="formMotif">
                    <div class="form-row">
                      <label style="font-size: 14.5px;position: relative;top: 4px; " *ngIf="listpreviousconsultations && listpreviousconsultations.length <1">Motif(s)</label>
                      <div class="form-group col">
                        <div class="col-10" *ngIf="listpreviousconsultations && listpreviousconsultations.length <1">
                          <div class="col-12">
                            <ng-select [(ngModel)]="selectedMotif" [items]="motifList" [virtualScroll]="true" [clearable]="true" clearAllText="Effacer le texte" addTagText="Ajouter :" formControlName="motif" [addTag]="true" (change)="addInputMotif($event)"></ng-select>
                          </div>
                          <div *ngIf="formMotif.controls.motif?.invalid&& (formMotif.controls.motif?.dirty || formMotif.controls.motif?.touched)&& formMotif.controls.motif?.errors  " class="text-center">
                            <div *ngIf=" formMotif.controls.motif?.errors.required && selectedMotifs.length===0" style="color: red;font-size: 15px;">Le motif est obligatoire</div>
                            <div *ngIf="formMotif.controls.motif?.errors.minlength" style="color: red;font-size: 15px;">{{motifMinLength}}</div>
                            <div *ngIf="formMotif.controls.motif?.errors.maxlength" style="color: red;font-size: 15px;">{{motifMaxLength}}</div>
                            <div *ngIf="formMotif.controls.motif?.errors.invalidMotif" style="color: red;font-size: 15px;">{{champSpecialCaracters}}</div>
                          </div>
                          <div style="text-align: center;"><span *ngFor="let mot of selectedMotifs">{{mot}} <div class="deleted-btn" title="Supprimer" style="color: red;display: contents;cursor: pointer;" (click)="removeMotif(mot)">X
                              </div> <br></span></div>
                        </div>
                        <div class="col-10" *ngIf="listpreviousconsultations && listpreviousconsultations.length >0">
                          <p id="consultationencours" style="font-size: 14.6px;text-align: center;">Vous avez déjà
                            enregistré une consultation pour ce patient
                            aujourd'hui. <br> Voulez-vous la modifier ?
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-center mt-4">
                      <!-- Ajout de la classe pour centrer -->
                      <div class="text-center"> <!-- Ajout de la classe pour centrer -->
                        <button class="btn btn-secondary mr-2" (click)="deleteSelectedMotif($event)">Annuler</button>
                        <button class="btn btn-primary" *ngIf="listpreviousconsultations && listpreviousconsultations.length <1" (click)="checkFormAndSave(patient)">Sauvegarder</button>
                        <button class="btn btn-primary" *ngIf="listpreviousconsultations && listpreviousconsultations.length >0" (click)="geToUpdateConsultation()">Modifier</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- modal ajout motif de consultation  -->
      </div>

      <div class="informationPat2 image-container">
        <img src="assets/images/patsDetails/pat1.png" *ngIf="!pathImage" style="cursor: pointer;" title="Prendre une photo" data-toggle="modal" data-target="#fileUploadModal" class="imgMed">
        <img src="{{pathImage}}" *ngIf="pathImage" style="cursor: pointer;" title="Prendre une photo" data-toggle="modal" data-target="#fileUploadModal" class="imgMed">
        <div class="upload-indicator">
          <img src="assets/images/img-camera.png" data-toggle="modal" data-target="#fileUploadModal" style="cursor: pointer;width: 30px;" title="Prendre une photo">
        </div>

      </div>
      <div class="informationPat">
        <div class="fiche">
          <b class="ficheNum">N° Fiche : </b> <b>{{patient.numFiche}}</b>
        </div>
        <div class="row informationsPat">
          <div class="col-md-4 col-sm-12">
            <span><b>Date de naissance : </b></span>
            <span *ngIf="patient.dateAnniversaire">{{patient.dateAnniversaire | date: 'dd/MM/yyyy'}}</span>
            <span *ngIf="!patient.dateAnniversaire" class="badge">Aucun</span>
            <br>
            <span><b>Age : </b></span>
            <span *ngIf="patient.age">{{patient.age}} ans</span>
            <span *ngIf="!patient.age" class="badge">Aucun</span> <br>
            <span><b>Sexe : </b></span>
            <span *ngIf="patient.sexe">{{patient.sexe}}</span>
            <span *ngIf="!patient.sexe" class="badge">Aucun</span> <br>
            <span><b>Groupe Sanguin : </b> </span>
            <span *ngIf="patient.groupeSanguin">{{patient.groupeSanguin}}</span>
            <span *ngIf="!patient.groupeSanguin" class="badge">Aucun</span>
          </div>
          <div class="col-md-4 col-sm-12">
            <span><b>Statut Familial : </b></span>
            <span *ngIf="patient.statusFamilial">{{patient.statusFamilial}}</span>
            <span *ngIf="!patient.statusFamilial" class="badge">Aucun</span> <br>
            <span><b>Profession : </b></span>
            <span *ngIf="patient.profession">{{patient.profession}}</span>
            <span *ngIf="!patient.profession" class="badge">Aucun</span> <br>
            <span><b>N° Mobile : </b></span>
            <span *ngIf="patient.tel">{{patient.tel}}</span>
            <span *ngIf="!patient.tel" class="badge">Aucun</span> <br>
            <span><b>Adresse : </b> </span>
            <span *ngIf="patient.adresse.adresse ||patient.adresse.codePostal||patient.adresse.ville ">{{patient.adresse.adresse}}, {{patient.adresse.codePostal}}{{patient.adresse.ville}}</span>
            <span *ngIf="!patient.adresse.adresse &&!patient.adresse.codePostal&&!patient.adresse.ville " class="badge">Aucun</span>
          </div>
          <div class="col-md-4 col-sm-12">
            <span><b>Email : </b></span>
            <span *ngIf="patient.email">{{patient.email}}</span>
            <span *ngIf="!patient.email" class="badge">Aucun</span> <br>
            <span><b>APCI : </b> </span>
            <div *ngIf="patient.apci.length>0" class="inline-div">
              <ul class="inline-div-ul">
                <li *ngFor="let ap of patient.apci">{{getNameById(ap)}}</li>
              </ul>
            </div>
            <span *ngIf="patient.apci.length==0" class="badge"> Aucun</span>
          </div>
        </div>
        <br> <br>
      </div>
      <!---------------------------- Liste Visites effectuées ---------->
      <div class="" #historiqueExamen>
      </div>

      <div class="informationPat3" *ngIf="medecin">
        <div class="row bar">
          <div class="col"><a class="linkPart2"><b>Visites effectuées</b></a></div>

        </div>
        <div class="row rightButts">
          <div class="col-1 c1" style=" margin-right: 82%;">
            <div class=" buttoncadre0">
              <div class="row">
                <div class="col-2">
                  <img class="iconButt2 " src="assets/images/patsDetails/search_3.png" style="visibility: hidden">
                </div>
                <div class="col-6">
                  <input type="text" name="name" id="recherche" class="recherche" placeholder="Rechercher ... " style="visibility: hidden" [(ngModel)]="search" name="search">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="div-tab2">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <th id="firstThTable" scope="col" style="color:#589590;text-align: center;width: 15%">DATE</th>
                <th scope="col" style="color:#589590;text-align: center;width: 20%">MOTIF</th>
                <th scope="col" style="color:#589590;text-align: center;width: 20%">DURÉE</th>
                <th scope="col" style="color:#589590;text-align: center;width: 20%">Mots clés</th>
                <th scope="col" style="color:#589590;text-align: center;width: 30%">CAT</th>
                <th scope="col" style="color:#589590;text-align: center; width: 15%" *ngIf="medecin">ACTIONS</th>
              </thead>
              <tbody *ngFor="let consultation of allExamnes| paginate: { itemsPerPage: 25, currentPage: p, id: 'p'  }|searching : search.toString().toLowerCase();let k=index">
                <tr [class.ligne-paire]="k % 2 !== 0" [class.ligne-impaire]="k % 2 === 0" class="text-center">
                  <td>{{consultation.start | date: 'dd/MM/yyyy, HH:mm'}}</td>
                  <td><span *ngFor="let mot of consultation.motif">{{mot}}<br></span></td>
                  <td>{{consultation.duration}}</td>
                  <td>
                    <div class="row">
                      <!--!------------------ Oeil Droit -->
                      <!-- sans cyclo-->
                      <span *ngIf="consultation && consultation.refraction.sansCycloplegie.oielDroit.sphere&&  consultation.refraction.sansCycloplegie.oielDroit.sphere !=='Plan'&& (consultation.refraction.sansCycloplegie.oielDroit.sphere | numeric)< 0 &&(consultation.refraction.avecCycloplegie.oielDroit.sphere=='Plan' || !consultation.refraction.avecCycloplegie.oielDroit.sphere)  ">
                        <ng-container>( OD )</ng-container> Myopie
                      </span>
                      <span *ngIf="consultation && consultation.refraction.sansCycloplegie.oielDroit.sphere&&  consultation.refraction.sansCycloplegie.oielDroit.sphere !=='Plan'&& (consultation.refraction.sansCycloplegie.oielDroit.sphere | numeric)> 0 &&(consultation.refraction.avecCycloplegie.oielDroit.sphere=='Plan' || !consultation.refraction.avecCycloplegie.oielDroit.sphere) ">
                        <ng-container>( OD )</ng-container> Hypermétropie
                      </span>
                      <span *ngIf="consultation && consultation.refraction.sansCycloplegie.oielDroit.cylindre&& consultation.refraction.sansCycloplegie.oielDroit.cylindre !=='Sph' &&(consultation.refraction.avecCycloplegie.oielDroit.cylindre=='Sph' || !consultation.refraction.avecCycloplegie.oielDroit.cylindre)  ">
                        <ng-container>( OD )</ng-container> Astigmatisme
                      </span>
                      <!-- sans cyclo-->

                      <!-- avec cyclo-->
                      <span *ngIf="consultation && consultation.refraction.avecCycloplegie.oielDroit.sphere&&  consultation.refraction.avecCycloplegie.oielDroit.sphere !=='Plan'&& (consultation.refraction.avecCycloplegie.oielDroit.sphere | numeric)< 0  ">
                        <ng-container>( OD )</ng-container> Myopie
                      </span>
                      <span *ngIf="consultation && consultation.refraction.avecCycloplegie.oielDroit.sphere && consultation.refraction.avecCycloplegie.oielDroit.sphere !=='Plan'&& (consultation.refraction.avecCycloplegie.oielDroit.sphere| numeric) >0 ">
                        <ng-container>( OD )</ng-container> Hypermétropie
                      </span>
                      <span *ngIf="consultation && consultation.refraction.avecCycloplegie.oielDroit.cylindre&& consultation.refraction.avecCycloplegie.oielDroit.cylindre !=='Sph'">
                        <ng-container>( OD )</ng-container> Astigmatisme
                      </span>
                      <span *ngIf="consultation && consultation.refraction.acuitevisueldepres.oielDroit.add>0">
                        <ng-container>( OD )</ng-container> Presbytie
                      </span>
                      <!-- avec cyclo-->
                      <!-- RV -->
                      <div *ngIf="consultation && consultation.refraction.acuitevisueldeloin.oielDroit.rv">
                        <span *ngIf="consultation.refraction.acuitevisueldeloin.oielDroit.rv==='R < V'"><ng-container>( OD )</ng-container> RV (loin) : Voit mieux dans le vert</span>
                        <span *ngIf="consultation.refraction.acuitevisueldeloin.oielDroit.rv==='R = V'"><ng-container>( OD )</ng-container> RV (loin) : Voit aussi bien dans le rouge que dans le vert</span>
                        <span *ngIf="consultation.refraction.acuitevisueldeloin.oielDroit.rv==='R > V'"><ng-container>( OD )</ng-container> RV (loin) : Voit mieux dans le rouge</span>
                      </div>
                      <div *ngIf="consultation && consultation.refraction.acuitevisueldepres.oielDroit.rv">
                        <span *ngIf="consultation.refraction.acuitevisueldepres.oielDroit.rv==='R < V'"><ng-container>( OD )</ng-container> RV (près) : Voit mieux dans le vert</span>
                        <span *ngIf="consultation.refraction.acuitevisueldepres.oielDroit.rv==='R = V'"><ng-container>( OD )</ng-container> RV (près) : Voit aussi bien dans le rouge que dans le vert</span>
                        <span *ngIf="consultation.refraction.acuitevisueldepres.oielDroit.rv==='R > V'"><ng-container>( OD )</ng-container> RV (près) : Voit mieux dans le rouge</span>
                      </div>
                    </div>
                    <!--!------------------ Oeil Gauche -->
                    <div class="row">
                      <!-- sans cyclo-->
                      <span *ngIf="consultation && consultation.refraction.sansCycloplegie.oielGauche.sphere&&  consultation.refraction.sansCycloplegie.oielGauche.sphere !=='Plan'&& (consultation.refraction.sansCycloplegie.oielGauche.sphere | numeric)< 0 &&(consultation.refraction.avecCycloplegie.oielGauche.sphere=='Plan' || !consultation.refraction.avecCycloplegie.oielGauche.sphere)">
                        <ng-container>( OG )</ng-container> Myopie
                      </span>
                      <span *ngIf="consultation && consultation.refraction.sansCycloplegie.oielGauche.sphere&&  consultation.refraction.sansCycloplegie.oielGauche.sphere !=='Plan'&& (consultation.refraction.sansCycloplegie.oielGauche.sphere | numeric)> 0&&(consultation.refraction.avecCycloplegie.oielGauche.sphere=='Plan' || !consultation.refraction.avecCycloplegie.oielGauche.sphere)">
                        <ng-container>( OG )</ng-container> Hypermétropie
                      </span>
                      <span *ngIf="consultation &&consultation.refraction.sansCycloplegie.oielGauche.cylindre&& consultation.refraction.sansCycloplegie.oielGauche.cylindre !=='Sph'  &&(consultation.refraction.avecCycloplegie.oielGauche.cylindre=='Sph' || !consultation.refraction.avecCycloplegie.oielGauche.cylindre)  ">
                        <ng-container>( OG )</ng-container> Astigmatisme
                      </span>
                      <!-- sans cyclo-->

                      <!-- avec cyclo-->
                      <span *ngIf="consultation && consultation.refraction.avecCycloplegie.oielGauche.sphere&&  consultation.refraction.avecCycloplegie.oielGauche.sphere !=='Plan'&& (consultation.refraction.avecCycloplegie.oielGauche.sphere | numeric)< 0  ">
                        <ng-container>( OG )</ng-container> Myopie
                      </span>
                      <span *ngIf="consultation && consultation.refraction.avecCycloplegie.oielGauche.sphere&&  consultation.refraction.avecCycloplegie.oielGauche.sphere !=='Plan'&& (consultation.refraction.avecCycloplegie.oielGauche.sphere | numeric)> 0  ">
                        <ng-container>( OG )</ng-container> Hypermétropie
                      </span>
                      <span *ngIf="consultation &&consultation.refraction.avecCycloplegie.oielGauche.cylindre&& consultation.refraction.avecCycloplegie.oielGauche.cylindre !=='Sph' ">
                        <ng-container>( OG )</ng-container> Astigmatisme
                      </span>
                      <span *ngIf="consultation && consultation.refraction.acuitevisueldepres.oielGauche.add>0">
                        <ng-container>( OG )</ng-container> Presbytie
                      </span>
                      <!-- avec cyclo-->
                      <!-- RV -->
                      <div *ngIf="consultation && consultation.refraction.acuitevisueldeloin.oielGauche.rv">
                        <span *ngIf="consultation.refraction.acuitevisueldeloin.oielGauche.rv==='R < V'"><ng-container>( OG )</ng-container> RV (loin) : Voit mieux dans le vert</span>
                        <span *ngIf="consultation.refraction.acuitevisueldeloin.oielGauche.rv==='R = V'"><ng-container>( OG )</ng-container> RV (loin) : Voit aussi bien dans le rouge que dans le vert</span>
                        <span *ngIf="consultation.refraction.acuitevisueldeloin.oielGauche.rv==='R > V'"><ng-container>( OG )</ng-container> RV (loin) : Voit mieux dans le rouge</span>
                      </div>
                      <div *ngIf="consultation && consultation.refraction.acuitevisueldepres.oielGauche.rv">
                        <span *ngIf="consultation.refraction.acuitevisueldepres.oielGauche.rv==='R < V'"><ng-container>( OG )</ng-container> RV (près) : Voit mieux dans le vert</span>
                        <span *ngIf="consultation.refraction.acuitevisueldepres.oielGauche.rv==='R = V'"><ng-container>( OG )</ng-container> RV (près) : Voit aussi bien dans le rouge que dans le vert</span>
                        <span *ngIf="consultation.refraction.acuitevisueldepres.oielGauche.rv==='R > V'"><ng-container>( OG )</ng-container> RV (près) : Voit mieux dans le rouge</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <ul id="ul-pos">
                      <li *ngFor="let tag of consultation.tags" style="width: 40%;cursor: text;">
                        <div *ngIf="tag === 'Prescrire une chirurgie'">Prescrire une chirurgie pour <div *ngIf="consultation.cat && consultation.cat.chirurgie && consultation.cat.chirurgie.length >0">{{consultation.cat.chirurgie[0].oeil}}</div>
                        </div>
                        <div *ngIf="tag === 'Prescrire un laser'">
                          Prescrire un laser pour
                          <div *ngIf="consultation.cat && consultation.cat.prescrireLaser && consultation.cat.prescrireLaser.length >0">{{consultation.cat.prescrireLaser[0].oeil}}</div>
                        </div>
                        <div *ngIf="tag != 'Prescrire une chirurgie' && tag != 'Prescrire un laser'">{{tag}}</div>
                      </li>
                    </ul>
                  </td>
                  <td style="border-bottom-style: none;" *ngIf="medecin">
                    <a><i class="fa-solid fa-eye" title="Afficher la conclusion" data-toggle="modal" data-target="#myModalconclusion" (click)="selectExamen(consultation)"></i></a>
                    <a class="mx-1" [routerLink]="['/medecin/ajout/interrogatoire',patient._id, consultation.id]" title="Modifier consultation"><i class="fa-solid fa-pencil"></i></a>
                    <a class="mr-1"><i class="fa-solid fa-file-text" title="Ajouter un document" (click)="goToExamenDetails(patient._id, consultation.id)"></i></a>
                    <a><i class="fa-solid fa-trash" (click)="selectedConsultationFunction(consultation)" title="Supprimer" data-toggle="modal" data-target="#myModal9"></i></a>
                  </td>
                  <!---------------------------- Pop up supprimer VISITE ---------->
                  <div class="modal fade" id="myModal9" tabindex="-1" role="dialog" aria-labelledby="myModal9" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content modal-content-scan">
                        <div class="modal-header d-flex" style="border-bottom: 1px solid #e0e0e0;margin-top: auto;">
                          <h4 class="modal-title">
                            Suppression d'un Examen
                          </h4>
                          <a class="exit" aria-label="Close" data-dismiss="modal"><i class="fa-regular fa-circle-xmark"></i></a>
                        </div>
                        <div class="modal-body">
                          <div class="row" style="margin-left: 6%;">
                            <label style="font-size: 14.6px;text-align: center;">Voulez-vous vraiment supprimer cet examen?</label>
                          </div>
                          <div class="d-flex justify-content-center mt-4">
                            <!-- Ajout de la classe pour centrer -->
                            <div class="text-center"> <!-- Ajout de la classe pour centrer -->
                              <button class="btn btn-secondary mr-2" data-dismiss="modal">Annuler</button>
                              <button class="btn btn-primary" data-dismiss="modal" (click)="deleteconsultation(consultation)">Confirmer</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- modal suppression VISITE -->

                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="pagination2">
          <div class="d-flex justify-content-center" style="padding-top: 10px;">
            <pagination-controls previousLabel="Précédent " nextLabel="Suivant" (pageChange)="p = $event" id="p"></pagination-controls>
          </div>
        </div>
      </div>
    </section>

    <!---------------------------- Pop up supprimé avec succés ---------->
    <div class="modal fade" id="deleted" tabindex="-1" role="dialog" aria-labelledby="myModal0" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-content-scan">
          <div class="modal-header d-flex" style="border-bottom: 1px solid #e0e0e0;margin-top: auto;">
            <h4 class="modal-title">
              Suppression d'un Examen
            </h4>
            <a class="exit" aria-label="Close" data-dismiss="modal"><i class="fa-regular fa-circle-xmark"></i></a>
          </div>
          <div class="modal-body">
            <div class="row" style="margin-left: 6%;">
              <label style="font-size: 14.6px;text-align: center;"> Cet examen est supprimé avec succés!</label>
            </div>
            <div class="d-flex justify-content-center mt-4">
              <!-- Ajout de la classe pour centrer -->
              <div class="text-center"> <!-- Ajout de la classe pour centrer -->
                <!-- <button class="btn btn-secondary mr-2" data-dismiss="modal">Annuler</button> -->
                <button class="btn btn-primary" data-dismiss="modal">Confirmer</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!---------------------------- Pop up modifié avec succés ---------->
    <div class="modal fade" id="edited" tabindex="-1" role="dialog" aria-labelledby="myModal0" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-content-scan">
          <div class="modal-header d-flex justify-content-center">
            <h3 class="titre-modal-statut">
              MODIFIÉ AVEC SUCCÉS !
            </h3>
          </div>
          <div class="modal-body">
            <div class="row" style="margin-left: 6%;">
              <div class="col-10 text-center"><img src="assets/images/patsDetails/edit-2.png" class="imgPop"></div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <div class=" col d-flex justify-content-center buttonsPopup">
                <div class="col-auto nbr-dossier ">
                  <table>
                    <tr>
                      <td class="tdButt1 ">
                        <button class="btn-valider" data-dismiss="modal">Ok </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!---------------------------- Pop up ajouté avec succés ---------->
    <div class="modal fade" id="added" tabindex="-1" role="dialog" aria-labelledby="myModal0" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-content-scan">
          <div class="modal-header d-flex justify-content-center">
            <h3 class="titre-modal-statut">
              AJOUTÉ AVEC SUCCÉS !
            </h3>
          </div>
          <div class="modal-body">
            <div class="row" style="margin-left: 6%;">
              <div class="col-10 text-center"><img src="assets/images/patsDetails/plus-3.png" class="imgPop"></div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <div class=" col d-flex justify-content-center buttonsPopup">
                <div class="col-auto nbr-dossier ">
                  <table>
                    <tr>
                      <td class="tdButt1 ">
                        <button class="btn-valider" data-dismiss="modal">Ok </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!---------------------------- Pop up message erreur ---------->
    <div class="modal fade" id="error" tabindex="-1" role="dialog" aria-labelledby="myModal0" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-content-scan">
          <div class="modal-header d-flex justify-content-center">
            <h3 class="titre-modal-statut">
              Erreur !
            </h3>
          </div>
          <div class="modal-body">
            <div class="row" style="margin-left: 6%;">
              <div class="col-10 text-center"><img src="assets/images/patsDetails/error.png" class="imgPop"></div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <div class=" col d-flex justify-content-center buttonsPopup">
                <div class="col-auto nbr-dossier ">
                  <table>
                    <tr>
                      <td class="tdButt1 ">
                        <button class="btn-valider" data-dismiss="modal">Ok </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </section>
</div>



<!-- mODEL UPLOAD IMAGE-->
<div class="modal" id="fileUploadModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="margin-left: -25%;min-width: 650px;max-width: 650px;">
      <div class="modal-header" style="border-bottom: 1px solid;margin-top: 0%;border-bottom-color: #eeeeee;background-color: #fafafa;">
        <h5 class="modal-title">Prendre une photo</h5>
        <button type="button" class="close" data-dismiss="modal" title="Fermer" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div id="app">
          <div class="row">
            <p *ngIf="!authorized" id="alertActiverCamera">L'accès à la caméra est bloqué. <br> Veuillez l'activer dans les paramètres de votre navigateur.</p>
          </div>
          <div class="row">
            <div class="col-md-6">
              <video #video id="video" width="280" height="280" autoplay></video>
            </div>
            <div class="col-md-6">
              <canvas #canvas id="canvas" style="border-radius: 140px;" width="280" height="280"></canvas>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <button class="btn button-save" style="background: #f16c28;color: white;margin: 0 15px;font-weight: bold;font-size: 13px;margin-bottom: 10px;" *ngIf="authorized" (click)="capture()">Prendre une photo</button>
              <br>
              <button class="btn button-save" style="color: white;background-color: #008A7F;margin: 0 15px;font-weight: bold;font-size: 13px;margin-bottom: 10px;" *ngIf="authorized && captures && captures.length >0" data-dismiss="modal" (click)="saveImages()">Sauvegarder</button>
            </div>
            <div class="col-md-6">
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
<!--END UPLOAD MODEL-->
<!-- modal consultion -->
<div class="modal fade" id="myModalconclusion" tabindex="-1" role="dialog" aria-labelledby="myModalconclusion" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan p-r-r-80">
      <div class="modal-header d-flex">
        <h4 class="modal-title">
          Conclusion <i class="fa-solid fa-print" (click)="redirectionNouvelleConclusion()" title="Imprimer la conclusion du {{myExamen?.start |date : 'dd/MM/yyyy'}}"></i>
        </h4>
        <a class="exit" aria-label="Close" data-dismiss="modal"><i class="fa-regular fa-circle-xmark"></i></a>

      </div>
      <div class="modal-body width-700-px">
        <div class="row">
          <div class="col c-p" [ngClass]="{'p-cat': isActiveAnomalies}" (click)="showAnomalies()">Anomalies</div>
          <div class="col c-p" [ngClass]="{'p-cat': isActiveRefraction}" (click)="showRefraction()">Réfraction</div>
          <div class="col c-p" [ngClass]="{'p-cat': isActiveToCat}" (click)="showToCat()">TO & CAT</div>
          <div class="col c-p" [ngClass]="{'p-cat': isActiveRdv}" (click)="showRdv()">RDV</div>
        </div>
        <!-- isActiveAnomalies -->
        <br>
        <div class="row" *ngIf="isActiveAnomalies && myExamen">
          <app-interrogatoire-patient [myExamen]="myExamen"></app-interrogatoire-patient>
        </div>
        <!-- isActiveAnomalies -->
        <!-- isActiveRefraction -->
        <div class="row" *ngIf="isActiveRefraction && myExamen">
          <app-refraction-patient [myExamen]="myExamen"></app-refraction-patient>
        </div>
        <!-- isActiveRefraction -->
        <!-- isActiveToCat -->
        <div class="row" *ngIf="isActiveToCat && myExamen">
          <app-cat-lispatient [myExamen]="myExamen"></app-cat-lispatient>
        </div>
        <!-- isActiveToCat -->
        <!-- isActiveRdv -->
        <div class="row" *ngIf="isActiveRdv && myExamen">
          <app-program-lispatient [myExamen]="myExamen"></app-program-lispatient>
        </div>
        <!-- isActiveRdv -->
      </div>
    </div>
  </div>
</div>
<!-- modal conclusion  -->