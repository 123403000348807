export const medecamentClassTherapeutique =
{
  "None": "",
  "Larme artificielle": {
    "posologie": "1gtt * 5/j",
    "duree": "06 mois",
    "medicaments": ["Aqualarm", "Duo Fresh", "Hyalufresh", "Hydrafresh", "Lacrymed Unidose", "Navitae", "Ophtaox", "Thealose", "Vision Lux", "Vismed", "Hyaluvisc free"]
  },
  "Collyre Steroid seul": {
    "posologie": "1gtt * 5/j",
    "duree": "07 jours",
    "medicaments": ["Flucon", "Lotemax"]
  },
  "Collyre Antibiotique + steroid": {
    "posologie": "1gtt * 5/j",
    "duree": "07 jours",
    "medicaments": ["Bitobra ++", "Frakidex collyre", "Dexamex", "PND", "Tobacort"]
  },
  "Collyre Antiseptique": {
    "posologie": "1gtt * 5/j",
    "duree": "07 jours",
    "medicaments": ["Desomedine", "Vitabact"]
  },
  "Hygiène palpébrale": {
    "posologie": "1gtt * 5/j",
    "duree": "06 mois",
    "medicaments": ["Naviblef", "Therapearl (masque)", "Compresse chaude"]
  },
  "Pommade ophtalmique": {
    "posologie": "1app * 2/j",
    "duree": "07 jours",
    "medicaments": ["Aciclovir pommade", "Corneregel", "Frakidex", "Fucithalmic", "Gel larme", "Liposic", "Optifucin", "Sterdex", "Virgan"]
  },
  "Beta bloquant+prostaglandine": {
    "posologie": "1gtt * 1/j",
    "duree": "06 mois",
    "medicaments": ["Bilata", "Duotrav", "Ganfort", "Timoprost", "Xalacom", "Xalanol"]
  },
  "Dilatation pupillaire": {
    "posologie": "1gtt * 2/j",
    "duree": "",
    "medicaments": ["Atropine 1%", "Ciclocol", "Mydricol", "Neosynephrine"]
  },
  "Constriction pupillaire": {
    "posologie": "1gtt * 2/j",
    "duree": "",
    "medicaments": ["Pilocarpine 2%", "Pilolo 25mg"]
  },

  "Collyre Antibiotique seul": {
    "posologie": "1gtt * 5/j",
    "duree": "07 jours",
    "medicaments": ["Cipro Collyre", "Fluoro de 4 G", "Neofloxin", "Cipro", "Oflocol unidose", "Odycin(MOXIFLOXACINE)", "Tobra", "Unigenta"]
  },
  "Collyres hypertonique": {
    "posologie": "1gtt * 5/j",
    "duree": "07 jours",
    "medicaments": ["Dacurose", "Navi NACL", "Osmodrop", "Physiol"]
  },
  "Comprimés": {
    "posologie": "1cp * 2/j",
    "duree": "07 jours",
    "Antibiotique": {
      "medicaments": ["Doxy 100", "Doxy 200", "Levodis", "Levox 500", "Levoxine 500", "Oflodis", "Vibra 200", "Vibramycine N 100"]
    },
    "Antiviraux": {
      "medicaments": ["Cyvaltex 500", "Zelivir 500", "Valcyvir 500"]
    },
    "Substituts alimentaires": {
      "medicaments": ["Vitamine C 1000 mg", "Magnésium", "Bio mg2+", "Demlacure", "H mag", "Hydracure", "Omega 3 YH", "Vital yeux", "Vitrocure"]
    },
    "Anti inflammatoire": {
      "medicaments": ["Copred 20 mg", "Copred 5 mg", "Inflammyl", "Klipal", "Tendinyl 25mg (indocid)"]
    },
    "Pansement gastrique": {
      "medicaments": ["Ipproton", "Gastral"]
    },
    "Hypnotisant": {
      "medicaments": ["Stilnox"],
    },
    "Hypotonisant": {
      "medicaments": ["Diamox"],
    },
    "Anti allergique": {
      "medicaments": ["Allergica","Desloryne "],
    },
  },
  "Collyre Anti Inflammatoire": {
    "posologie": "1gtt * 5/j",
    "duree": "07 jours",
    "Glycyrrhizine": {
      "medicaments": ["Keradrop"]
    },
    "Extrait de plante": {
      "medicaments": ["Navi infla"]
    },
    "Diclofenac": {
      "medicaments": ["Dicloabak", "Votril"]
    },
    "Indometacine": {
      "medicaments": ["Indocollyre unidose"]
    }
  },
  "Pommade pour Ecchymose": {
    "posologie": "1app * 2/j",
    "duree": "07 jours",
    "medicaments": ["Cicarnica"]
  },
  "Collyre hypotonisants (Glaucome)": {
    "Analogue de la Prostaglandine": {
      "posologie": "1gtt * 1/j",
      "duree": "06 mois",
      "medicaments": ["Lata", "Latanocol unidose", "Latanoprost", "Monoprost", "Travatan", "Xalatan"]
    },
    "Beta bloquant": {
      "posologie": "1gtt * 2/j",
      "duree": "06 mois",
      "medicaments": ["Carteol lp 2%", "Intramol", "Tunolol"]
    },
    "Beta bloquant + dorzolamide": {
      "posologie": "1gtt * 2/j",
      "duree": "06 mois",
      "medicaments": ["Bidorzo", "Zolol"]
    },
    "Analogue alpha": {
      "posologie": "1gtt * 2/j",
      "duree": "06 mois",
      "medicaments": ["Alphagan", "Alphacol"]
    },
    "Dorzolamide": {
      "posologie": "1gtt * 2/j",
      "duree": "06 mois",
      "medicaments": ["Alzor", "Dorzo"]
    },
  },
  "Collyre anti allergique": {
    "posologie": "1gtt * 2/j",
    "duree": "",
    "medicaments": ["Naaback", "Zalerg", "Levophta", "Unicrom", "Azeal", "Opatanol"]
  },
  "Collyre immunosuppresseur":{
    "posologie": "",
    "duree": "",
    "medicaments": ["Ikrevis 0.1"]
  }
}
