import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-interrogatoire-patient',
  templateUrl: './interrogatoire-patient.component.html',
  styleUrls: ['./interrogatoire-patient.component.css', '../list-patients.component.css']
})
export class InterrogatoirePatientComponent implements OnInit {
  @Input() myExamen: any;

  constructor() { }

  ngOnInit() { }

  checkExistingAnomalieInterrogatoire(): boolean {
    return this.myExamen?.interrogation && this.myExamen?.interrogation.antecedentsophtalmologiquepersonnels && (this.myExamen?.interrogation.antecedentsophtalmologiquepersonnels.medicaux.length > 0
      || this.myExamen?.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours.length > 0
      || this.myExamen?.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name
      || this.myExamen?.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux.length > 0
      || this.myExamen?.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique.length > 0
      || this.myExamen?.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique.length > 0
      || this.myExamen?.interrogation.antecedentsGeneraux && this.myExamen?.interrogation.antecedentsGeneraux.grossesseenCours && this.myExamen?.interrogation.antecedentsGeneraux.grossesseenCours.present
      || this.myExamen?.interrogation.antecedentsGeneraux && this.myExamen?.interrogation.antecedentsGeneraux.terrainatopique && this.myExamen?.interrogation.antecedentsGeneraux.terrainatopique.present
      || this.myExamen?.interrogation.antecedentsGeneraux && this.myExamen?.interrogation.antecedentsGeneraux.diabete && this.myExamen?.interrogation.antecedentsGeneraux.diabete.present
      || this.myExamen?.interrogation.antecedentsGeneraux && this.myExamen?.interrogation.antecedentsGeneraux.hta && this.myExamen?.interrogation.antecedentsGeneraux.hta.present
      || this.myExamen?.interrogation.antecedentsGeneraux && this.myExamen?.interrogation.antecedentsGeneraux.terrainimmunodepression && this.myExamen?.interrogation.antecedentsGeneraux.terrainimmunodepression.present
      || this.myExamen?.interrogation.antecedentsGeneraux && this.myExamen?.interrogation.antecedentsGeneraux.maladiedysimunitaire && this.myExamen?.interrogation.antecedentsGeneraux.maladiedysimunitaire.present
      || this.myExamen?.interrogation.antecedentsGeneraux && this.myExamen?.interrogation.antecedentsGeneraux.maladieneurologique && this.myExamen?.interrogation.antecedentsGeneraux.maladieneurologique.present
      || this.myExamen?.interrogation.antecedentsGeneraux && this.myExamen?.interrogation.antecedentsGeneraux.notionvaccinationrecente && this.myExamen?.interrogation.antecedentsGeneraux.notionvaccinationrecente.present
      || this.myExamen?.interrogation.antecedentsGeneraux && this.myExamen?.interrogation.antecedentsGeneraux.notionanesthesierecente && this.myExamen?.interrogation.antecedentsGeneraux.notionanesthesierecente.present
      || this.myExamen?.interrogation.antecedentsGeneraux && this.myExamen?.interrogation.antecedentsGeneraux.traitementencours && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.traitementencours.present
      || this.myExamen?.interrogation.antecedentsFamiliauxOphtalmologique && this.myExamen?.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht && this.myExamen?.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht.length > 0)
  }
}
