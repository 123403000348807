<div class="card">
    <div class="card-body">
        <div class="titleCenter">
            <h4 class="cursor-pointer" title="Afficher/Cacher Rétine" (click)="displayAndHideRetine()">Rétine</h4>
            <div class="arrow arrow-up btnRetine" *ngIf="displayRetine" title="Cacher Rétine" (click)="displayAndHideRetine()">▲</div>
            <div class="arrow arrow-up btnRetine" *ngIf="!displayRetine" title="Afficher Rétine" (click)="displayAndHideRetine()">▼</div>
        </div>

        <div *ngIf="displayRetine" @fade>
            <div class="col-12 sansCyclo">
                <div class="cadreOeilDroit2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                        <h5 id="OeilTitle">Oeil droit</h5>
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.normale.present==true}" (click)="addRemoveFromretineRight('Normale')">
                                    Normale
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.blancavecpression.present==true}" (click)="addRemoveFromretineRight('Blanc avec pression')">
                                    Blanc avec pression
                                </li>
                                <div *ngIf="showMoreRetine">
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.blancsanspression.present==true}" (click)="addRemoveFromretineRight('Blanc sans pression')">
                                        Blanc sans pression
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.decollementchorodien.present==true}" (click)="addRemoveFromretineRight('Décollement choroïdien')">
                                        Décollement choroïdien
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.decollementretine.present==true}" (click)="addRemoveFromretineRight('Décollement de rétine')">
                                        Décollement de rétine
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.degenerescencelattice.present==true}" (click)="addRemoveFromretineRight('Dégénérescence lattice')">
                                        Dégénérescence lattice
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.dehiscenceretinienne.present==true}" (click)="addRemoveFromretineRight('Déhiscence rétinienne')">
                                        Déhiscence rétinienne
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.DEP.present==true}" (click)="addRemoveFromretineRight('DEP')">
                                        DEP
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.DSR.present==true}" (click)="addRemoveFromretineRight('DSR')">
                                        DSR
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.exsudat.present==true}" (click)="addRemoveFromretineRight('Exsudat')">
                                        Exsudat
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.foyer.present==true}" (click)="addRemoveFromretineRight('Foyer')">
                                        Foyer
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.masseretinochoroidienne.present==true}" (click)="addRemoveFromretineRight('Masse rétino/choroïdienne')">
                                    Masse rétino/choroïdienne
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.givre.present==true}" (click)="addRemoveFromretineRight('Givre')">
                                    Givre
                                </li>
                                <div *ngIf="this.showMoreRetine">
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.hemorragieretinienne.present==true}" (click)="addRemoveFromretineRight('Hémorragie rétinienne')">
                                        Hémorragie rétinienne
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.macroanevrisme.present==true}" (click)="addRemoveFromretineRight('Macroanévrisme')">
                                        Macroanévrisme
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.microanevrisme.present==true}" (click)="addRemoveFromretineRight('Microanévrisme')">
                                        Microanévrisme
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.nodulecotonneux.present==true}" (click)="addRemoveFromretineRight('Nodule cotonneux')">
                                        Nodule cotonneux
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.palissade.present==true}" (click)="addRemoveFromretineRight('Palissade')">
                                        Palissade
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.pigmentation.present==true}" (click)="addRemoveFromretineRight('Pigmentation')">
                                        Pigmentation
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.retinoschisis.present==true}" (click)="addRemoveFromretineRight('Rétinoschisis')">
                                        Rétinoschisis
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielDroit.tachederp.present==true}" (click)="addRemoveFromretineRight('Tâche de rétinite pigmentaire')">
                                        Tâche de rétinite pigmentaire
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorRetine" [ngClass]="{'selectedLi': checkExistRetineRight(anomaly.id)}">
                                        <span (click)="addRemoveFromRetineRightNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistRetineRight(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-retine')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm addanaomalybtn" [(ngModel)]="newAnomalieRetine" (keyup.enter)="addNewAnomalyRetine($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreRetineSection() *ngIf="!showMoreRetine">Voir plus</span>
                            <span (click)=showMoreRetineSection() *ngIf="showMoreRetine">Voir moins</span>
                        </div>
                    </div>
                </div>
                <div class="cadreOeilGauche2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" class="img-oeil" />
                        <h5 id="OeilTitle">Oeil gauche</h5>
                        <br />
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.normale.present==true}" (click)="addRemoveFromretineLeft('Normale')">
                                    Normale
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.blancavecpression.present==true}" (click)="addRemoveFromretineLeft('Blanc avec pression')">
                                    Blanc avec pression
                                </li>
                                <div *ngIf="showMoreRetine">
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.blancsanspression.present==true}" (click)="addRemoveFromretineLeft('Blanc sans pression')">
                                        Blanc sans pression
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.decollementchorodien.present==true}" (click)="addRemoveFromretineLeft('Décollement choroïdien')">
                                        Décollement choroïdien
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.decollementretine.present==true}" (click)="addRemoveFromretineLeft('Décollement de rétine')">
                                        Décollement de rétine
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.degenerescencelattice.present==true}" (click)="addRemoveFromretineLeft('Dégénérescence lattice')">
                                        Dégénérescence lattice
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.dehiscenceretinienne.present==true}" (click)="addRemoveFromretineLeft('Déhiscence rétinienne')">
                                        Déhiscence rétinienne
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.DEP.present==true}" (click)="addRemoveFromretineLeft('DEP')">
                                        DEP
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.DSR.present==true}" (click)="addRemoveFromretineLeft('DSR')">
                                        DSR
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.exsudat.present==true}" (click)="addRemoveFromretineLeft('Exsudat')">
                                        Exsudat
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.foyer.present==true}" (click)="addRemoveFromretineLeft('Foyer')">
                                        Foyer
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.masseretinochoroidienne.present==true}" (click)="addRemoveFromretineLeft('Masse rétino/choroïdienne')">
                                    Masse rétino/choroïdienne
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.givre.present==true}" (click)="addRemoveFromretineLeft('Givre')">
                                    Givre
                                </li>
                                <div *ngIf="showMoreRetine">
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.hemorragieretinienne.present==true}" (click)="addRemoveFromretineLeft('Hémorragie rétinienne')">
                                        Hémorragie rétinienne
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.macroanevrisme.present==true}" (click)="addRemoveFromretineLeft('Macroanévrisme')">
                                        Macroanévrisme
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.microanevrisme.present==true}" (click)="addRemoveFromretineLeft('Microanévrisme')">
                                        Microanévrisme
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.nodulecotonneux.present==true}" (click)="addRemoveFromretineLeft('Nodule cotonneux')">
                                        Nodule cotonneux
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.palissade.present==true}" (click)="addRemoveFromretineLeft('Palissade')">
                                        Palissade
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.pigmentation.present==true}" (click)="addRemoveFromretineLeft('Pigmentation')">
                                        Pigmentation
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.retinoschisis.present==true}" (click)="addRemoveFromretineLeft('Rétinoschisis')">
                                        Rétinoschisis
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.retine.oielGauche.tachederp.present==true}" (click)="addRemoveFromretineLeft('Tâche de rétinite pigmentaire')">
                                        Tâche de rétinite pigmentaire
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorRetine" [ngClass]="{'selectedLi': checkExistRetineLeft(anomaly.id)}">
                                        <span (click)="addRemoveFromRetineLeftNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistRetineLeft(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-retine')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm addanaomalybtn" [(ngModel)]="newAnomalieRetine" (keyup.enter)="addNewAnomalyRetine($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreRetineSection() *ngIf="!showMoreRetine">Voir plus</span>
                            <span (click)=showMoreRetineSection() *ngIf="showMoreRetine">Voir moins</span>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="row blocinputs">
                <div class="col-md-6">
                    <div *ngIf="examLaf?.laf.retine.oielDroit.normale.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Normale </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.normale.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.decollementretine.present==true">
                        <h5 class="titleDetails">Décollement de rétine</h5>
                        <div class="row">
                            <!-- type decolement retine-->
                            <div class="col-md-6 col-lg-6">
                                <label>Type</label>
                                <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.decollementretine.type">
                                    <option value="Mixte">Mixte</option>
                                    <option value="Rhegmatogène">Rhegmatogène</option>
                                    <option value="Tractionnel">Tractionnel</option>
                                </select>
                            </div>
                            <!-- Siège decolement retine-->
                            <div class="col-md-6 col-lg-6">
                                <label>Siège</label>
                                <div class="noduleIriene">
                                    <label>de</label>
                                    <select class="form-control input-sm ml-2" [(ngModel)]="examLaf?.laf.retine.oielDroit.decollementretine.siegeDe">
                                        <option *ngFor="let l of siege" [value]="l">{{l}}</option>
                                    </select>
                                    <label class="m-l-10">à</label>
                                    <select class="form-control input-sm ml-2" [(ngModel)]="examLaf?.laf.retine.oielDroit.decollementretine.siegeA">
                                        <option *ngFor="let l of siege" [value]="l">{{l}}</option>
                                    </select>
                                    <label class="m-l-10">(Heure)</label>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4 margin-t-10">
                                <label>Etat maculaire</label>
                                <div class="row">
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.etatmaculaire=='Macula on'" (change)="selectDecollementRetrineetatmaculaireRight('Macula on')" />
                                        <p class="ACcheckboxLabel">Macula on</p>
                                    </div>
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.etatmaculaire=='Macula off'" (change)="selectDecollementRetrineetatmaculaireRight('Macula off')" />
                                        <p class="ACcheckboxLabel">Macula off</p>
                                    </div>
                                </div>
                            </div>
                            <!-- Mobilite -->
                            <div class="col-md-4 col-lg-4 margin-t-10">
                                <label>Mobilité</label>
                                <div class="row">
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.mobilite=='Mobile'" (change)="selectDecollementRetrinemobiliteRight('Mobile')" />
                                        <p class="ACcheckboxLabel">Mobile</p>
                                    </div>
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.mobilite=='Fixe'" (change)="selectDecollementRetrinemobiliteRight('Fixe')" />
                                        <p class="ACcheckboxLabel">Fixe</p>
                                    </div>
                                </div>
                            </div>
                            <!-- hauteur -->
                            <div class="col-md-4 col-lg-4 margin-t-10">
                                <label>Hauteur</label>
                                <div class="row">
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.hauteur=='Bulleux'" (change)="selectDecollementRetrinehauteurRight('Bulleux')" />
                                        <p class="ACcheckboxLabel">Bulleux</p>
                                    </div>
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.hauteur=='Non bulleux'" (change)="selectDecollementRetrinehauteurRight('Non bulleux')" />
                                        <p class="ACcheckboxLabel">Non bulleux</p>
                                    </div>
                                </div>
                            </div>
                            <!-- Ancienneté -->
                            <div class="col-md-6 col-lg-6 margin-t-10">
                                <label>Ancienneté</label>
                                <div class="row">
                                    <input type="date" class="form-control input-sm" [max]="today" [(ngModel)]="examLaf?.laf.retine.oielDroit.decollementretine.anciennete" (change)="calculAncienneteDaysRight()">

                                    <label *ngIf="examLaf?.laf.retine.oielDroit.decollementretine.anciennete">Ancien depuis {{numberOfdaysRight}} jours</label>
                                </div>
                            </div>
                            <!-- signe d'Ancienneté -->
                            <div class="col-md-6 col-lg-6 margin-t-10">
                                <label>Signe d’ancienneté</label>

                                <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.decollementretine.signedancienneteType">
                                    <option value="Cordage sous rétinien">Cordage sous rétinien</option>
                                    <option value="Cristaux maculaires">Cristaux maculaires</option>
                                    <option value="Kystes rétinien">Kystes rétinien</option>
                                    <option value="Lignes pigmentée de démarcation">Lignes pigmentée de démarcation</option>
                                    <option value="Néovaisseaux rétinien">Néovaisseaux rétinien</option>
                                    <option value="Prolifération vitréo rétinienne">Prolifération vitréo rétinienne</option>
                                </select>

                            </div>
                            <!-- Déhiscence -->
                            <label class="m-t-20">Déhiscence</label>
                            <div class="row">
                                <div class="row">
                                    <label>Type</label>
                                    <div class="col-md-6 ">
                                        <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.decollementretine.dehiscenceType" (change)="selecteddecollementretineTypeRight()">
                                            <option value="Déchirure">Déchirure</option>
                                            <option value="Dialyse à l’aura">Dialyse à l’aura</option>
                                            <option value="Trous">Trous</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- Type trous -->
                                <div class="row" *ngIf="examLaf?.laf.retine.oielDroit.decollementretine.dehiscenceType=='Trous'">
                                    <label>Trous</label>
                                    <div class="col-md-6 inline-grid">
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.trous=='Trous atrophique'" (change)="selectdecollementretineTrousRight('Trous atrophique')" />
                                            <p class="ACcheckboxLabel">Trous atrophique</p>
                                        </div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.trous=='Trous à opercule'" (change)="selectdecollementretineTrousRight('Trous à opercule')" />
                                            <p class="ACcheckboxLabel">Trous à opercule</p>
                                        </div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.trous=='Trous paravasculaire'" (change)="selectdecollementretineTrousRight('Trous paravasculaire')" />
                                            <p class="ACcheckboxLabel">Trous paravasculaire</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Type Trous  -->
                                <div class="row" *ngIf="examLaf?.laf.retine.oielDroit.decollementretine.dehiscenceType=='Trous'">
                                    <!-- Siége -->
                                    <div class="col-md-6 margin-t-10">
                                        <label>Siège</label>
                                        <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.decollementretine.Siege">
                                            <option value="Equatoriale">Equatoriale</option>
                                            <option value="Pré équatorial">Pré équatoriale</option>
                                            <option value="Pars plana">Pars plana</option>
                                            <option value="Pars plicata">Pars plicata</option>
                                            <option value="Rétro équatoriale">Rétro équatoriale</option>
                                        </select>
                                    </div>
                                    <!-- Taille  -->
                                    <div class="col-md-3 margin-t-10">
                                        <label>Taille</label>
                                        <div class=" noduleIriene">
                                            <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.decollementretine.Taille" (change)="checkSign($event)" />
                                            <label class="m-l-10">DP</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- type Déchirure -->
                                <div class="row" *ngIf="examLaf?.laf.retine.oielDroit.decollementretine.dehiscenceType=='Déchirure'">
                                    <label>Déchirure</label>
                                    <div class="col-md-6 inline-grid">
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.dechirure=='Déchirure en fer a cheval'" (change)="selectdecollementretineDechirureRight('Déchirure en fer a cheval')" />
                                            <p class="ACcheckboxLabel">Déchirure en fer a cheval</p>
                                        </div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.dechirure=='Déchirure géante'" (change)="selectdecollementretineDechirureRight('Déchirure géante')" />
                                            <p class="ACcheckboxLabel">Déchirure géante</p>
                                        </div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.dechirure=='Déchirure multiples'" (change)="selectdecollementretineDechirureRight('Déchirure multiples')" />
                                            <p class="ACcheckboxLabel">Déchirure multiples</p>
                                        </div>
                                    </div>
                                </div>
                                <!--Déchirure en fer a cheval  -->
                                <div class="row" *ngIf=" examLaf?.laf.retine.oielDroit.decollementretine.dehiscenceType=='Déchirure'&&examLaf?.laf.retine.oielDroit.decollementretine.dechirure=='Déchirure en fer a cheval'">
                                    <!-- Localisation -->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Localisation</label>
                                        <div class=" noduleIriene">
                                            <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.decollementretine.localisation">
                                                <option *ngFor="let l of localisation" [value]="l">{{l}}</option>
                                            </select>

                                            <label class="m-l-10">h</label>
                                        </div>
                                    </div>
                                    <!-- Clapet -->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Clapet</label>
                                        <div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.clapet=='Oui'" (change)="selectdecollementretineclapetRight('Oui')" />
                                                <p class="ACcheckboxLabel">Oui</p>
                                            </div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.clapet=='Non'" (change)="selectdecollementretineclapetRight('Non')" />
                                                <p class="ACcheckboxLabel">Non</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Bords -->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Bords</label>
                                        <div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.Bords=='Inversés'" (change)="selectdecollementretineBordsRight('Inversés')" />
                                                <p class="ACcheckboxLabel">Inversés</p>
                                            </div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.Bords=='Plats'" (change)="selectdecollementretineBordsRight('Plats')" />
                                                <p class="ACcheckboxLabel">Plats</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- vaiseau en pont-->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Vaisseaux en pont</label>
                                        <div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.Vaisseauxenpont=='Oui'" (change)="selectdecollementretineVaisseauxenpontRight('Oui')" />
                                                <p class="ACcheckboxLabel">Oui</p>
                                            </div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.Vaisseauxenpont=='Non'" (change)="selectdecollementretineVaisseauxenpontRight('Non')" />
                                                <p class="ACcheckboxLabel">Non</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Siége -->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Siège</label>
                                        <div>
                                            <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.decollementretine.Siege">
                                                <option value="Equatoriale">Equatoriale</option>
                                                <option value="Pré équatorial">Pré équatoriale</option>
                                                <option value="Pars plana">Pars plana</option>
                                                <option value="Pars plicata">Pars plicata</option>
                                                <option value="Rétro équatoriale">Rétro équatoriale</option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- Taille  -->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Taille</label>
                                        <div class="noduleIriene">
                                            <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.decollementretine.Taille" (change)="checkSign($event)" />
                                            <label class="m-l-10">DP</label>
                                        </div>
                                    </div>
                                </div>
                                <!--Déchirure géante  -->
                                <div class="row" *ngIf="examLaf?.laf.retine.oielDroit.decollementretine.dehiscenceType=='Déchirure' &&examLaf?.laf.retine.oielDroit.decollementretine.dechirure=='Déchirure géante'">
                                    <!-- Localisation -->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Localisation</label>
                                        <div class=" noduleIriene">
                                            <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.decollementretine.localisation">
                                                <option *ngFor="let l of localisation" [value]="l">{{l}}</option>
                                            </select>
                                            <label class="m-l-10">h</label>
                                        </div>
                                    </div>
                                    <!-- Bords -->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Bords</label>
                                        <div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.Bords=='Inversés'" (change)="selectdecollementretineBordsRight('Inversés')" />
                                                <p class="ACcheckboxLabel">Inversés</p>
                                            </div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.Bords=='Plats'" (change)="selectdecollementretineBordsRight('Plats')" />
                                                <p class="ACcheckboxLabel">Plats</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- vaiseau en pont-->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Vaisseaux en pont</label>
                                        <div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.Vaisseauxenpont=='Oui'" (change)="selectdecollementretineVaisseauxenpontRight('Oui')" />
                                                <p class="ACcheckboxLabel">Oui</p>
                                            </div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.decollementretine.Vaisseauxenpont=='Non'" (change)="selectdecollementretineVaisseauxenpontRight('Non')" />
                                                <p class="ACcheckboxLabel">Non</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Siége -->
                                    <div class="col-md-6 col-lg-6 margin-t-10">
                                        <label>Siège</label>
                                        <div>
                                            <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.decollementretine.Siege">
                                                <option value="Equatoriale">Equatoriale</option>
                                                <option value="Pré équatorial">Pré équatoriale</option>
                                                <option value="Pars plana">Pars plana</option>
                                                <option value="Pars plicata">Pars plicata</option>
                                                <option value="Rétro équatoriale">Rétro équatoriale</option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- Taille  -->
                                    <div class="col-md-3 col-lg-3 margin-t-10">
                                        <label>Taille</label>
                                        <div class="noduleIriene">
                                            <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.decollementretine.Taille" (change)="checkSign($event)" />
                                            <label class="m-l-10">DP</label>
                                        </div>
                                    </div>

                                </div>
                                <!--Déchirure multiples  -->
                                <div class="row" *ngIf="examLaf?.laf.retine.oielDroit.decollementretine.dehiscenceType=='Déchirure' &&examLaf?.laf.retine.oielDroit.decollementretine.dechirure=='Déchirure multiples'">
                                    <!-- Nombre  -->
                                    <div class="col-md-3 margin-t-10">
                                        <label>Nombre</label>
                                        <div class="noduleIriene">
                                            <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.decollementretine.nombre" (change)="checkdecollementretineNombreRight()" />
                                        </div>
                                    </div>
                                    <div class="row mt-3" *ngFor="let dechirure of examLaf?.laf.retine.oielDroit.decollementretine.dechrirureMultiple;let i=index">
                                        <h5 class="titleDetails">Déchirure {{i+1}}</h5>
                                        <!-- Localisation -->
                                        <div class="col-md-4 col-lg-4 margin-t-10">
                                            <label>Localisation</label>
                                            <div class="noduleIriene">
                                                <!-- <input type="time" min="0" max="12" class="form-control input-sm m-l-10" (change)="addLocalisationRight($event)" /> -->
                                                <select class="form-control input-sm" [(ngModel)]="examLaf.laf.retine.oielDroit.decollementretine.dechrirureMultiple[i].localisation">
                                                    <option *ngFor="let l of localisation" [value]="l">{{l}}</option>
                                                </select>
                                                <label class="m-l-10">h</label>
                                            </div>

                                        </div>
                                        <!-- Bords -->
                                        <div class="col-md-4 col-lg-4 margin-t-10">
                                            <label>Bords</label>
                                            <div>
                                                <div class="checkBoxbloc">
                                                    <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="dechirure.Bords=='Inversés'" (change)="selectdecollementretineBordsDechirureMultiRight('Inversés',i)" />
                                                    <p class="ACcheckboxLabel">Inversés</p>
                                                </div>
                                                <div class="checkBoxbloc">
                                                    <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="dechirure.Bords=='Plats'" (change)="selectdecollementretineBordsDechirureMultiRight('Plats',i)" />
                                                    <p class="ACcheckboxLabel">Plats</p>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- vaiseau en pont-->
                                        <div class="col-md-4 col-lg-4 margin-t-10">
                                            <label>Vaisseaux en pont</label>
                                            <div>
                                                <div class="checkBoxbloc">
                                                    <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="dechirure.Vaisseauxenpont=='Oui'" (change)="selectdecollementretineVaisseauxenpontDechirureMultiRight('Oui',i)" />
                                                    <p class="ACcheckboxLabel">Oui</p>
                                                </div>
                                                <div class="checkBoxbloc">
                                                    <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="dechirure.Vaisseauxenpont=='Non'" (change)="selectdecollementretineVaisseauxenpontDechirureMultiRight('Non',i)" />
                                                    <p class="ACcheckboxLabel">Non</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Siége -->
                                        <div class="col-md-6 col-lg-6 margin-t-10">
                                            <label>Siège</label>

                                            <select class="form-control input-sm" [(ngModel)]="examLaf.laf.retine.oielDroit.decollementretine.dechrirureMultiple[i].Siege">
                                                <option value="Equatoriale">Equatoriale</option>
                                                <option value="Pré équatorial">Pré équatoriale</option>
                                                <option value="Pars plana">Pars plana</option>
                                                <option value="Pars plicata">Pars plicata</option>
                                                <option value="Rétro équatoriale">Rétro équatoriale</option>
                                            </select>
                                        </div>
                                        <!-- Taille  -->
                                        <div class="col-md-3 col-lg-3 margin-t-10">
                                            <label>Taille</label>
                                            <div class="noduleIriene">
                                                <input type="number" class="form-control input-sm" [(ngModel)]="examLaf.laf.retine.oielDroit.decollementretine.dechrirureMultiple[i].Taille" (change)="checkSign($event)" />
                                                <label class="m-l-10">DP</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <!-- description -->
                            <div class="col-12 mt-3">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.decollementretine.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.present==true">
                        <h5 class="titleDetails">Déhiscence rétinienne</h5>
                        <div class="row">
                            <div class="row">
                                <label>Type</label>
                                <div class="col-md-6 ">
                                    <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Type" (change)="seletedTypeRight()">
                                        <option value="Déchirure">Déchirure</option>
                                        <option value="Dialyse à l’aura">Dialyse à l’aura</option>
                                        <option value="Trous">Trous</option>
                                    </select>
                                </div>
                            </div>
                            <!-- Type trous -->
                            <div class="row" *ngIf="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Type=='Trous'">
                                <label>Trous</label>
                                <div class="col-md-6 inline-grid">
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.trous=='Trous atrophique'" (change)="selectdehiscenceretinienneTrousRight('Trous atrophique')" />
                                        <p class="ACcheckboxLabel">Trous atrophique</p>
                                    </div>
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.trous=='Trous à opercule'" (change)="selectdehiscenceretinienneTrousRight('Trous à opercule')" />
                                        <p class="ACcheckboxLabel">Trous à opercule</p>
                                    </div>
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.trous=='Trous paravasculaire'" (change)="selectdehiscenceretinienneTrousRight('Trous paravasculaire')" />
                                        <p class="ACcheckboxLabel">Trous paravasculaire</p>
                                    </div>
                                </div>
                            </div>
                            <!-- Type Trous  -->
                            <div class="row" *ngIf="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Type=='Trous'">
                                <!-- Siége -->
                                <div class="col-md-6 margin-t-10">
                                    <label>Siège</label>

                                    <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Siege">
                                        <option value="Equatoriale">Equatoriale</option>
                                        <option value="Pré équatorial">Pré équatoriale</option>
                                        <option value="Pars plana">Pars plana</option>
                                        <option value="Pars plicata">Pars plicata</option>
                                        <option value="Rétro équatoriale">Rétro équatoriale</option>
                                    </select>

                                </div>
                                <!-- Taille  -->
                                <div class="col-md-3 margin-t-10">
                                    <label>Taille</label>
                                    <div class=" noduleIriene">
                                        <input type="number" step="0.5" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Taille" (change)="checkSign($event)" />
                                        <label class="m-l-10">DP</label>
                                    </div>
                                </div>
                                <!-- DE , A , Heure -->
                                <div class="row">
                                    <div class="col-md-3 margin-t-10">
                                        <label>De</label>
                                        <div class=" noduleIriene">
                                            <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.dehiscenceretinienneDe" (change)="checkSign($event)" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 margin-t-10">
                                        <label>A</label>
                                        <div class=" noduleIriene">
                                            <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.dehiscenceretinienneA" (change)="checkSign($event)" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 margin-t-10">
                                        <label>Heure</label>
                                        <div class=" noduleIriene">
                                            <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.dehiscenceretinienneHeure" (change)="checkSign($event)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- type Déchirure -->
                            <div class="row" *ngIf="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Type=='Déchirure'">
                                <label>Déchirure</label>
                                <div class="col-md-6 inline-grid">
                                    <div class="checkBoxbloc ">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.dechirure=='Déchirure en fer a cheval'" (change)="selectdehiscenceretinienneDechirureRight('Déchirure en fer a cheval')" />
                                        <p class="ACcheckboxLabel">Déchirure en fer a cheval</p>
                                    </div>
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.dechirure=='Déchirure géante'" (change)="selectdehiscenceretinienneDechirureRight('Déchirure géante')" />
                                        <p class="ACcheckboxLabel">Déchirure géante</p>
                                    </div>
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.dechirure=='Déchirure multiples'" (change)="selectdehiscenceretinienneDechirureRight('Déchirure multiples')" />
                                        <p class="ACcheckboxLabel">Déchirure multiples</p>
                                    </div>
                                </div>
                            </div>
                            <!--Déchirure en fer a cheval  -->
                            <div class="row" *ngIf=" examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Type=='Déchirure'&&examLaf?.laf.retine.oielDroit.dehiscenceretinienne.dechirure=='Déchirure en fer a cheval'">
                                <!-- Localisation -->
                                <div class="col-md-4 col-lg-4 margin-t-10">
                                    <label>Localisation</label>
                                    <div class=" noduleIriene">
                                        <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.localisation">
                                            <option *ngFor="let l of localisation" [value]="l">{{l}}</option>
                                        </select>

                                        <label class="m-l-10">h</label>
                                    </div>
                                </div>
                                <!-- Clapet -->
                                <div class="col-md-4 col-lg-4 margin-t-10">
                                    <label>Clapet</label>
                                    <div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.clapet=='Oui'" (change)="selectdehiscenceretinienneclapetRight('Oui')" />
                                            <p class="ACcheckboxLabel">Oui</p>
                                        </div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.clapet=='Non'" (change)="selectdehiscenceretinienneclapetRight('Non')" />
                                            <p class="ACcheckboxLabel">Non</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Bords -->
                                <div class="col-md-4 col-lg-4 margin-t-10">
                                    <label>Bords</label>
                                    <div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Bords=='Inversés'" (change)="selectdehiscenceretinienneBordsRight('Inversés')" />
                                            <p class="ACcheckboxLabel">Inversés</p>
                                        </div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Bords=='Plats'" (change)="selectdehiscenceretinienneBordsRight('Plats')" />
                                            <p class="ACcheckboxLabel">Plats</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- vaiseau en pont-->
                                <div class="col-md-4 col-lg-4 margin-t-10">
                                    <label>Vaisseaux en pont</label>
                                    <div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Vaisseauxenpont=='Oui'" (change)="selectdehiscenceretinienneVaisseauxenpontRight('Oui')" />
                                            <p class="ACcheckboxLabel">Oui</p>
                                        </div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Vaisseauxenpont=='Non'" (change)="selectdehiscenceretinienneVaisseauxenpontRight('Non')" />
                                            <p class="ACcheckboxLabel">Non</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Siége -->
                                <div class="col-md-4 col-lg-4 margin-t-10">
                                    <label>Siège</label>
                                    <div>
                                        <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Siege">
                                            <option value="Equatoriale">Equatoriale</option>
                                            <option value="Pré équatorial">Pré équatoriale</option>
                                            <option value="Pars plana">Pars plana</option>
                                            <option value="Pars plicata">Pars plicata</option>
                                            <option value="Rétro équatoriale">Rétro équatoriale</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- Taille  -->
                                <div class="col-md-4 col-lg-4 margin-t-10">
                                    <label>Taille</label>
                                    <div class="noduleIriene">
                                        <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Taille" (change)="checkSign($event)" />
                                        <label class="m-l-10">DP</label>
                                    </div>
                                </div>
                            </div>
                            <!--Déchirure géante  -->
                            <div class="row" *ngIf="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Type=='Déchirure' &&examLaf?.laf.retine.oielDroit.dehiscenceretinienne.dechirure=='Déchirure géante'">
                                <!-- Localisation -->
                                <div class="col-md-4 col-lg-4 margin-t-10">
                                    <label>Localisation</label>
                                    <div class=" noduleIriene">
                                        <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.localisation">
                                            <option *ngFor="let l of localisation" [value]="l">{{l}}</option>
                                        </select>
                                        <label class="m-l-10">h</label>
                                    </div>
                                </div>
                                <!-- Bords -->
                                <div class="col-md-4 col-lg-4 margin-t-10">
                                    <label>Bords</label>
                                    <div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Bords=='Inversés'" (change)="selectdehiscenceretinienneBordsRight('Inversés')" />
                                            <p class="ACcheckboxLabel">Inversés</p>
                                        </div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Bords=='Plats'" (change)="selectdehiscenceretinienneBordsRight('Plats')" />
                                            <p class="ACcheckboxLabel">Plats</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- vaiseau en pont-->
                                <div class="col-md-4 col-lg-4 margin-t-10">
                                    <label>Vaisseaux en pont</label>
                                    <div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Vaisseauxenpont=='Oui'" (change)="selectdehiscenceretinienneVaisseauxenpontRight('Oui')" />
                                            <p class="ACcheckboxLabel">Oui</p>
                                        </div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Vaisseauxenpont=='Non'" (change)="selectdehiscenceretinienneVaisseauxenpontRight('Non')" />
                                            <p class="ACcheckboxLabel">Non</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Siége -->
                                <div class="col-md-6 col-lg-6 margin-t-10">
                                    <label>Siège</label>
                                    <div>
                                        <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Siege">
                                            <option value="Equatoriale">Equatoriale</option>
                                            <option value="Pré équatorial">Pré équatoriale</option>
                                            <option value="Pars plana">Pars plana</option>
                                            <option value="Pars plicata">Pars plicata</option>
                                            <option value="Rétro équatoriale">Rétro équatoriale</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- Taille  -->
                                <div class="col-md-3 col-lg-3 margin-t-10">
                                    <label>Taille</label>
                                    <div class="noduleIriene">
                                        <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Taille" (change)="checkSign($event)" />
                                        <label class="m-l-10">DP</label>
                                    </div>
                                </div>

                            </div>
                            <!--Déchirure multiples  -->
                            <div class="row" *ngIf="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.Type=='Déchirure' &&examLaf?.laf.retine.oielDroit.dehiscenceretinienne.dechirure=='Déchirure multiples'">
                                <!-- Nombre  -->
                                <div class="col-md-3 margin-t-10">
                                    <label>Nombre</label>
                                    <div class="noduleIriene">
                                        <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.nombre" (change)="checkDehiscenceretinienneNombreRight()" />
                                    </div>
                                </div>
                                <div class="row mt-3" *ngFor="let dechirure of examLaf?.laf.retine.oielDroit.dehiscenceretinienne.dechrirureMultiple;let i=index">
                                    <h5 class="titleDetails">Déchirure {{i+1}}</h5>
                                    <!-- Localisation -->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Localisation</label>
                                        <div class="noduleIriene">
                                            <select class="form-control input-sm" [(ngModel)]="examLaf.laf.retine.oielDroit.dehiscenceretinienne.dechrirureMultiple[i].localisation">
                                                <option *ngFor="let l of localisation" [value]="l">{{l}}</option>
                                            </select>
                                            <label class="m-l-10">h</label>
                                        </div>

                                    </div>
                                    <!-- Bords -->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Bords</label>
                                        <div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="dechirure.Bords=='Inversés'" (change)="selectdehiscenceretinienneBordsDechirureMultiRight('Inversés',i)" />
                                                <p class="ACcheckboxLabel">Inversés</p>
                                            </div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="dechirure.Bords=='Plats'" (change)="selectdehiscenceretinienneBordsDechirureMultiRight('Plats',i)" />
                                                <p class="ACcheckboxLabel">Plats</p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- vaiseau en pont-->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Vaisseaux en pont</label>
                                        <div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="dechirure.Vaisseauxenpont=='Oui'" (change)="selectdehiscenceretinienneVaisseauxenpontDechirureMultiRight('Oui',i)" />
                                                <p class="ACcheckboxLabel">Oui</p>
                                            </div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="dechirure.Vaisseauxenpont=='Non'" (change)="selectdehiscenceretinienneVaisseauxenpontDechirureMultiRight('Non',i)" />
                                                <p class="ACcheckboxLabel">Non</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Siége -->
                                    <div class="col-md-6 col-lg-6 margin-t-10">
                                        <label>Siège</label>

                                        <select class="form-control input-sm" [(ngModel)]="examLaf.laf.retine.oielDroit.dehiscenceretinienne.dechrirureMultiple[i].Siege">
                                            <option value="Equatoriale">Equatoriale</option>
                                            <option value="Pré équatorial">Pré équatoriale</option>
                                            <option value="Pars plana">Pars plana</option>
                                            <option value="Pars plicata">Pars plicata</option>
                                            <option value="Rétro équatoriale">Rétro équatoriale</option>
                                        </select>
                                    </div>
                                    <!-- Taille  -->
                                    <div class="col-md-3 col-lg-3 margin-t-10">
                                        <label>Taille</label>
                                        <div class="noduleIriene">
                                            <input type="number" class="form-control input-sm" [(ngModel)]="examLaf.laf.retine.oielDroit.dehiscenceretinienne.dechrirureMultiple[i].Taille" (change)="checkSign($event)" />
                                            <label class="m-l-10">DP</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- description -->
                            <div class="col-12 mt-3">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.dehiscenceretinienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.decollementchorodien.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Décollement choroïdien </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.decollementchorodien.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.degenerescencelattice.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Dégénérescence lattice </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.degenerescencelattice.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.retinoschisis.present==true ">
                        <h5 class="titleDetails">Rétinoschisis</h5>
                        <div class="row">
                            <div class="col-md-6 col-lg-6">
                                <label>Siège</label>
                                <div class="noduleIriene">
                                    <label>de</label>
                                    <select class="form-control input-sm ml-2" [(ngModel)]="examLaf?.laf.retine.oielDroit.retinoschisis.SiegeDe">
                                        <option *ngFor="let l of siege" [value]="l">{{l}}</option>
                                    </select>
                                    <label class="m-l-10">à</label>
                                    <select class="form-control input-sm ml-2" [(ngModel)]="examLaf?.laf.retine.oielDroit.retinoschisis.SiegeA">
                                        <option *ngFor="let l of siege" [value]="l">{{l}}</option>
                                    </select>
                                    <label class="m-l-10">(Heure)</label>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6">
                                <label>Etat maculaire</label>
                                <div class="noduleIriene">
                                    <div>
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.retinoschisis.Etatmaculaire=='Macula on'" (change)="selectretinoschisisEtatmaculaireRight('Macula on')" />
                                        <p class="ACcheckboxLabel">Macula on</p>
                                    </div>
                                    <div> <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.retinoschisis.Etatmaculaire=='Macula off'" (change)="selectretinoschisisEtatmaculaireRight('Macula off')" />
                                        <p class="ACcheckboxLabel">Macula off</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.retinoschisis.description">
                            </div>

                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.foyer.present==true">
                        <h5 class="titleDetails">Foyer</h5>
                        <div class="row">
                            <!-- Type -->
                            <div class="col-md-6 col-lg-6">
                                <label>Type</label>
                                <select class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.retine.oielDroit.foyer.Type">
                                    <option value="type1">type 1</option>
                                    <option value="type2">type 2</option>
                                    <option value="type3">type 3</option>
                                </select>
                            </div>
                            <!-- Couleur -->
                            <div class="col-md-6 col-lg-6">
                                <label>Couleur</label>
                                <input type="text" class="form-control input-sm-new " [(ngModel)]="examLaf?.laf.retine.oielDroit.foyer.Couleur" />
                            </div>

                            <!-- Siege -->
                            <div class="col-12">
                                <label>Siege</label>
                                <div class="noduleIriene">
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.foyer.Siege=='Retinien'" (change)="selectRetinefoyerSiegeRight('Retinien')" />
                                        <p class="ACcheckboxLabel">Retinien</p>
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.foyer.Siege=='Choroidien'" (change)="selectRetinefoyerSiegeRight('Choroidien')" />
                                        <p class="ACcheckboxLabel">Choroidien</p>
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.foyer.Siege=='Retino choroidien'" (change)="selectRetinefoyerSiegeRight('Retino choroidien')" />
                                        <p class="ACcheckboxLabel">Retino choroidien</p>
                                    </div>
                                </div>

                            </div>
                            <!-- Taille -->
                            <div class="col-md-4 col-lg-4">
                                <label>Taille</label>
                                <div class="noduleIriene">
                                    <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.retine.oielDroit.foyer.Taille" (change)="checkSign($event)" />
                                    <label class="m-l-10">DP</label>
                                </div>
                            </div>

                            <!-- Description -->
                            <div class="col-md-8 col-lg-8">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.foyer.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.masseretinochoroidienne.present==true">
                        <h5 class="titleDetails">Masse rétino/choroïdienne</h5>
                        <div class="row">
                            <!-- Type -->
                            <div class="col-md-6 col-lg-6">
                                <label>Type</label>
                                <select class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.retine.oielDroit.masseretinochoroidienne.Type">
                                    <option value="type1">type 1</option>
                                    <option value="type2">type 2</option>
                                    <option value="type3">type 3</option>
                                </select>
                            </div>
                            <!-- Couleur -->
                            <div class="col-md-6 col-lg-6">
                                <label>Couleur</label>
                                <input type="text" class="form-control input-sm-new " [(ngModel)]="examLaf?.laf.retine.oielDroit.masseretinochoroidienne.Couleur" />
                            </div>

                            <!-- Siege -->
                            <div class="col-12">
                                <label>Siege</label>
                                <div class="noduleIriene">
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.masseretinochoroidienne.Siege=='Retinien'" (change)="selectRetinemasseretinochoroidienneSiegeRight('Retinien')" />
                                        <p class="ACcheckboxLabel">Retinien</p>
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.masseretinochoroidienne.Siege=='Choroidien'" (change)="selectRetinemasseretinochoroidienneSiegeRight('Choroidien')" />
                                        <p class="ACcheckboxLabel">Choroidien</p>
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielDroit.masseretinochoroidienne.Siege=='Retino choroidien'" (change)="selectRetinemasseretinochoroidienneSiegeRight('Retino choroidien')" />
                                        <p class="ACcheckboxLabel">Retino choroidien</p>
                                    </div>
                                </div>

                            </div>
                            <!-- Taille -->
                            <div class="col-md-4 col-lg-4">
                                <label>Taille</label>
                                <div class="noduleIriene">
                                    <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.retine.oielDroit.masseretinochoroidienne.Taille" (change)="checkSign($event)" />
                                    <label class="m-l-10">DP</label>
                                </div>
                            </div>

                            <!-- Description -->
                            <div class="col-md-8 col-lg-8">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.masseretinochoroidienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.DEP.present==true">
                        <h5 class="titleDetails">DEP</h5>
                        <div class="row">
                            <div class="col-md-4 col-lg-4">
                                <label>Taille</label>
                                <div class="noduleIriene">
                                    <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.DEP.Taille" (change)="checkSign($event)" />
                                    <label class="m-l-10">DP</label>
                                </div>
                            </div>
                            <!-- Description -->
                            <div class="col-md-8 col-lg-8">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.DEP.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.DSR.present==true">
                        <h5 class="titleDetails">DSR</h5>
                        <div class="row">
                            <div class="col-md-4 col-lg-4">
                                <label>Taille</label>
                                <div class="noduleIriene">
                                    <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielDroit.DSR.Taille" (change)="checkSign($event)" />
                                    <label class="m-l-10">DP</label>
                                </div>
                            </div>
                            <!-- Description -->

                            <div class="col-md-8 col-lg-8">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.DSR.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.palissade.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">
                                    <h5>Palissade</h5>
                                </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.palissade.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.givre.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Givre </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.givre.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.blancsanspression.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Blanc sans pression </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.blancsanspression.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.blancavecpression.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Blanc avec pression </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.blancavecpression.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.hemorragieretinienne.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hémorragie rétinienne </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.hemorragieretinienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.nodulecotonneux.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Nodule cotonneux </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.nodulecotonneux.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.microanevrisme.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Microanévrisme </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.microanevrisme.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.macroanevrisme.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Macroanévrisme </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.macroanevrisme.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.exsudat.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Exsudat </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.exsudat.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.pigmentation.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Pigmentation </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.pigmentation.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielDroit.tachederp.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Tâche de rétinite pigmentaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielDroit.tachederp.description">
                            </div>
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.retine.oielDroit.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}} </h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>
                </div>
                <div class="col-md-6">
                    <div *ngIf="examLaf?.laf.retine.oielGauche.normale.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Normale </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.normale.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.decollementretine.present==true">
                        <h5 class="titleDetails">Décollement de rétine</h5>
                        <div class="row">
                            <!-- type decolement retine-->
                            <div class="col-md-6 col-lg-6">
                                <label>Type</label>
                                <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.decollementretine.type">
                                    <option value="Mixte">Mixte</option>
                                    <option value="Rhegmatogène">Rhegmatogène</option>
                                    <option value="Tractionnel">Tractionnel</option>
                                </select>
                            </div>
                            <!-- Siège decolement retine-->
                            <div class="col-md-6 col-lg-6">
                                <label>Siège</label>
                                <div class="noduleIriene">
                                    <label>de</label>
                                    <select class="form-control input-sm ml-2" [(ngModel)]="examLaf?.laf.retine.oielGauche.decollementretine.siegeDe">
                                        <option *ngFor="let l of siege" [value]="l">{{l}}</option>
                                    </select>
                                    <label class="m-l-10">à</label>
                                    <select class="form-control input-sm ml-2" [(ngModel)]="examLaf?.laf.retine.oielGauche.decollementretine.siegeA">
                                        <option *ngFor="let l of siege" [value]="l">{{l}}</option>
                                    </select>
                                    <label class="m-l-10">(Heure)</label>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4 margin-t-10">
                                <label>Etat maculaire</label>
                                <div class="row">
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.etatmaculaire=='Macula on'" (change)="selectDecollementRetrineetatmaculaireLeft('Macula on')" />
                                        <p class="ACcheckboxLabel">Macula on</p>
                                    </div>
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.etatmaculaire=='Macula off'" (change)="selectDecollementRetrineetatmaculaireLeft('Macula off')" />
                                        <p class="ACcheckboxLabel">Macula off</p>
                                    </div>
                                </div>
                            </div>
                            <!-- Mobilite -->
                            <div class="col-md-4 col-lg-4 margin-t-10">
                                <label>Mobilité</label>
                                <div class="row">
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.mobilite=='Mobile'" (change)="selectDecollementRetrinemobiliteLeft('Mobile')" />
                                        <p class="ACcheckboxLabel">Mobile</p>
                                    </div>
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.mobilite=='Fixe'" (change)="selectDecollementRetrinemobiliteLeft('Fixe')" />
                                        <p class="ACcheckboxLabel">Fixe</p>
                                    </div>
                                </div>
                            </div>
                            <!-- hauteur -->
                            <div class="col-md-4 col-lg-4 margin-t-10">
                                <label>Hauteur</label>
                                <div class="row">
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.hauteur=='Bulleux'" (change)="selectDecollementRetrinehauteurLeft('Bulleux')" />
                                        <p class="ACcheckboxLabel">Bulleux</p>
                                    </div>
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.hauteur=='Non bulleux'" (change)="selectDecollementRetrinehauteurLeft('Non bulleux')" />
                                        <p class="ACcheckboxLabel">Non bulleux</p>
                                    </div>
                                </div>
                            </div>
                            <!-- Ancienneté -->
                            <div class="col-md-6 col-lg-6 margin-t-10">
                                <label>Ancienneté</label>
                                <div class="row">
                                    <input type="date" class="form-control input-sm" [max]="today" [(ngModel)]="examLaf?.laf.retine.oielGauche.decollementretine.anciennete" (change)="calculAncienneteDaysLeft()">

                                    <label *ngIf="examLaf?.laf.retine.oielGauche.decollementretine.anciennete">Ancien depuis {{numberOfdaysLeft}} jours</label>
                                </div>
                            </div>
                            <!-- signe d'Ancienneté -->
                            <div class="col-md-6 col-lg-6 margin-t-10">
                                <label>Signe d’ancienneté</label>

                                <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.decollementretine.signedancienneteType">
                                    <option value="Cordage sous rétinien">Cordage sous rétinien</option>
                                    <option value="Cristaux maculaires">Cristaux maculaires</option>
                                    <option value="Kystes rétinien">Kystes rétinien</option>
                                    <option value="Lignes pigmentée de démarcation">Lignes pigmentée de démarcation</option>
                                    <option value="Néovaisseaux rétinien">Néovaisseaux rétinien</option>
                                    <option value="Prolifération vitréo rétinienne">Prolifération vitréo rétinienne</option>
                                </select>

                            </div>
                            <!-- Déhiscence -->
                            <label class="m-t-20">Déhiscence</label>
                            <div class="row">
                                <div class="row">
                                    <label>Type</label>
                                    <div class="col-md-6">
                                        <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.decollementretine.dehiscenceType" (change)="selecteddecollementretineTypeLeft()">
                                            <option value="Déchirure">Déchirure</option>
                                            <option value="Dialyse à l’aura">Dialyse à l’aura</option>
                                            <option value="Trous">Trous</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- Type trous -->
                                <div class="row" *ngIf="examLaf?.laf.retine.oielGauche.decollementretine.dehiscenceType=='Trous'">
                                    <label>Trous</label>
                                    <div class="col-md-6 inline-grid">
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.trous=='Trous atrophique'" (change)="selectdecollementretineTrousLeft('Trous atrophique')" />
                                            <p class="ACcheckboxLabel">Trous atrophique</p>
                                        </div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.trous=='Trous à opercule'" (change)="selectdecollementretineTrousLeft('Trous à opercule')" />
                                            <p class="ACcheckboxLabel">Trous à opercule</p>
                                        </div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.trous=='Trous paravasculaire'" (change)="selectdecollementretineTrousLeft('Trous paravasculaire')" />
                                            <p class="ACcheckboxLabel">Trous paravasculaire</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Type Trous  -->
                                <div class="row" *ngIf="examLaf?.laf.retine.oielGauche.decollementretine.dehiscenceType=='Trous'">
                                    <!-- Siége -->
                                    <div class="col-md-6 margin-t-10">
                                        <label>Siège</label>

                                        <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.decollementretine.Siege">
                                            <option value="Equatoriale">Equatoriale</option>
                                            <option value="Pré équatorial">Pré équatoriale</option>
                                            <option value="Pars plana">Pars plana</option>
                                            <option value="Pars plicata">Pars plicata</option>
                                            <option value="Rétro équatoriale">Rétro équatoriale</option>
                                        </select>

                                    </div>
                                    <!-- Taille  -->
                                    <div class="col-md-3 margin-t-10">
                                        <label>Taille</label>
                                        <div class=" noduleIriene">
                                            <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.decollementretine.Taille" (change)="checkSign($event)" />
                                            <label class="m-l-10">DP</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- type Déchirure -->
                                <div class="row" *ngIf="examLaf?.laf.retine.oielGauche.decollementretine.dehiscenceType=='Déchirure'">
                                    <label>Déchirure</label>
                                    <div class="col-md-6 inline-grid">
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.dechirure=='Déchirure en fer a cheval'" (change)="selectdecollementretineDechirureLeft('Déchirure en fer a cheval')" />
                                            <p class="ACcheckboxLabel">Déchirure en fer a cheval</p>
                                        </div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.dechirure=='Déchirure géante'" (change)="selectdecollementretineDechirureLeft('Déchirure géante')" />
                                            <p class="ACcheckboxLabel">Déchirure géante</p>
                                        </div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.dechirure=='Déchirure multiples'" (change)="selectdecollementretineDechirureLeft('Déchirure multiples')" />
                                            <p class="ACcheckboxLabel">Déchirure multiples</p>
                                        </div>
                                    </div>
                                </div>
                                <!--Déchirure en fer a cheval  -->
                                <div class="row" *ngIf=" examLaf?.laf.retine.oielGauche.decollementretine.dehiscenceType=='Déchirure'&&examLaf?.laf.retine.oielGauche.decollementretine.dechirure=='Déchirure en fer a cheval'">
                                    <!-- Localisation -->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Localisation</label>
                                        <div class=" noduleIriene">
                                            <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.decollementretine.localisation">
                                                <option *ngFor="let l of localisation" [value]="l">{{l}}</option>
                                            </select>

                                            <label class="m-l-10">h</label>
                                        </div>
                                    </div>
                                    <!-- Clapet -->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Clapet</label>
                                        <div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.clapet=='Oui'" (change)="selectdecollementretineclapetLeft('Oui')" />
                                                <p class="ACcheckboxLabel">Oui</p>
                                            </div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.clapet=='Non'" (change)="selectdecollementretineclapetLeft('Non')" />
                                                <p class="ACcheckboxLabel">Non</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Bords -->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Bords</label>
                                        <div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.Bords=='Inversés'" (change)="selectdecollementretineBordsLeft('Inversés')" />
                                                <p class="ACcheckboxLabel">Inversés</p>
                                            </div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.Bords=='Plats'" (change)="selectdecollementretineBordsLeft('Plats')" />
                                                <p class="ACcheckboxLabel">Plats</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- vaiseau en pont-->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Vaisseaux en pont</label>
                                        <div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.Vaisseauxenpont=='Oui'" (change)="selectdecollementretineVaisseauxenpontLeft('Oui')" />
                                                <p class="ACcheckboxLabel">Oui</p>
                                            </div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.Vaisseauxenpont=='Non'" (change)="selectdecollementretineVaisseauxenpontLeft('Non')" />
                                                <p class="ACcheckboxLabel">Non</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Siége -->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Siège</label>
                                        <div>
                                            <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.decollementretine.Siege">
                                                <option value="Equatoriale">Equatoriale</option>
                                                <option value="Pré équatorial">Pré équatoriale</option>
                                                <option value="Pars plana">Pars plana</option>
                                                <option value="Pars plicata">Pars plicata</option>
                                                <option value="Rétro équatoriale">Rétro équatoriale</option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- Taille  -->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Taille</label>
                                        <div class="noduleIriene">
                                            <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.decollementretine.Taille" (change)="checkSign($event)" />
                                            <label class="m-l-10">DP</label>
                                        </div>
                                    </div>
                                </div>
                                <!--Déchirure géante  -->
                                <div class="row" *ngIf="examLaf?.laf.retine.oielGauche.decollementretine.dehiscenceType=='Déchirure' &&examLaf?.laf.retine.oielGauche.decollementretine.dechirure=='Déchirure géante'">
                                    <!-- Localisation -->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Localisation</label>
                                        <div class=" noduleIriene">
                                            <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.decollementretine.localisation">
                                                <option *ngFor="let l of localisation" [value]="l">{{l}}</option>
                                            </select>
                                            <label class="m-l-10">h</label>
                                        </div>
                                    </div>
                                    <!-- Bords -->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Bords</label>
                                        <div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.Bords=='Inversés'" (change)="selectdecollementretineBordsLeft('Inversés')" />
                                                <p class="ACcheckboxLabel">Inversés</p>
                                            </div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.Bords=='Plats'" (change)="selectdecollementretineBordsLeft('Plats')" />
                                                <p class="ACcheckboxLabel">Plats</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- vaiseau en pont-->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Vaisseaux en pont</label>
                                        <div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.Vaisseauxenpont=='Oui'" (change)="selectdecollementretineVaisseauxenpontLeft('Oui')" />
                                                <p class="ACcheckboxLabel">Oui</p>
                                            </div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.decollementretine.Vaisseauxenpont=='Non'" (change)="selectdecollementretineVaisseauxenpontLeft('Non')" />
                                                <p class="ACcheckboxLabel">Non</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Siége -->
                                    <div class="col-md-6 col-lg-6 margin-t-10">
                                        <label>Siège</label>
                                        <div>
                                            <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.decollementretine.Siege">
                                                <option value="Equatoriale">Equatoriale</option>
                                                <option value="Pré équatorial">Pré équatoriale</option>
                                                <option value="Pars plana">Pars plana</option>
                                                <option value="Pars plicata">Pars plicata</option>
                                                <option value="Rétro équatoriale">Rétro équatoriale</option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- Taille  -->
                                    <div class="col-md-3 col-lg-3 margin-t-10">
                                        <label>Taille</label>
                                        <div class="noduleIriene">
                                            <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.decollementretine.Taille" (change)="checkSign($event)" />
                                            <label class="m-l-10">DP</label>
                                        </div>
                                    </div>

                                </div>
                                <!--Déchirure multiples  -->
                                <div class="row" *ngIf="examLaf?.laf.retine.oielGauche.decollementretine.dehiscenceType=='Déchirure' &&examLaf?.laf.retine.oielGauche.decollementretine.dechirure=='Déchirure multiples'">
                                    <!-- Nombre  -->
                                    <div class="col-md-3 margin-t-10">
                                        <label>Nombre</label>
                                        <div class="noduleIriene">
                                            <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.decollementretine.nombre" (change)="checkdecollementretineNombreLeft()" />
                                        </div>
                                    </div>
                                    <div class="row mt-3" *ngFor="let dechirure of examLaf?.laf.retine.oielGauche.decollementretine.dechrirureMultiple;let i=index">
                                        <h5 class="titleDetails">Déchirure {{i+1}}</h5>
                                        <!-- Localisation -->
                                        <div class="col-md-4 col-lg-4 margin-t-10">
                                            <label>Localisation</label>
                                            <div class="noduleIriene">
                                                <select class="form-control input-sm" [(ngModel)]="examLaf.laf.retine.oielGauche.decollementretine.dechrirureMultiple[i].localisation">
                                                    <option *ngFor="let l of localisation" [value]="l">{{l}}</option>
                                                </select>
                                                <label class="m-l-10">h</label>
                                            </div>

                                        </div>
                                        <!-- Bords -->
                                        <div class="col-md-4 col-lg-4 margin-t-10">
                                            <label>Bords</label>
                                            <div>
                                                <div class="checkBoxbloc">
                                                    <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="dechirure.Bords=='Inversés'" (change)="selectdecollementretineBordsDechirureMultiLeft('Inversés',i)" />
                                                    <p class="ACcheckboxLabel">Inversés</p>
                                                </div>
                                                <div class="checkBoxbloc">
                                                    <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="dechirure.Bords=='Plats'" (change)="selectdecollementretineBordsDechirureMultiLeft('Plats',i)" />
                                                    <p class="ACcheckboxLabel">Plats</p>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- vaiseau en pont-->
                                        <div class="col-md-4 col-lg-4 margin-t-10">
                                            <label>Vaisseaux en pont</label>
                                            <div>
                                                <div class="checkBoxbloc">
                                                    <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="dechirure.Vaisseauxenpont=='Oui'" (change)="selectdecollementretineVaisseauxenpontDechirureMultiLeft('Oui',i)" />
                                                    <p class="ACcheckboxLabel">Oui</p>
                                                </div>
                                                <div class="checkBoxbloc">
                                                    <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="dechirure.Vaisseauxenpont=='Non'" (change)="selectdecollementretineVaisseauxenpontDechirureMultiLeft('Non',i)" />
                                                    <p class="ACcheckboxLabel">Non</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Siége -->
                                        <div class="col-md-6 col-lg-6 margin-t-10">
                                            <label>Siège</label>

                                            <select class="form-control input-sm" [(ngModel)]="examLaf.laf.retine.oielGauche.decollementretine.dechrirureMultiple[i].Siege">
                                                <option value="Equatoriale">Equatoriale</option>
                                                <option value="Pré équatorial">Pré équatoriale</option>
                                                <option value="Pars plana">Pars plana</option>
                                                <option value="Pars plicata">Pars plicata</option>
                                                <option value="Rétro équatoriale">Rétro équatoriale</option>
                                            </select>
                                        </div>
                                        <!-- Taille  -->
                                        <div class="col-md-3 col-lg-3 margin-t-10">
                                            <label>Taille</label>
                                            <div class="noduleIriene">
                                                <input type="number" class="form-control input-sm" [(ngModel)]="examLaf.laf.retine.oielGauche.decollementretine.dechrirureMultiple[i].Taille" (change)="checkSign($event)" />
                                                <label class="m-l-10">DP</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <!-- description -->
                            <div class="col-12 mt-3">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.decollementretine.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.present==true">
                        <h5 class="titleDetails">Déhiscence rétinienne</h5>
                        <div class="row">
                            <div class="row">
                                <label>Type</label>
                                <div class="col-md-6 ">
                                    <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Type" (change)="seletedTypeLeft()">
                                        <option value="Déchirure">Déchirure</option>
                                        <option value="Dialyse à l’aura">Dialyse à l’aura</option>
                                        <option value="Trous">Trous</option>
                                    </select>
                                </div>
                            </div>
                            <!-- Type trous -->
                            <div class="row" *ngIf="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Type=='Trous'">
                                <label>Trous</label>
                                <div class="col-md-6 inline-grid">
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.trous=='Trous atrophique'" (change)="selectdehiscenceretinienneTrousLeft('Trous atrophique')" />
                                        <p class="ACcheckboxLabel">Trous atrophique</p>
                                    </div>
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.trous=='Trous à opercule'" (change)="selectdehiscenceretinienneTrousLeft('Trous à opercule')" />
                                        <p class="ACcheckboxLabel">Trous à opercule</p>
                                    </div>
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.trous=='Trous paravasculaire'" (change)="selectdehiscenceretinienneTrousLeft('Trous paravasculaire')" />
                                        <p class="ACcheckboxLabel">Trous paravasculaire</p>
                                    </div>
                                </div>
                            </div>
                            <!-- Type Trous  -->
                            <div class="row" *ngIf="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Type=='Trous'">
                                <!-- Siége -->
                                <div class="col-md-6 margin-t-10">
                                    <label>Siège</label>

                                    <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Siege">
                                        <option value="Equatoriale">Equatoriale</option>
                                        <option value="Pré équatorial">Pré équatoriale</option>
                                        <option value="Pars plana">Pars plana</option>
                                        <option value="Pars plicata">Pars plicata</option>
                                        <option value="Rétro équatoriale">Rétro équatoriale</option>
                                    </select>

                                </div>
                                <!-- Taille  -->
                                <div class="col-md-3 margin-t-10">
                                    <label>Taille</label>
                                    <div class=" noduleIriene">
                                        <input type="number" step="0.5" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Taille" (change)="checkSign($event)" />
                                        <label class="m-l-10">DP</label>
                                    </div>
                                </div>
                                <!-- DE , A , Heure -->
                                <div class="row">
                                    <div class="col-md-3 margin-t-10">
                                        <label>De</label>
                                        <div class=" noduleIriene">
                                            <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.dehiscenceretinienneDe" (change)="checkSign($event)" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 margin-t-10">
                                        <label>A</label>
                                        <div class=" noduleIriene">
                                            <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.dehiscenceretinienneA" (change)="checkSign($event)" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 margin-t-10">
                                        <label>Heure</label>
                                        <div class=" noduleIriene">
                                            <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.dehiscenceretinienneHeure" (change)="checkSign($event)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- type Déchirure -->
                            <div class="row" *ngIf="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Type=='Déchirure'">
                                <label>Déchirure</label>
                                <div class="col-md-6 inline-grid">
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.dechirure=='Déchirure en fer a cheval'" (change)="selectdehiscenceretinienneDechirureLeft('Déchirure en fer a cheval')" />
                                        <p class="ACcheckboxLabel">Déchirure en fer a cheval</p>
                                    </div>
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.dechirure=='Déchirure géante'" (change)="selectdehiscenceretinienneDechirureLeft('Déchirure géante')" />
                                        <p class="ACcheckboxLabel">Déchirure géante</p>
                                    </div>
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.dechirure=='Déchirure multiples'" (change)="selectdehiscenceretinienneDechirureLeft('Déchirure multiples')" />
                                        <p class="ACcheckboxLabel">Déchirure multiples</p>
                                    </div>
                                </div>
                            </div>
                            <!--Déchirure en fer a cheval  -->
                            <div class="row" *ngIf=" examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Type=='Déchirure'&&examLaf?.laf.retine.oielGauche.dehiscenceretinienne.dechirure=='Déchirure en fer a cheval'">
                                <!-- Localisation -->
                                <div class="col-md-4 col-lg-4 margin-t-10">
                                    <label>Localisation</label>
                                    <div class=" noduleIriene">
                                        <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.localisation">
                                            <option *ngFor="let l of localisation" [value]="l">{{l}}</option>
                                        </select>

                                        <label class="m-l-10">h</label>
                                    </div>
                                </div>
                                <!-- Clapet -->
                                <div class="col-md-4 col-lg-4 margin-t-10">
                                    <label>Clapet</label>
                                    <div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.clapet=='Oui'" (change)="selectdehiscenceretinienneclapetLeft('Oui')" />
                                            <p class="ACcheckboxLabel">Oui</p>
                                        </div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.clapet=='Non'" (change)="selectdehiscenceretinienneclapetLeft('Non')" />
                                            <p class="ACcheckboxLabel">Non</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Bords -->
                                <div class="col-md-4 col-lg-4 margin-t-10">
                                    <label>Bords</label>
                                    <div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Bords=='Inversés'" (change)="selectdehiscenceretinienneBordsLeft('Inversés')" />
                                            <p class="ACcheckboxLabel">Inversés</p>
                                        </div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Bords=='Plats'" (change)="selectdehiscenceretinienneBordsLeft('Plats')" />
                                            <p class="ACcheckboxLabel">Plats</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- vaiseau en pont-->
                                <div class="col-md-4 col-lg-4 margin-t-10">
                                    <label>Vaisseaux en pont</label>
                                    <div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Vaisseauxenpont=='Oui'" (change)="selectdehiscenceretinienneVaisseauxenpontLeft('Oui')" />
                                            <p class="ACcheckboxLabel">Oui</p>
                                        </div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Vaisseauxenpont=='Non'" (change)="selectdehiscenceretinienneVaisseauxenpontLeft('Non')" />
                                            <p class="ACcheckboxLabel">Non</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Siége -->
                                <div class="col-md-4 col-lg-4 margin-t-10">
                                    <label>Siège</label>
                                    <div>
                                        <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Siege">
                                            <option value="Equatoriale">Equatoriale</option>
                                            <option value="Pré équatorial">Pré équatoriale</option>
                                            <option value="Pars plana">Pars plana</option>
                                            <option value="Pars plicata">Pars plicata</option>
                                            <option value="Rétro équatoriale">Rétro équatoriale</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- Taille  -->
                                <div class="col-md-4 col-lg-4 margin-t-10">
                                    <label>Taille</label>
                                    <div class="noduleIriene">
                                        <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Taille" (change)="checkSign($event)" />
                                        <label class="m-l-10">DP</label>
                                    </div>
                                </div>
                            </div>
                            <!--Déchirure géante  -->
                            <div class="row" *ngIf="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Type=='Déchirure' &&examLaf?.laf.retine.oielGauche.dehiscenceretinienne.dechirure=='Déchirure géante'">
                                <!-- Localisation -->
                                <div class="col-md-4 col-lg-4 margin-t-10">
                                    <label>Localisation</label>
                                    <div class=" noduleIriene">
                                        <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.localisation">
                                            <option *ngFor="let l of localisation" [value]="l">{{l}}</option>
                                        </select>
                                        <label class="m-l-10">h</label>
                                    </div>
                                </div>
                                <!-- Bords -->
                                <div class="col-md-4 col-lg-4 margin-t-10">
                                    <label>Bords</label>
                                    <div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Bords=='Inversés'" (change)="selectdehiscenceretinienneBordsLeft('Inversés')" />
                                            <p class="ACcheckboxLabel">Inversés</p>
                                        </div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Bords=='Plats'" (change)="selectdehiscenceretinienneBordsLeft('Plats')" />
                                            <p class="ACcheckboxLabel">Plats</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- vaiseau en pont-->
                                <div class="col-md-4 col-lg-4 margin-t-10">
                                    <label>Vaisseaux en pont</label>
                                    <div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Vaisseauxenpont=='Oui'" (change)="selectdehiscenceretinienneVaisseauxenpontLeft('Oui')" />
                                            <p class="ACcheckboxLabel">Oui</p>
                                        </div>
                                        <div class="checkBoxbloc">
                                            <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Vaisseauxenpont=='Non'" (change)="selectdehiscenceretinienneVaisseauxenpontLeft('Non')" />
                                            <p class="ACcheckboxLabel">Non</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Siége -->
                                <div class="col-md-6 col-lg-6 margin-t-10">
                                    <label>Siège</label>
                                    <div>
                                        <select class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Siege">
                                            <option value="Equatoriale">Equatoriale</option>
                                            <option value="Pré équatorial">Pré équatoriale</option>
                                            <option value="Pars plana">Pars plana</option>
                                            <option value="Pars plicata">Pars plicata</option>
                                            <option value="Rétro équatoriale">Rétro équatoriale</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- Taille  -->
                                <div class="col-md-3 col-lg-3 margin-t-10">
                                    <label>Taille</label>
                                    <div class="noduleIriene">
                                        <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Taille" (change)="checkSign($event)" />
                                        <label class="m-l-10">DP</label>
                                    </div>
                                </div>

                            </div>
                            <!--Déchirure multiples  -->
                            <div class="row" *ngIf="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.Type=='Déchirure' &&examLaf?.laf.retine.oielGauche.dehiscenceretinienne.dechirure=='Déchirure multiples'">
                                <!-- Nombre  -->
                                <div class="col-md-3 margin-t-10">
                                    <label>Nombre</label>
                                    <div class="noduleIriene">
                                        <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.nombre" (change)="checkDehiscenceretinienneNombreLeft()" />
                                    </div>
                                </div>
                                <div class="row mt-3" *ngFor="let dechirure of examLaf?.laf.retine.oielGauche.dehiscenceretinienne.dechrirureMultiple;let i=index">
                                    <h5 class="titleDetails">Déchirure {{i+1}}</h5>
                                    <!-- Localisation -->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Localisation</label>
                                        <div class="noduleIriene">
                                            <select class="form-control input-sm" [(ngModel)]="examLaf.laf.retine.oielGauche.dehiscenceretinienne.dechrirureMultiple[i].localisation">
                                                <option *ngFor="let l of localisation" [value]="l">{{l}}</option>
                                            </select>
                                            <label class="m-l-10">h</label>
                                        </div>

                                    </div>
                                    <!-- Bords -->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Bords</label>
                                        <div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="dechirure.Bords=='Inversés'" (change)="selectdehiscenceretinienneBordsDechirureMultiLeft('Inversés',i)" />
                                                <p class="ACcheckboxLabel">Inversés</p>
                                            </div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="dechirure.Bords=='Plats'" (change)="selectdehiscenceretinienneBordsDechirureMultiLeft('Plats',i)" />
                                                <p class="ACcheckboxLabel">Plats</p>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- vaiseau en pont-->
                                    <div class="col-md-4 col-lg-4 margin-t-10">
                                        <label>Vaisseaux en pont</label>
                                        <div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="dechirure.Vaisseauxenpont=='Oui'" (change)="selectdehiscenceretinienneVaisseauxenpontDechirureMultiLeft('Oui',i)" />
                                                <p class="ACcheckboxLabel">Oui</p>
                                            </div>
                                            <div class="checkBoxbloc">
                                                <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="dechirure.Vaisseauxenpont=='Non'" (change)="selectdehiscenceretinienneVaisseauxenpontDechirureMultiLeft('Non',i)" />
                                                <p class="ACcheckboxLabel">Non</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Siége -->
                                    <div class="col-md-6 col-lg-6 margin-t-10">
                                        <label>Siège</label>

                                        <select class="form-control input-sm" [(ngModel)]="examLaf.laf.retine.oielGauche.dehiscenceretinienne.dechrirureMultiple[i].Siege">
                                            <option value="Equatoriale">Equatoriale</option>
                                            <option value="Pré équatorial">Pré équatoriale</option>
                                            <option value="Pars plana">Pars plana</option>
                                            <option value="Pars plicata">Pars plicata</option>
                                            <option value="Rétro équatoriale">Rétro équatoriale</option>
                                        </select>
                                    </div>
                                    <!-- Taille  -->
                                    <div class="col-md-3 col-lg-3 margin-t-10">
                                        <label>Taille</label>
                                        <div class="noduleIriene">
                                            <input type="number" class="form-control input-sm" [(ngModel)]="examLaf.laf.retine.oielGauche.dehiscenceretinienne.dechrirureMultiple[i].Taille" (change)="checkSign($event)" />
                                            <label class="m-l-10">DP</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- description -->
                            <div class="col-12 mt-3">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.dehiscenceretinienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.decollementchorodien.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Décollement choroïdien </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.decollementchorodien.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.degenerescencelattice.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Dégénérescence lattice </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.degenerescencelattice.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.retinoschisis.present==true ">
                        <h5 class="titleDetails">Rétinoschisis</h5>
                        <div class="row">
                            <div class="col-md-6 col-lg-6">
                                <label>Siège</label>
                                <div class="noduleIriene">
                                    <label>de</label>
                                    <select class="form-control input-sm ml-2" [(ngModel)]="examLaf?.laf.retine.oielGauche.retinoschisis.SiegeDe">
                                        <option *ngFor="let l of siege" [value]="l">{{l}}</option>
                                    </select>
                                    <label class="m-l-10">à</label>
                                    <select class="form-control input-sm ml-2" [(ngModel)]="examLaf?.laf.retine.oielGauche.retinoschisis.SiegeA">
                                        <option *ngFor="let l of siege" [value]="l">{{l}}</option>
                                    </select>
                                    <label class="m-l-10">(Heure)</label>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6">
                                <label>Etat maculaire</label>
                                <div class="noduleIriene">
                                    <div>
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.retinoschisis.Etatmaculaire=='Macula on'" (change)="selectretinoschisisEtatmaculaireLeft('Macula on')" />
                                        <p class="ACcheckboxLabel">Macula on</p>
                                    </div>
                                    <div> <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.retinoschisis.Etatmaculaire=='Macula off'" (change)="selectretinoschisisEtatmaculaireLeft('Macula off')" />
                                        <p class="ACcheckboxLabel">Macula off</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.retinoschisis.description">
                            </div>

                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.foyer.present==true">
                        <h5 class="titleDetails">Foyer</h5>
                        <div class="row">
                            <!-- Type -->
                            <div class="col-md-6 col-lg-6">
                                <label>Type</label>
                                <select class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.retine.oielGauche.foyer.Type">
                                    <option value="type1">type 1</option>
                                    <option value="type2">type 2</option>
                                    <option value="type3">type 3</option>
                                </select>
                            </div>
                            <!-- Couleur -->
                            <div class="col-md-6 col-lg-6">
                                <label>Couleur</label>
                                <input type="text" class="form-control input-sm-new " [(ngModel)]="examLaf?.laf.retine.oielGauche.foyer.Couleur" />
                            </div>

                            <!-- Siege -->
                            <div class="col-12">
                                <label>Siege</label>
                                <div class="noduleIriene">
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.foyer.Siege=='Retinien'" (change)="selectRetinefoyerSiegeLeft('Retinien')" />
                                        <p class="ACcheckboxLabel">Retinien</p>
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.foyer.Siege=='Choroidien'" (change)="selectRetinefoyerSiegeLeft('Choroidien')" />
                                        <p class="ACcheckboxLabel">Choroidien</p>
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.foyer.Siege=='Retino choroidien'" (change)="selectRetinefoyerSiegeLeft('Retino choroidien')" />
                                        <p class="ACcheckboxLabel">Retino choroidien</p>
                                    </div>
                                </div>

                            </div>
                            <!-- Taille -->
                            <div class="col-md-4 col-lg-4">
                                <label>Taille</label>
                                <div class="noduleIriene">
                                    <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.retine.oielGauche.foyer.Taille" (change)="checkSign($event)" />
                                    <label class="m-l-10">DP</label>
                                </div>
                            </div>

                            <!-- Description -->
                            <div class="col-md-8 col-lg-8">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.foyer.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.masseretinochoroidienne.present==true">
                        <h5 class="titleDetails">Masse rétino/choroïdienne</h5>
                        <div class="row">
                            <!-- Type -->
                            <div class="col-md-6 col-lg-6">
                                <label>Type</label>
                                <select class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.retine.oielGauche.masseretinochoroidienne.Type">
                                    <option value="type1">type 1</option>
                                    <option value="type2">type 2</option>
                                    <option value="type3">type 3</option>
                                </select>
                            </div>
                            <!-- Couleur -->
                            <div class="col-md-6 col-lg-6">
                                <label>Couleur</label>
                                <input type="text" class="form-control input-sm-new " [(ngModel)]="examLaf?.laf.retine.oielGauche.masseretinochoroidienne.Couleur" />
                            </div>

                            <!-- Siege -->
                            <div class="col-12">
                                <label>Siege</label>
                                <div class="noduleIriene">
                                    <div class="checkBoxbloc">
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.masseretinochoroidienne.Siege=='Retinien'" (change)="selectRetinemasseretinochoroidienneSiegeLeft('Retinien')" />
                                        <p class="ACcheckboxLabel">Retinien</p>
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.masseretinochoroidienne.Siege=='Choroidien'" (change)="selectRetinemasseretinochoroidienneSiegeLeft('Choroidien')" />
                                        <p class="ACcheckboxLabel">Choroidien</p>
                                        <input type="checkbox" class="radiotypeinput" id="checkbox" [checked]="examLaf?.laf.retine.oielGauche.masseretinochoroidienne.Siege=='Retino choroidien'" (change)="selectRetinemasseretinochoroidienneSiegeLeft('Retino choroidien')" />
                                        <p class="ACcheckboxLabel">Retino choroidien</p>
                                    </div>
                                </div>

                            </div>
                            <!-- Taille -->
                            <div class="col-md-4 col-lg-4">
                                <label>Taille</label>
                                <div class="noduleIriene">
                                    <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.retine.oielGauche.masseretinochoroidienne.Taille" (change)="checkSign($event)" />
                                    <label class="m-l-10">DP</label>
                                </div>
                            </div>

                            <!-- Description -->
                            <div class="col-md-8 col-lg-8">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.masseretinochoroidienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.DEP.present==true">
                        <h5 class="titleDetails">DEP</h5>
                        <div class="row">
                            <div class="col-md-4 col-lg-4">
                                <label>Taille</label>
                                <div class="noduleIriene">
                                    <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.DEP.Taille" (change)="checkSign($event)" />
                                    <label class="m-l-10">DP</label>
                                </div>
                            </div>
                            <!-- Description -->
                            <div class="col-md-8 col-lg-8">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.DEP.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.DSR.present==true">
                        <h5 class="titleDetails">DSR</h5>
                        <div class="row">
                            <div class="col-md-4 col-lg-4">
                                <label>Taille</label>
                                <div class="noduleIriene">
                                    <input type="number" class="form-control input-sm" [(ngModel)]="examLaf?.laf.retine.oielGauche.DSR.Taille" (change)="checkSign($event)" />
                                    <label class="m-l-10">DP</label>
                                </div>
                            </div>
                            <!-- Description -->

                            <div class="col-md-8 col-lg-8">
                                <label>Description</label>
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.DSR.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.palissade.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">
                                    <h5>Palissade</h5>
                                </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.palissade.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.givre.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Givre </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.givre.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.blancsanspression.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Blanc sans pression </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.blancsanspression.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.blancavecpression.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Blanc avec pression </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.blancavecpression.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.hemorragieretinienne.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Hémorragie rétinienne </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.hemorragieretinienne.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.nodulecotonneux.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Nodule cotonneux </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.nodulecotonneux.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.microanevrisme.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Microanévrisme </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.microanevrisme.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.macroanevrisme.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Macroanévrisme </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.macroanevrisme.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.exsudat.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Exsudat </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.exsudat.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.pigmentation.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Pigmentation </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.pigmentation.description">
                            </div>
                        </div>
                    </div>
                    <div class="margin-t-10" *ngIf="examLaf?.laf.retine.oielGauche.tachederp.present==true">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h5 class="titleDetails">Tâche de rétinite pigmentaire </h5>
                            </div>
                            <div class="col-lg-8 col-md-12">
                                <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.retine.oielGauche.tachederp.description">
                            </div>
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.retine.oielGauche.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}} </h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new " placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row emptyBloc"></div>
                </div>

            </div>
        </div>
    </div>
</div>