<!-- <div class="btnclass" style="height:70px" *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire.indexOf( 'lentilles') > -1||myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire.indexOf( 'lunettes') > -1">
  <button class="btn btn-primary" style="position: absolute;right: 50%;background: #008a7f;border: 1px solid #008a7f;top: 10px;" (click)="imprimerConclusion()">Imprimer</button>
</div> -->
<div class="btnclass" style="height:70px">
  <button class="btn btn-primary" style="position: absolute;right: 50%;background: #008a7f;border: 1px solid #008a7f;top: 10px;" (click)="imprimer()">Imprimer</button>
</div>

<!-- CERTIFICAT D'ADAPTATION A LA CONDUITE-->
<div *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire.indexOf( 'conduite') > -1">
  <app-conduite></app-conduite>
</div>

<!-- END CERTIFICAT D'ADAPTATION A LA CONDUITE-->

<!-- Prescrire un certificat médical-->
<div *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire.indexOf( 'Prescrire un certificat médical') > -1">
  <app-certificat-medical></app-certificat-medical>
</div>
<!-- end Prescrire un certificat médical-->

<!-- Ordonnance de lunette -->
<div *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire.indexOf( 'lunettes') > -1 ">
  <app-lunette></app-lunette>
</div>
<!-- End Ordonnance de lunette -->
<!-- Ordonnance de lunette -->
<div *ngIf="myExamen && myExamen.foundImpression  && myExamen.foundImpression.titrePrecrire.indexOf( 'lentilles') > -1 ">
  <app-lentille></app-lentille>
</div>
<!-- End Ordonnance de lunette -->

<!-- Ordonnance des médicaments -->
<div *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire ==='Prescrire une ordonnance'">
  <app-ordonnance></app-ordonnance>
</div>
<!-- End Ordonnance des médicaments -->

<!--Prescrire une ordonnance type Conjonctive -->
<div *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire=== 'Prescrire une ordonnance type Conjonctive'">
  <app-ordonnance></app-ordonnance>
</div>
<!-- End Prescrire une ordonnance type Conjonctive -->

<!--Prescrire une ordonnance type post op Cataracte -->
<div *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire==='Prescrire une ordonnance type post op Cataracte'">
  <app-ordonnance></app-ordonnance>
</div>
<!-- End Prescrire une ordonnance type post op Cataracte -->

<!--Prescrire une ordonnance type allergie -->
<div *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire==='Prescrire une ordonnance type allergie'">
  <app-ordonnance></app-ordonnance>
</div>
<!-- End Prescrire une ordonnance type allergie -->

<!--Prescrire une ordonnance type sécheresse oculairee -->
<div *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire==='Prescrire une ordonnance type sécheresse oculairee'">
  <app-ordonnance></app-ordonnance>
</div>
<!-- End Prescrire une ordonnance type sécheresse oculairee -->

<!--Prescrire une ordonnance type post op Chirurgie Réfractive -->
<div *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire=== 'Prescrire une ordonnance type post op Chirurgie Réfractive'">
  <app-ordonnance></app-ordonnance>
</div>
<!-- End Prescrire une ordonnance type post op Chirurgie Réfractive -->
<!--Prescrire une ordonnance type post op Chirurgie Réfractive -->
<div *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire=== 'Prescrire une ordonnance type post op Chirurgie Réfractive'">
  <app-ordonnance></app-ordonnance>
</div>
<!-- End Prescrire une ordonnance type post op Chirurgie Réfractive -->
<!-- Chirugie -->
<div *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire.indexOf( 'Prescrire une chirurgie') > -1">
  <app-chirugie></app-chirugie>
</div>
<!--End of chiruge-->

<!-- Examen complémentaire -->
<div *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire.indexOf( 'Prescrire des examens complémentaires') > -1">
  <app-examen-complementaire></app-examen-complementaire>
</div>
<!-- end of Examen complémentaire -->

<!-- Prescrire un laser -->
<div *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire.indexOf( 'Prescrire un laser') > -1">
  <app-laser></app-laser>
</div>
<!--End of Prescrire un laser-->

<!--Prescrire un bilan orthoptique  -->
<div *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire.indexOf( 'Prescrire un bilan orthoptique') > -1">
  <app-bilan-orthopedique></app-bilan-orthopedique>
</div>

<!--end of Prescrire un bilan orthoptique  -->
<!-- Prescrire une lettre pour confrère  -->
<div *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire.indexOf( 'Prescrire une lettre pour confrère') > -1">
  <app-lettreConfrere></app-lettreConfrere>
</div>

<!--end of Prescrire une lettre pour confrère  -->