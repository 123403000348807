<div class="btnclass" style="height:70px">
  <button class="btn btn-primary" style="position: absolute;right: 50%;background: #008a7f;border: 1px solid #008a7f;top: 10px;" (click)="imprimerConclusion()">Imprimer</button>
</div>
<div id="divToExport">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
  <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet">
  <style>
    .letter-spacing {
      letter-spacing: 3px;
    }

    ul {
      list-style-type: none;
    }

    .main-content {
      padding-left: 3.2rem;
    }

    .contenu {
      font-size: 1.2rem;
      font-weight: 600;
    }

    .bold-line {
      border-top: 3px solid;
    }

    .logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
  </style>
  <!-- En-tête -->
  <app-header-template></app-header-template>

  <div class=" container mt-4 ml-7 main-content" *ngIf="myExamen">
    <h2 class="text-center" [style.color]="medcin.colorTemplates"><strong>CONCLUSION</strong></h2>
    <br>
    <!-- Informations générales-->
    <div class="card p-1" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates" *ngIf="myExamen && myExamen.patient">
      <div class="card-body">
        <div class="row justify-content-between">
          <h6 class="card-title"><strong [style.color]="medcin.colorTemplates">Nom et Prénom:</strong> {{myExamen.patient.nom}} {{myExamen.patient.prenom}}
          </h6>
          <h6 class="card-title"><strong [style.color]="medcin.colorTemplates">Age:</strong> {{myExamen.patient.age}}</h6>
          <h6 class="card-title"><strong [style.color]="medcin.colorTemplates">Date:</strong> {{myExamen.start | date :"dd/MM/yyyy"}}</h6>
          <div class="d-flex">
            <h6 class="card-title">
              <strong [style.color]="medcin.colorTemplates">Motif: </strong>

            </h6>
            <ul style="list-style-type: disc !important;padding-left: 4px;" class="card-title ">
              <li *ngFor="let mot of motif">{{mot}}</li>
            </ul>
          </div>

        </div>
      </div>
    </div>
    <br>
    <!--Anomalie à l'interrogatoire-->
    <h5 class="text-center" [style.color]="medcin.colorTemplates"><strong>Anomalie à l'interrogatoire</strong></h5>
    <!--Médicaux-->
    <h5 class="text-left" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Médicaux:</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.medicaux?.length>0">
              <li class="second-color" *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.medicaux" [ngClass]="{'second-color': value.name != 'None'}">
                <ng-container *ngIf="value.oiel === 'oeilDroit' || value.oiel === 'deuxYeux'">
                  {{ value.name }}
                </ng-container>
              </li>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.medicaux?.length>0">
              <li *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.medicaux" [ngClass]="{'second-color': value.name != 'None'}">
                <ng-container *ngIf="value.oiel === 'oeilGauche' || value.oiel === 'deuxYeux'">
                  {{ value.name }}
                </ng-container>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Traitement en cours-->
    <h5 class="text-left" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Traitement en cours:</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours?.length>0">
              <li class="second-color" *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours" [ngClass]="{'second-color': value.name != 'None'}">
                <ng-container *ngIf="value.oiel === 'oeilDroit' || value.oiel === 'deuxYeux'">
                  {{ value.name }}
                </ng-container>
              </li>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours?.length>0">
              <li *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours" [ngClass]="{'second-color': value.name != 'None'}">
                <ng-container *ngIf="value.oiel === 'oeilGauche' || value.oiel === 'deuxYeux'">
                  {{ value.name }}
                </ng-container>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Antécédents de traitement par laser-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Antécédents de traitement par
      laser:</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name ">
              <ng-container *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.oiel === 'oeilDroit' ||
              myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.oiel === 'deuxYeux'">
                <li [ngClass]="{'second-color': myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name != 'None'}">{{myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name}} </li>
              </ng-container>

            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name ">
              <ng-container *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.oiel === 'oeilGauche' ||
              myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.oiel === 'deuxYeux'">
                <li [ngClass]="{'second-color': myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name != 'None'}">{{myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name}} </li>
              </ng-container>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Chirurgicaux-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Chirurgicaux:</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux.length>0">
              <li *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux" [ngClass]="{'second-color': value.name != 'None'}">
                <ng-container *ngIf="value.oiel === 'oeilDroit' || value.oiel === 'deuxYeux'">
                  {{ value.name }}
                </ng-container>
              </li>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux.length>0">
              <li *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux" [ngClass]="{'second-color': value.name != 'None'}">
                <ng-container *ngIf="value.oiel === 'oeilGauche' || value.oiel === 'deuxYeux'">
                  {{ value.name }}
                </ng-container>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Correction optique-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Correction optique:</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique.length>0">
              <li *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique" [ngClass]="{'second-color': value.name != 'None'}">{{value.name}}</li>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique.length>0">
              <li *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique" [ngClass]="{'second-color': value.name != 'None'}">{{value.name}}</li>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Antécédents généraux -->

    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Antécédents généraux:</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux  && myExamen.interrogation.antecedentsGeneraux.grossesseenCours.present">
              <h6 class="text-center second-color">Grossesse en cours </h6>
            </ul>
            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux  && myExamen.interrogation.antecedentsGeneraux.terrainatopique.present">
              <h6 class="text-center second-color">Terrain atopique</h6>
              <ul style="list-style-type:disc">
                <li class="second-color" *ngFor="let value of myExamen.interrogation.antecedentsGeneraux.terrainatopique.valeurs">{{value.name}}</li>
              </ul>
            </ul>
            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.diabete.present">
              <h6 class="text-center second-color">Diabète</h6>
            </ul>
            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.hta.present">
              <h6 class="text-center second-color">HTA</h6>
            </ul>
            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression && myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.present">
              <h6 class="text-center second-color">Terrain d'immunodepression</h6>
              <ul style="list-style-type:disc" class="text-center">
                <li *ngFor="let value of myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.valeurs">{{value.name}}</li>
              </ul>
            </ul>
            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire && myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.present">
              <h6 class="text-center second-color">Maladie dysimmunitaire</h6>
              <ul style="list-style-type:disc" class="text-center">
                <li *ngFor="let value of myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.valeurs">{{value.name}}</li>
              </ul>
            </ul>
            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.maladieneurologique && myExamen.interrogation.antecedentsGeneraux.maladieneurologique.present">
              <h6 class="text-center second-color">Maladie neurologique</h6>
              <ul style="list-style-type:disc">
                <li class="second-color" *ngFor="let value of myExamen.interrogation.antecedentsGeneraux.maladieneurologique.valeurs">{{value.name}}</li>
              </ul>
            </ul>
            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.maladieneurologique && myExamen.interrogation.antecedentsGeneraux.maladieneurologique.present">
              <h6 class="text-center second-color">Maladie neurologique</h6>
              <ul style="list-style-type:disc">
                <li class="second-color" *ngFor="let value of myExamen.interrogation.antecedentsGeneraux.maladieneurologique.valeurs">{{value.name}}</li>
              </ul>
            </ul>
            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.antecedentByMedecin && myExamen.interrogation.antecedentsGeneraux.antecedentByMedecin.length>0">
              <ul class="text-center second-color" style="list-style-type:disc">
                <li class="second-color" *ngFor="let value of myExamen.interrogation.antecedentsGeneraux.antecedentByMedecin">{{value.name}}</li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente && myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.present">
              <h6 class="text-center second-color">Notion de vaccination récente</h6>
              <ul class=" second-color" style="list-style-type:disc">
                <li>Type : {{myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.type}}</li>
                <li>Date : {{myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.date}}</li>
              </ul>
            </ul>
            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente && myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.present">
              <h6 class="text-center second-color">Notion d'anesthésie récente</h6>
              <ul class=" second-color" style="list-style-type:disc">
                <li>Type : {{myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.type}}</li>
                <li>Date : {{myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.date}}</li>
              </ul>
            </ul>
            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.traitementencours && myExamen.interrogation.antecedentsGeneraux.traitementencours.present">
              <h6 class="text-center second-color">Traitement général en cours</h6>
              <ul class="second-color" style="list-style-type:disc">
                <li>Type : {{myExamen.interrogation.antecedentsGeneraux.traitementencours.type}}</li>
                <li>Dose : {{myExamen.interrogation.antecedentsGeneraux.traitementencours.dose}}</li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--Antécédents familiaux ophtalmologiques  -->

    <div class="row">
      <div class="col-md-6">
        <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Antécédents familiaux ophtalmologiques:</h5>
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsFamiliauxOphtalmologique && myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht?.length>0 " style="list-style-type:disc">
              <li class="second-color" *ngFor="let antecedent of myExamen.interrogation && myExamen.interrogation.antecedentsFamiliauxOphtalmologique && myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht">{{antecedent.name}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Habitudes de vie:</h5>
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <ul *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsFamiliauxOphtalmologique && myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie?.length>0 " style="list-style-type:disc">
              <li class="second-color" *ngFor="let antecedent of myExamen.interrogation && myExamen.interrogation.antecedentsFamiliauxOphtalmologique && myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie">{{antecedent.name}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <br>
    <!--Anomalie à l'examen-->
    <h5 class="text-center" [style.color]="medcin.colorTemplates"><strong>Anomalie à l'examen</strong></h5>
    <!--Inspection-->
    <h5 class="text-left" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Inspection</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.inspection && myExamen.inspection.anomaly && myExamen.inspection.anomaly.present">
              <p *ngIf="myExamen.inspection.anomaly.oielDroit.normale.present">Normale</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.attitudedemalvoyance.present">Attitude de malvoyance</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.buphtalmie.present">Buphtalmie</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.exphtalmie.present">Exphtalmie</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.enophtalmie.present">Énophtalmie</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.microphtalmie.present">Microphtalmie</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.leucocorie.present">Leucocorie</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.protheseoculaire.present">Prothèse oculaire</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.plaies.present">Plaies</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.paralysiefaciale.present">Paralysie faciale</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.ptosis.present">Ptosis</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielDroit.strabisme.present">Strabisme</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.inspection.anomaly.oielDroit.allAnomalies">{{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.inspection && myExamen.inspection.anomaly && myExamen.inspection.anomaly.present">
              <p *ngIf="myExamen.inspection.anomaly.oielGauche.normale.present">Normale</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.attitudedemalvoyance.present">Attitude de malvoyance</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.buphtalmie.present">Buphtalmie</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.exphtalmie.present">Exphtalmie</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.enophtalmie.present">Énophtalmie</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.microphtalmie.present">Microphtalmie</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.leucocorie.present">Leucocorie</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.protheseoculaire.present">Prothèse oculaire</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.plaies.present">Plaies</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.paralysiefaciale.present">Paralysie faciale</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.ptosis.present">Ptosis</p>
              <p class="second-color" *ngIf="myExamen.inspection.anomaly.oielGauche.strabisme.present">Strabisme</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.inspection.anomaly.oielGauche.allAnomalies">{{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Réfraction subjective sans cycloplégie-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Réfraction subjective sans
      cycloplégie</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="row">
              <div class="col-md-4">
                <input type="text" value="Sph :{{ myExamen.refraction.sansCycloplegie.oielDroit.sphere}}" disabled class="form-control">
              </div>
              <div class="col-md-4">
                <input type="text" value="Cyl :{{myExamen.refraction.sansCycloplegie.oielDroit.cylindre}}" disabled class="form-control">
              </div>
              <div class="col-md-4">
                <input type="text" disabled value="Axe :{{myExamen.refraction.sansCycloplegie.oielDroit.axe}}" class="form-control">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="row">
              <div class="col-md-4">
                <input type="text" value="Sph :{{ myExamen.refraction.sansCycloplegie.oielGauche.sphere}}" disabled class="form-control">
              </div>
              <div class="col-md-4">
                <input type="text" value="Cyl :{{myExamen.refraction.sansCycloplegie.oielGauche.cylindre}}" disabled class="form-control">
              </div>
              <div class="col-md-4">
                <input type="text" value="Axe :{{myExamen.refraction.sansCycloplegie.oielGauche.axe}}" disabled class="form-control">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Réfraction subjective avec cycloplégie-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Réfraction subjective avec
      cycloplégie</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="row">
              <div class="col-md-4">
                <input type="text" value="Sph :{{ myExamen.refraction.avecCycloplegie.oielDroit.sphere}}" disabled class="form-control">
              </div>
              <div class="col-md-4">
                <input type="text" value="Cyl :{{myExamen.refraction.avecCycloplegie.oielDroit.cylindre}}" disabled class="form-control">
              </div>
              <div class="col-md-4">
                <input type="text" disabled value="Axe :{{myExamen.refraction.avecCycloplegie.oielDroit.axe}}" class="form-control">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="row">
              <div class="col-md-4">
                <input type="text" value="Sph :{{ myExamen.refraction.avecCycloplegie.oielGauche.sphere}}" disabled class="form-control">
              </div>
              <div class="col-md-4">
                <input type="text" value="Cyl :{{myExamen.refraction.avecCycloplegie.oielGauche.cylindre}}" disabled class="form-control">
              </div>
              <div class="col-md-4">
                <input type="text" value="Axe :{{myExamen.refraction.avecCycloplegie.oielGauche.axe}}" disabled class="form-control">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Annexes-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Annexes</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.annexe && myExamen.annexe.anomaly && myExamen.annexe.anomaly.present">
              <p *ngIf="myExamen.annexe.anomaly.oielDroit.normale.present">Normale</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.chalazion.present">Chalazion</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.dca.present">DCA</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.dcc.present">DCC</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.dermabrasion.present">Dermabrasion</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.diplopie.present">Diplopie</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.distichoasis.present">Distichoasis</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.dgm.present">DGM</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.ecoulementlacrymal.present">Écoulement lacrymal</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.entropion.present">Entropion</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.ectropion.present">Ectropion</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.fistule.present">Fistule</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.insuffisancedeconvergence.present">Insuffisance de convergence</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.massedelalogelacrymal.present">Masse de la loge lacrymal</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.meeibomite.present">Meeibomite</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.ophtalmologie.present">Ophtalmologie</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.orgelet.present">Orgelet</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.paralysieocculomotrice.present">Paralysie occulomotrice</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.plaie.present">Plaie</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.presencedestrabisme.present">Présence de strabisme</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.ptosis.present">Ptosis</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.refletcorneendecentre.present">Réflet cornéen décentré</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.stenoselacrymal.present">Sténose lacrymal</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.sondedeDCRenplace.present">Sonde de DCR en place</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.telangiectasie.present">Telangiectasie</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielDroit.tumeurpaplepbrale.present">Tumeur paplepbrale</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.annexe.anomaly.oielDroit.allAnomapes">{{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.annexe && myExamen.annexe.anomaly && myExamen.annexe.anomaly.present">
              <p *ngIf="myExamen.annexe.anomaly.oielGauche.normale.present">Normale</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.chalazion.present">Chalazion</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.dca.present">DCA</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.dcc.present">DCC</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.dermabrasion.present">Dermabrasion</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.diplopie.present">Diplopie</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.distichoasis.present">Distichoasis</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.dgm.present">DGM</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.ecoulementlacrymal.present">Écoulement lacrymal</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.entropion.present">Entropion</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.ectropion.present">Ectropion</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.fistule.present">Fistule</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.insuffisancedeconvergence.present">Insuffisance de convergence</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.massedelalogelacrymal.present">Masse de la loge lacrymal</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.meeibomite.present">Meeibomite</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.ophtalmologie.present">Ophtalmologie</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.orgelet.present">Orgelet</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.paralysieocculomotrice.present">Paralysie occulomotrice</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.plaie.present">Plaie</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.presencedestrabisme.present">Présence de strabisme</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.ptosis.present">Ptosis</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.refletcorneendecentre.present">Réflet cornéen décentré</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.stenoselacrymal.present">Sténose lacrymal</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.sondedeDCRenplace.present">Sonde de DCR en place</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.telangiectasie.present">Telangiectasie</p>
              <p class="second-color" *ngIf="myExamen.annexe.anomaly.oielGauche.tumeurpaplepbrale.present">Tumeur paplepbrale</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.annexe.anomaly.oielGauche.allAnomapes">{{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--AVSC de loin-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">AVSC de loin</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <p>{{myExamen.refraction.acuitevisueldeloin.oielDroit.avsc}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <p>{{myExamen.refraction.acuitevisueldeloin.oielGauche.avsc}}</p>
          </div>
        </div>
      </div>
    </div>
    <!--AVAC de loin-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">AVAC de loin</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <p>{{myExamen.refraction.acuitevisueldeloin.oielDroit.avac}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <p>{{myExamen.refraction.acuitevisueldeloin.oielGauche.avac}}</p>
          </div>
        </div>
      </div>
    </div>
    <!--Réfraction finale de loin-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Réfraction finale de loin</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="row">
              <div class="col-md-4">
                <input type="text" value="Sph :{{ myExamen.refraction.acuitevisueldeloin.oielDroit.sphere}}" disabled class="form-control">
              </div>
              <div class="col-md-4">
                <input type="text" disabled value="Cyl :{{myExamen.refraction.acuitevisueldeloin.oielDroit.cylindre}}" class="form-control">
              </div>
              <div class="col-md-4">
                <input type="text" disabled value="Axe :{{myExamen.refraction.acuitevisueldeloin.oielDroit.axe}}" class="form-control">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="row">
              <div class="col-md-4">
                <input type="text" disabled value="Sph :{{ myExamen.refraction.acuitevisueldeloin.oielGauche.sphere}}" class="form-control">
              </div>
              <div class="col-md-4">
                <input type="text" disabled value="Cyl :{{myExamen.refraction.acuitevisueldeloin.oielGauche.cylindre}}" class="form-control">
              </div>
              <div class="col-md-4">
                <input type="text" disabled value="Axe :{{myExamen.refraction.acuitevisueldeloin.oielGauche.axe}}" class="form-control">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--AVSC de près-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">AVSC de près</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <p>{{myExamen.refraction.acuitevisueldepres.oielDroit.avsc}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <p>{{myExamen.refraction.acuitevisueldepres.oielGauche.avsc}}</p>
          </div>
        </div>
      </div>
    </div>
    <!--AVAC de près-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">AVAC de près</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <p>{{myExamen.refraction.acuitevisueldepres.oielDroit.avac}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <p>{{myExamen.refraction.acuitevisueldepres.oielGauche.avac}}</p>
          </div>
        </div>
      </div>
    </div>
    <!--Réfraction finale de près-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Réfraction finale de près</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="row">
              <div class="col-md-4">
                <input type="text" disabled value="Sph :{{ myExamen.refraction.acuitevisueldepres.oielDroit.sphere}}" class="form-control">
              </div>
              <div class="col-md-4">
                <input type="text" disabled value="Cyl :{{myExamen.refraction.acuitevisueldepres.oielDroit.cylindre}}" class="form-control">
              </div>
              <div class="col-md-4">
                <input type="text" disabled value="Axe :{{myExamen.refraction.acuitevisueldepres.oielDroit.axe}}" class="form-control">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="row">
              <div class="col-md-4">
                <input type="text" disabled value="Sph :{{ myExamen.refraction.acuitevisueldepres.oielGauche.sphere}}" class="form-control">
              </div>
              <div class="col-md-4">
                <input type="text" disabled value="Cyl :{{myExamen.refraction.acuitevisueldepres.oielGauche.cylindre}}" class="form-control">
              </div>
              <div class="col-md-4">
                <input type="text" disabled value="Axe :{{myExamen.refraction.acuitevisueldepres.oielGauche.axe}}" class="form-control">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Sclère-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Sclère</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.sclere">
              <p *ngIf="myExamen.laf.sclere.oielDroit.blancheEtIntacte.present"> Blanche et intacte </p>
              <p class="second-color" *ngIf="myExamen.laf.sclere.oielDroit.episclerite.present"> Episclerite</p>
              <p class="second-color" *ngIf="myExamen.laf.sclere.oielDroit.plaie.present"> Plaie</p>
              <p class="second-color" *ngIf="myExamen.laf.sclere.oielDroit.sclerite.present"> Sclérite</p>
              <p class="second-color" *ngIf="myExamen.laf.sclere.oielDroit.scleromalacie.present"> Scléromalacie</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.sclere.oielDroit.allAnomapes"> {{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.sclere">
              <p *ngIf="myExamen.laf.sclere.oielGauche.blancheEtIntacte.present"> Blanche et intacte </p>
              <p class="second-color" *ngIf="myExamen.laf.sclere.oielGauche.episclerite.present"> Episclerite</p>
              <p class="second-color" *ngIf="myExamen.laf.sclere.oielGauche.plaie.present"> Plaie</p>
              <p class="second-color" *ngIf="myExamen.laf.sclere.oielGauche.sclerite.present"> Sclérite</p>
              <p class="second-color" *ngIf="myExamen.laf.sclere.oielGauche.scleromalacie.present"> Scléromalacie</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.sclere.oielGauche.allAnomapes"> {{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Conjonctive-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Conjonctive</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.conj">
              <p *ngIf="myExamen.laf.conj.oielDroit.normocoloreEtIntacte.present">Normocolore et intacte </p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.ankyloblepharon.present"> Ankyloblepharon</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.cercleperikeratique.present"> Cercle périkératique</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.Chemosis.present">Chemosis</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.faussemembrane.present"> Fausse membrane</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.fibroseconjonctival.present">Fibrose conjonctival</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.follicule.present">Follicule</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.granulomeconjonctivale.present">Granulome conjonctivale</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.hemorragiesousconjonctival.present">Hémorragie sous conjonctival</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.hyperhemieconj.present">Hyperhémie conj</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.icterique.present">Ictérique</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.micropapille.present ">Micro-papille</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.macropapille.present "> Macro-papille</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.papillegeante.present "> Papille géante</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.Pemphigoide.present">( Conj) Pemphigoïde</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.plaie.present ">Plaie</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.ptgStade.present ">PTG(stade)</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.secretion.present ">Sécrétion</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.symblepharon.present "> Symblépharon</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.tangueLite.present "> Tangue lite</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielDroit.tumeurconjonctivale.present"> Tumeur conjonctivale</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.conj.oielDroit.allAnomalies">{{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.conj">
              <p *ngIf="myExamen.laf.conj.oielGauche.normocoloreEtIntacte.present">Normocolore et intacte </p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.ankyloblepharon.present"> Ankyloblepharon</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.cercleperikeratique.present"> Cercle périkératique</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.Chemosis.present">Chemosis</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.faussemembrane.present"> Fausse membrane</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.fibroseconjonctival.present">Fibrose conjonctival</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.follicule.present">Follicule</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.granulomeconjonctivale.present">Granulome conjonctivale</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.hemorragiesousconjonctival.present">Hémorragie sous conjonctival</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.hyperhemieconj.present">Hyperhémie conj</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.icterique.present">Ictérique</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.micropapille.present ">Micro-papille</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.macropapille.present "> Macro-papille</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.papillegeante.present "> Papille géante</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.Pemphigoide.present">( Conj) Pemphigoïde</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.plaie.present ">Plaie</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.ptgStade.present ">PTG(stade)</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.secretion.present ">Sécrétion</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.symblepharon.present "> Symblépharon</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.tangueLite.present "> Tangue lite</p>
              <p class="second-color" *ngIf="myExamen.laf.conj.oielGauche.tumeurconjonctivale.present"> Tumeur conjonctivale</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.conj.oielGauche.allAnomalies">{{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Cornée-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Cornée</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.corneetransparente">
              <p *ngIf="myExamen.laf.corneetransparente.oielDroit.transparente.present==true"> Transparente</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.abces.present==true"> Abcès</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.anneuximmunitaire.present==true"> Anneux immunitaire</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.bueeEpitheliale.present==true"> Buée épithéliale</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.bullesousepitheliale.present==true"> Bulle sous épithéliale</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.buphtalmie.present==true"> Buphtalmie</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.corneaguttata.present==true"> Cornea guttata </p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.endothelite.present==true"> Endothélite</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.epaississementepithelialeStromale.present==true"> Épaississement épithéliale/stromale</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.gerontoxon.present==true"> Gérontoxon</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.hematocornee.present==true"> Hématocornée</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.hydrops.present==true"> Hydrops</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.hypoesthesie.present==true"> Hypoesthésie</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.hypoesthesiecorneenne.present==true"> Hypoesthésie cornéenne</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.irregularitePlisdeladescemet.present==true"> Irrégularité/plis de la descemet </p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.KB.present==true">KB</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.Keratopathieenbandelette.present==true"> Keratopathie en bandelette </p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.KPS.present==true"> KPS</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.leucome.present==true"> Leucome</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.microkysteEpitheliaux.present==true"> Microkyste épithéliaux</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.microkystique.present==true"> Microkystique</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.neovascularisationcorneenne.present==true"> Neovascularisationcorneenne</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.NSE.present==true"> NSE</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.oedemecorneen.present==true"> Oedème cornéen</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.opaque.present==true"> Opaque</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.opacite.present==true"> Opacité</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.perforationcorneenne.present==true"> Perforation cornéenne</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.plaiescorneenne.present==true"> Plaies cornéenne</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.plaquevernale.present==true"> Plaque vernale</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.pointcorneen.present==true"> Point cornéen</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.PRD.present==true"> PRD</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.striesdevogt.present==true"> Stries de vogt</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.ulcerationcorneenne.present==true"> Ulcération cornéenne </p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.visibiliteanormaledesnerfscorneen.present==true"> Visibilitéanormale des nerfs cornéen</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.ulcerevernal.present==true"> Ulcère vernal</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielDroit.noduledetrantas.present==true"> Nodule de trantas</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.corneetransparente.oielDroit.allAnomalies"> {{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.corneetransparente">
              <p *ngIf="myExamen.laf.corneetransparente.oielGauche.transparente.present==true"> Transparente</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.abces.present==true"> Abcès</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.anneuximmunitaire.present==true"> Anneux immunitaire</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.bueeEpitheliale.present==true"> Buée épithéliale</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.bullesousepitheliale.present==true"> Bulle sous épithéliale</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.buphtalmie.present==true"> Buphtalmie</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.corneaguttata.present==true"> Cornea guttata </p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.endothelite.present==true"> Endothélite</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.epaississementepithelialeStromale.present==true"> Épaississement épithéliale/stromale</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.gerontoxon.present==true"> Gérontoxon</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.hematocornee.present==true"> Hématocornée</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.hydrops.present==true"> Hydrops</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.hypoesthesie.present==true"> Hypoesthésie</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.hypoesthesiecorneenne.present==true"> Hypoesthésie cornéenne</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.irregularitePlisdeladescemet.present==true"> Irrégularité/plis de la descemet </p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.KB.present==true">KB</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.Keratopathieenbandelette.present==true"> Keratopathie en bandelette </p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.KPS.present==true"> KPS</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.leucome.present==true"> Leucome</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.microkysteEpitheliaux.present==true"> Microkyste épithéliaux</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.microkystique.present==true"> Microkystique</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.neovascularisationcorneenne.present==true"> Neovascularisationcorneenne</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.NSE.present==true"> NSE</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.oedemecorneen.present==true"> Oedème cornéen</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.opaque.present==true"> Opaque</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.opacite.present==true"> Opacité</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.perforationcorneenne.present==true"> Perforation cornéenne</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.plaiescorneenne.present==true"> Plaies cornéenne</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.plaquevernale.present==true"> Plaque vernale</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.pointcorneen.present==true"> Point cornéen</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.PRD.present==true"> PRD</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.striesdevogt.present==true"> Stries de vogt</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.ulcerationcorneenne.present==true"> Ulcération cornéenne </p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.visibiliteanormaledesnerfscorneen.present==true"> Visibilitéanormale des nerfs cornéen</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.ulcerevernal.present==true"> Ulcère vernal</p>
              <p class="second-color" *ngIf="myExamen.laf.corneetransparente.oielGauche.noduledetrantas.present==true"> Nodule de trantas</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.corneetransparente.oielGauche.allAnomalies"> {{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Pachymétrie-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Pachymétrie</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen?.laf && myExamen?.laf.pachymtrie">
              <p *ngIf="myExamen?.laf.pachymtrie.oielDroit.value">{{myExamen?.laf.pachymtrie.oielDroit.value}} μm </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen?.laf && myExamen?.laf.pachymtrie">
              <p *ngIf="myExamen?.laf.pachymtrie.oielGauche.value">{{myExamen?.laf.pachymtrie.oielGauche.value}} μm </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--BUT-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">BUT</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.butShirmer">
              <p *ngIf="myExamen.laf.butShirmer.oielDroit.secondes">{{myExamen.laf.butShirmer.oielDroit.secondes}} s </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.conj">
              <p *ngIf="myExamen.laf.butShirmer.oielGauche.secondes">{{myExamen.laf.butShirmer.oielGauche.secondes}} s </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Schirmer-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Schirmer</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.butShirmer">
              <p *ngIf="myExamen.laf.butShirmer.oielDroit.secondes">{{myExamen.laf.butShirmer.oielDroit.mm}} mm </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.butShirmer">
              <p *ngIf="myExamen.laf.butShirmer.oielDroit.secondes">{{myExamen.laf.butShirmer.oielGauche.mm}} mm </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Chambre antérieure-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Chambre antérieure</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.chambreanterieurealmeetprofonde">
              <p *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.calmeEtProfonde.present==true">Calme et profonde</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.CAetroite.present==true">CA étroite</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.corpsetranger.present==true">Corps étranger</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.cyclodialyse.present==true">Cyclodialyse</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.hemorragiesousconjonctival.present==true">Hémorragie sous conjonctival</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.hypopion.present==true">Hypopion</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.hyphema.present==true">Hyphema</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.ICA.present==true">ICA</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.IP.present==true">IP</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.massecristallinienne.present==true">Masse cristallinienne</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.mechedevitre.present==true ">Mèche de vitre</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.pigments.present==true ">Pigments</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.recession.present==true">Récession</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.signedulimbe.present==true">Signe du limbe +</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.testdevanherick.present==true">Test de van herick</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallcellulaire.present==true">Tyndall cellulaire </p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallproteique.present==true">Tyndall proteique</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.chambreanterieurealmeetprofonde.oielDroit.allAnomalies">{{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.chambreanterieurealmeetprofonde">
              <p *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.calmeEtProfonde.present==true">Calme et profonde</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.CAetroite.present==true">CA étroite</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.corpsetranger.present==true">Corps étranger</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.cyclodialyse.present==true">Cyclodialyse</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.hemorragiesousconjonctival.present==true">Hémorragie sous conjonctival</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.hypopion.present==true">Hypopion</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.hyphema.present==true">Hyphema</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.ICA.present==true">ICA</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.IP.present==true">IP</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.massecristallinienne.present==true">Masse cristallinienne</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.mechedevitre.present==true ">Mèche de vitre</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.pigments.present==true ">Pigments</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.recession.present==true">Récession</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.signedulimbe.present==true">Signe du limbe +</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.testdevanherick.present==true">Test de van herick</p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallcellulaire.present==true">Tyndall cellulaire </p>
              <p class="second-color" *ngIf="myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallproteique.present==true">Tyndall proteique</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.chambreanterieurealmeetprofonde.oielGauche.allAnomalies">{{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Iris-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Iris</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.irisNormale ">
              <p *ngIf="myExamen.laf.irisNormale.oielDroit.normale.present==true">Normale</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.atrophieirienne.present==true">Atrophie irienne</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.convexe.present==true">Convexe</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.cristauxirien.present==true">Cristaux irien</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.granulomeirien.present==true">Granulome irien</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.heterochromie.present==true">Hétérochromie</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.irisconcave.present==true">Iris concave</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.melanomeMassesuspecte.present==true">Mélanome/masse suspecte</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.naevusirien.present==true">Naevus irien</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.noduleirien.present==true">Nodule irien </p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.plaieirienne.present==true">Plaie irienne</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.rubeose.present==true">Rubéose </p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.SPEirien.present==true">SPE irien</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielDroit.synechieiridocristalienne.present==true">Synechie irido cristalienne</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.irisNormale.oielDroit.allAnomalies">{{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.irisNormale ">
              <p *ngIf="myExamen.laf.irisNormale.oielGauche.normale.present==true">Normale</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.atrophieirienne.present==true">Atrophie irienne</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.convexe.present==true">Convexe</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.cristauxirien.present==true">Cristaux irien</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.granulomeirien.present==true">Granulome irien</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.heterochromie.present==true">Hétérochromie</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.irisconcave.present==true">Iris concave</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.melanomeMassesuspecte.present==true">Mélanome/masse suspecte</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.naevusirien.present==true">Naevus irien</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.noduleirien.present==true">Nodule irien </p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.plaieirienne.present==true">Plaie irienne</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.rubeose.present==true">Rubéose </p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.SPEirien.present==true">SPE irien</p>
              <p class="second-color" *ngIf="myExamen.laf.irisNormale.oielGauche.synechieiridocristalienne.present==true">Synechie irido cristalienne</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.irisNormale.oielGauche.allAnomalies">{{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Tonus-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Tonus</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.toAic ">
              <p *ngIf="myExamen.laf.toAic.oielDroit.mm">{{myExamen.laf.toAic.oielDroit.mm}} mmHg</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.toAic ">
              <p *ngIf="myExamen.laf.toAic.oielGauche.mm">{{myExamen.laf.toAic.oielGauche.mm}} mmHg</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Angle irido cornéen-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Angle irido cornéen</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.angleiridocornee">
              <p *ngIf="myExamen.laf.angleiridocornee.oielDroit.ouvertSur360.present==true"> Ouvert sur 360 degrés</p>
              <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielDroit.apposition.present==true"> Apposition</p>
              <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielDroit.corpsetranger.present==true"> Corps étranger</p>
              <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielDroit.ferme.present==true "> Fermé</p>
              <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielDroit.hyphema.present==true"> Hyphema</p>
              <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielDroit.hypopion.present==true">Hypopion</p>
              <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielDroit.occludable.present==true"> Occludable</p>
              <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielDroit.pigmente.present==true"> Pigmenté</p>
              <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielDroit.rubeose.present==true"> Rubéose</p>
              <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielDroit.synechie.present==true"> Synéchie</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.angleiridocornee.oielDroit.allAnomalies"> {{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.angleiridocornee">
              <p *ngIf="myExamen.laf.angleiridocornee.oielGauche.ouvertSur360.present==true"> Ouvert sur 360 degrés</p>
              <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielGauche.apposition.present==true"> Apposition</p>
              <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielGauche.corpsetranger.present==true"> Corps étranger</p>
              <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielGauche.ferme.present==true "> Fermé</p>
              <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielGauche.hyphema.present==true"> Hyphema</p>
              <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielGauche.hypopion.present==true">Hypopion</p>
              <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielGauche.occludable.present==true"> Occludable</p>
              <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielGauche.pigmente.present==true"> Pigmenté</p>
              <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielGauche.rubeose.present==true"> Rubéose</p>
              <p class="second-color" *ngIf="myExamen.laf.angleiridocornee.oielGauche.synechie.present==true"> Synéchie</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.angleiridocornee.oielGauche.allAnomalies"> {{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--RPM-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">RPM</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.rpmpresentetsymetrique">
              <p *ngIf="myExamen.laf.rpmpresentetsymetrique.oielDroit.presentEtSymetrique.present==true"> Présent et symétrique</p>
              <p class="second-color" *ngIf="myExamen.laf.rpmpresentetsymetrique.oielDroit.marcusgun.present==true"> Marcus gun +</p>
              <p class="second-color" *ngIf="myExamen.laf.rpmpresentetsymetrique.oielDroit.mydriase.present==true"> Mydriase</p>
              <p class="second-color" *ngIf="myExamen.laf.rpmpresentetsymetrique.oielDroit.myosis.present==true"> Myosis</p>
              <p class="second-color" *ngIf="myExamen.laf.rpmpresentetsymetrique.oielDroit.semimydriase.present==true">Semi mydriase</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.rpmpresentetsymetrique.oielDroit.allAnomalies"> {{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.rpmpresentetsymetrique">
              <p *ngIf="myExamen.laf.rpmpresentetsymetrique.oielGauche.presentEtSymetrique.present==true"> Présent et symétrique</p>
              <p class="second-color" *ngIf="myExamen.laf.rpmpresentetsymetrique.oielGauche.marcusgun.present==true"> Marcus gun +</p>
              <p class="second-color" *ngIf="myExamen.laf.rpmpresentetsymetrique.oielGauche.mydriase.present==true"> Mydriase</p>
              <p class="second-color" *ngIf="myExamen.laf.rpmpresentetsymetrique.oielGauche.myosis.present==true"> Myosis</p>
              <p class="second-color" *ngIf="myExamen.laf.rpmpresentetsymetrique.oielGauche.semimydriase.present==true">Semi mydriase</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.rpmpresentetsymetrique.oielGauche.allAnomalies"> {{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Dilatation-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Dilatation</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.dilatation">
              <p *ngIf="myExamen.laf.dilatation.oielDroit.bonne.present==true">Bonne</p>
              <p class="second-color" *ngIf="myExamen.laf.dilatation.oielDroit.mauvaise.present==true ">Mauvaise</p>
              <p class="second-color" *ngIf="myExamen.laf.dilatation.oielDroit.moyenne.present==true">Moyenne </p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.dilatation.oielDroit.allAnomalies">{{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.dilatation">
              <p *ngIf="myExamen.laf.dilatation.oielGauche.bonne.present==true">Bonne</p>
              <p class="second-color" *ngIf="myExamen.laf.dilatation.oielGauche.mauvaise.present==true ">Mauvaise</p>
              <p class="second-color" *ngIf="myExamen.laf.dilatation.oielGauche.moyenne.present==true">Moyenne </p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.dilatation.oielGauche.allAnomalies">{{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Cristallin-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Cristallin</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.cristallinTransparent">
              <p *ngIf="myExamen.laf.cristallinTransparent.oielDroit.transparent.present==true">Transparent</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.aphaquie.present==true">Aphaquie</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.capsuleantcalcifie.present==true">Capsule ant calcifié</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.capsuleantrompu.present==true">Capsule ant rompu</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.cataracte.present==true">Cataracte</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.corpsetranger.present==true">Corps étranger</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.ectopie.present==true">Ectopie</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.fragilitezonulaire.present==true">Fragilité zonulaire</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.glaucomeflecken.present==true">Glaucome flecken</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.luxationSubluxation.present==true">Luxation/subluxation</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.microspheiohaquie.present==true">Microspheiohaquie</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.phacosclerose.present==true">Phacosclérose</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.phacodonesis.present==true">Phacodonésis</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.pseudophaque.present==true">Pseudophaque</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielDroit.SPE.present==true">SPE</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.cristallinTransparent.oielDroit.allAnomalies">{{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.cristallinTransparent">
              <p *ngIf="myExamen.laf.cristallinTransparent.oielGauche.transparent.present==true">Transparent</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.aphaquie.present==true">Aphaquie</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.capsuleantcalcifie.present==true">Capsule ant calcifié</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.capsuleantrompu.present==true">Capsule ant rompu</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.cataracte.present==true">Cataracte</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.corpsetranger.present==true">Corps étranger</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.ectopie.present==true">Ectopie</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.fragilitezonulaire.present==true">Fragilité zonulaire</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.glaucomeflecken.present==true">Glaucome flecken</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.luxationSubluxation.present==true">Luxation/subluxation</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.microspheiohaquie.present==true">Microspheiohaquie</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.phacosclerose.present==true">Phacosclérose</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.phacodonesis.present==true">Phacodonésis</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.pseudophaque.present==true">Pseudophaque</p>
              <p class="second-color" *ngIf="myExamen.laf.cristallinTransparent.oielGauche.SPE.present==true">SPE</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.cristallinTransparent.oielGauche.allAnomalies">{{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Vitre-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Vitre</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.vitre">
              <p *ngIf="myExamen.laf.vitre.oielDroit.calmeEtAcellulaire.present==true">Calme et acellulaire</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.banquise.present==true">Banquise</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.brides.present==true">Brides</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.cordage.present==true">Cordage</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.corpsasterode.present==true">Corps astéroïde</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.corpsetrangers.present==true">Corps étrangers</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.DPV.present==true">DPV</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.essaimagetumoral.present==true">Essaimage tumoral</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.fragmentcristallinien.present==true">Fragment cristallinien</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.hematique.present==true">Hématique</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.hemorragiepreretinienne.present==true">Hémorragie prérétinienne</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.hemorragievitreene.present==true">Hémorragie vitréene</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.Lacune.present==true">Lacune</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.LACUNE.present==true">LACUNE</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.liquefaction.present==true">Liquéfaction</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.massecristallinienne.present==true">Masse cristallinienne</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.oeufdefourmis.present==true">Oeuf de fourmis</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.persistanceduvitreprimitif.present==true">Persistance du vitré primitif</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.pigmentaire.present==true">Pigmentaire</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.precipiteencolierdeperle.present==true">Precipite en colier de perle</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.proteique.present==true">Protéique</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.synchesisetincelant.present==true">Synchesis étincelant</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.tyndallcellulaire.present==true">Tyndall cellulaire</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielDroit.voilevitreen.present==true">Voile vitréen</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.vitre.oielDroit.allAnomalies">{{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12" *ngIf="myExamen.laf && myExamen.laf.vitre">
              <p *ngIf="myExamen.laf.vitre.oielGauche.calmeEtAcellulaire.present==true">Calme et acellulaire</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.banquise.present==true">Banquise</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.brides.present==true">Brides</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.cordage.present==true">Cordage</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.corpsasterode.present==true">Corps astéroïde</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.corpsetrangers.present==true">Corps étrangers</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.DPV.present==true">DPV</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.essaimagetumoral.present==true">Essaimage tumoral</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.fragmentcristallinien.present==true">Fragment cristallinien</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.hematique.present==true">Hématique</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.hemorragiepreretinienne.present==true">Hémorragie prérétinienne</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.hemorragievitreene.present==true">Hémorragie vitréene</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.Lacune.present==true">Lacune</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.LACUNE.present==true">LACUNE</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.liquefaction.present==true">Liquéfaction</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.massecristallinienne.present==true">Masse cristallinienne</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.oeufdefourmis.present==true">Oeuf de fourmis</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.persistanceduvitreprimitif.present==true">Persistance du vitré primitif</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.pigmentaire.present==true">Pigmentaire</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.precipiteencolierdeperle.present==true">Precipite en colier de perle</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.proteique.present==true">Protéique</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.synchesisetincelant.present==true">Synchesis étincelant</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.tyndallcellulaire.present==true">Tyndall cellulaire</p>
              <p class="second-color" *ngIf="myExamen.laf.vitre.oielGauche.voilevitreen.present==true">Voile vitréen</p>
              <p class="second-color" *ngFor="let anomaly of myExamen.laf.vitre.oielGauche.allAnomalies">{{anomaly.titre}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Fond de l'œil-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Fond de l'œil</h5>
    <div class="row">
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil droit
            </h5>
            <div class="col-md-12">
              <!-- ! Normale -->
              <div *ngIf="
            myExamen.laf.papille.oielDroit.normaleRapport.present==true && myExamen.laf.papille.oielGauche.normaleRapport.present==true 
            && myExamen.laf.macule.oielDroit.normale.present==true&& myExamen.laf.macule.oielGauche.normale.present==true
            &&myExamen.laf.anomalieVasculaire.oielDroit.normale.present==true&&myExamen.laf.anomalieVasculaire.oielGauche.normale.present==true
            && myExamen.laf.retine.oielDroit.normale.present==true&&myExamen.laf.retine.oielGauche.normale.present==true
            ">
                Normale
              </div>
              <!-- !end of Normale -->
              <!-- ! Papille -->
              <div *ngIf="myExamen.laf && myExamen.laf.papille && myExamen.laf.papille.oielDroit.normaleRapport.present==false||myExamen.laf.papille.oielGauche.normaleRapport.present==false">
                <p *ngIf="myExamen.laf.papille.oielDroit.normaleRapport.present==true">( Papille ) Normale rapport C/D < 3/10 </p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.atrophiepupillaire.present==true">( Papille ) Atrophie pupillaire</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.bouclesasculairesprepapillaires.present==true">( Papille ) Boucles vasculaires prépapillaires</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.drusen.present==true">( Papille ) Drusen</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.dysversion.present==true">( Papille ) Dysversion</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.ElargissementdelazoneB.present==true">( Papille ) Élargissement de la zone B </p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.Excavationpathologique.present==true">( Papille ) Excavation pathologique</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.ExclusiondUnvaisseauxcircumlineaire.present==true">( Papille ) Exclusion d’un vaisseaux circum linéaire </p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.fibreamyeline.present==true">( Papille ) Fibre a myéline</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.fossettecolobomateuse.present==true">( Papille ) Fossette colobomateuse</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.hemorragieperipapillaire.present==true">( Papille ) Hémorragie peripapillaire </p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.isntnonrespecte.present==true">( Papille ) ISNT non respecté</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.Morningglory.present==true">( Papille ) Morning glory</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.nevoxpapillaire.present==true">( Papille ) Nevox papillaire</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.oedemepapillaire.present==true">( Papille ) Oedeme papillaire</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.papilleenfleurdeliseron.present==true">( Papille ) Papille en fleur de liseron</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.paleurpapillaire.present==true">( Papille ) Paleur papillaire</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielDroit.sriesangoide.present==true">( Papille ) Sries angoide</p>
                    <p class="second-color" *ngFor="let anomaly of myExamen.laf.papille.oielDroit.allAnomalies">( Papille ) {{anomaly.titre}}</p>
              </div>
              <!-- ! end of Papille -->
              <!-- ! Macula-->
              <div *ngIf="myExamen.laf && myExamen.laf.macule && myExamen.laf.macule.oielDroit.normale.present==false||myExamen.laf.macule.oielGauche.normale.present==false">
                <p *ngIf="myExamen.laf.macule.oielDroit.normale.present==true">( Macula ) Normale</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.aspectenoeildeboeuf.present==true">( Macula ) Aspect en oeil de boeuf</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.aspectjaunedoeufauplat.present==true"> ( Macula ) Aspect jaune d’oeuf au plat</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.aspectpoivreetsel.present==true"> ( Macula ) Aspect poivre et sel</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.couronneexcsudatif.present==true"> ( Macula ) Couronne excsudatif </p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.DEP.present==true">( Macula ) DEP </p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.dreusenmaculaire.present==true"> ( Macula ) Dreusen maculaire</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.DSR.present==true"> ( Macula ) DSR</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.eLogettemaculaire.present==true">( Macula ) ELogette maculaire</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.etoilemaculaire.present==true">( Macula ) Etoile maculaire </p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.hematomemaculaire.present==true">( Macula ) Hematome maculaire </p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.hemorragiemaculaire.present==true">( Macula ) Hemorragie maculaire </p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.hemorragieretrohyaloidienne.present==true"> ( Macula ) Hemorragie retrohyaloidienne </p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.macroanevrysme.present==true"> ( Macula ) Macroanevrysme</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.macularougecerise.present==true"> ( Macula ) Macula rouge cerise</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.membraneepimaculairen.present==true"> ( Macula ) Membrane epimaculairen</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.perterefletfoveolaire.present==true"> ( Macula ) Perte reflet foveolaire</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.pigmentationanormale.present==true"> ( Macula ) Pigmentation anormale</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.plagedatrophie.present==true">( Macula ) Plage d’atrophie</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.remaniementmicorkystique.present==true">( Macula ) Remaniement micorkystique </p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielDroit.trousmaculaire.present==true">( Macula ) Trous maculaire </p>
                <p class="second-color" *ngFor="let anomaly of myExamen.laf.macule.oielDroit.allAnomalies">( Macula ) {{anomaly.titre}}</p>
              </div>
              <!-- ! end of Macula-->
              <!-- ! Anomalie vasculaire   -->
              <div *ngIf="myExamen.laf && myExamen.laf.anomalieVasculaire && myExamen.laf.anomalieVasculaire.oielDroit.normale.present==false||myExamen.laf.anomalieVasculaire.oielGauche.normale.present==false">
                <p *ngIf="myExamen.laf.anomalieVasculaire.oielDroit.normale.present==true">( Anomalie vasculaire ) Normale</p>
                <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielDroit.arteriosclerose.present==true">( Anomalie vasculaire ) Artériosclérose </p>
                <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielDroit.signedeRH.present==true"> ( Anomalie vasculaire ) Signe de RH</p>
                <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielDroit.signedeRD.present==true">( Anomalie vasculaire ) Signe de RD</p>
                <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielDroit.signedOcclusionVeineuse.present==true"> ( Anomalie vasculaire ) Signe d’Occlusion Veineuse</p>
                <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielDroit.signedOcclusionARTERIELLE.present==true">( Anomalie vasculaire ) Signe d’Occlusion Artérielle</p>
                <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielDroit.vasculite.present==true"> ( Anomalie vasculaire ) Vasculite </p>
                <p class="second-color" *ngFor="let anomaly of myExamen.laf.anomalieVasculaire.oielDroit.allAnomalies">( Anomalie vasculaire ) {{anomaly.titre}}</p>
              </div>
              <!-- ! end of Anomalie vasculaire   -->
              <!-- ! Rétine -->
              <div *ngIf="myExamen.laf && myExamen.laf.retine && myExamen.laf.retine.oielDroit.normale.present==false||myExamen.laf.retine.oielGauche.normale.present==false">
                <p *ngIf="myExamen.laf.retine.oielDroit.normale.present==true">( Rétine ) Normale</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.blancavecpression.present==true"> ( Rétine ) Blanc avec pression</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.blancsanspression.present==true"> ( Rétine ) Blanc sans pression</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.decollementchorodien.present==true"> ( Rétine ) Décollement choroïdien</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.decollementretine.present==true">( Rétine ) Décollement de rétine</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.degenerescencelattice.present==true"> ( Rétine ) Dégénérescence lattice</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.dehiscenceretinienne.present==true"> ( Rétine ) Déhiscence rétinienne</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.DEP.present==true"> ( Rétine ) DEP</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.DSR.present==true"> ( Rétine ) DSR </p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.exsudat.present==true">( Rétine ) Exsudat </p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.foyer.present==true">( Rétine ) Foyer </p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.masseretinochoroidienne.present==true"> ( Rétine ) Masse retino/choroidienne</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.givre.present==true">( Rétine ) Givre</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.hemorragieretinienne.present==true"> ( Rétine ) Hémorragie rétinienne</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.macroanevrisme.present==true"> ( Rétine ) Macroanevrisme</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.microanevrisme.present==true"> ( Rétine ) Microanévrisme</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.nodulecotonneux.present==true"> ( Rétine ) Nodule cotonneux </p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.palissade.present==true"> ( Rétine ) Palissade</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.pigmentation.present==true">( Rétine ) Pigmentation</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.retinoschisis.present==true"> ( Rétine ) Retino schisis</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielDroit.tachederp.present==true">( Rétine ) Tâche de rp </p>
                <p class="second-color" *ngFor="let anomaly of myExamen.laf.retine.oielDroit.allAnomalies">( Rétine ) {{anomaly.titre}}</p>
              </div>
              <!-- ! end of Rétine -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
          <div class="card-body">
            <h5 class="card-title text-center" [style.color]="medcin.colorTemplates"><img src="assets/images/oeil-conclusion.png" class="p-img-oeil"> Oeil
              gauche</h5>
            <div class="col-md-12">
              <!-- ! Normale -->
              <div *ngIf="
            myExamen.laf.papille.oielDroit.normaleRapport.present==true && myExamen.laf.papille.oielGauche.normaleRapport.present==true 
            && myExamen.laf.macule.oielDroit.normale.present==true&& myExamen.laf.macule.oielGauche.normale.present==true
            &&myExamen.laf.anomalieVasculaire.oielDroit.normale.present==true&&myExamen.laf.anomalieVasculaire.oielGauche.normale.present==true
            && myExamen.laf.retine.oielDroit.normale.present==true&&myExamen.laf.retine.oielGauche.normale.present==true
            ">
                Normale
              </div>
              <!-- !end of Normale -->
              <!-- ! Papille -->
              <div *ngIf="myExamen.laf && myExamen.laf.papille && myExamen.laf.papille.oielDroit.normaleRapport.present==false||myExamen.laf.papille.oielGauche.normaleRapport.present==false">
                <p *ngIf="myExamen.laf.papille.oielGauche.normaleRapport.present==true"> ( Papille ) Normale rapport C/D < 3/10 </p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.atrophiepupillaire.present==true">( Papille ) Atrophie pupillaire</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.bouclesasculairesprepapillaires.present==true">( Papille ) Boucles vasculaires prépapillaires</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.drusen.present==true">( Papille ) Drusen</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.dysversion.present==true">( Papille ) Dysversion</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.ElargissementdelazoneB.present==true">( Papille ) Élargissement de la zone B </p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.Excavationpathologique.present==true">( Papille ) Excavation pathologique</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.ExclusiondUnvaisseauxcircumlineaire.present==true">( Papille ) Exclusion d’un vaisseaux circum linéaire </p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.fibreamyeline.present==true">( Papille ) Fibre a myéline</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.fossettecolobomateuse.present==true">( Papille ) Fossette colobomateuse</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.hemorragieperipapillaire.present==true">( Papille ) Hémorragie peripapillaire </p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.isntnonrespecte.present==true">( Papille ) ISNT non respecté</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.Morningglory.present==true">( Papille ) Morning glory</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.nevoxpapillaire.present==true">( Papille ) Nevox papillaire</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.oedemepapillaire.present==true">( Papille ) Oedeme papillaire</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.papilleenfleurdeliseron.present==true">( Papille ) Papille en fleur de liseron</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.paleurpapillaire.present==true">( Papille ) Paleur papillaire</p>
                    <p class="second-color" *ngIf="myExamen.laf.papille.oielGauche.sriesangoide.present==true">( Papille ) Sries angoide</p>
                    <p class="second-color" *ngFor="let anomaly of myExamen.laf.papille.oielGauche.allAnomalies">( Papille ) {{anomaly.titre}}</p>
              </div>
              <!-- ! end of Papille -->
              <!-- ! Macula-->
              <div *ngIf="myExamen.laf && myExamen.laf.macule && myExamen.laf.macule.oielDroit.normale.present==false||myExamen.laf.macule.oielGauche.normale.present==false">
                <p *ngIf="myExamen.laf.macule.oielGauche.normale.present==true">( Macula ) Normale</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.aspectenoeildeboeuf.present==true">( Macula ) Aspect en oeil de boeuf</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.aspectjaunedoeufauplat.present==true"> ( Macula ) Aspect jaune d’oeuf au plat</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.aspectpoivreetsel.present==true"> ( Macula ) Aspect poivre et sel</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.couronneexcsudatif.present==true"> ( Macula ) Couronne excsudatif </p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.DEP.present==true">( Macula ) DEP </p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.dreusenmaculaire.present==true"> ( Macula ) Dreusen maculaire</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.DSR.present==true"> ( Macula ) DSR</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.eLogettemaculaire.present==true">vELogette maculaire</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.etoilemaculaire.present==true">( Macula ) Etoile maculaire </p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.hematomemaculaire.present==true">( Macula ) Hematome maculaire </p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.hemorragiemaculaire.present==true">( Macula ) Hemorragie maculaire </p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.hemorragieretrohyaloidienne.present==true"> ( Macula ) Hemorragie retrohyaloidienne </p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.macroanevrysme.present==true"> ( Macula ) Macroanevrysme</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.macularougecerise.present==true"> ( Macula ) Macula rouge cerise</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.membraneepimaculairen.present==true"> ( Macula ) Membrane epimaculairen</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.perterefletfoveolaire.present==true"> vPerte reflet foveolaire</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.pigmentationanormale.present==true"> ( Macula ) Pigmentation anormale</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.plagedatrophie.present==true">( Macula ) Plage d’atrophie</p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.remaniementmicorkystique.present==true">( Macula ) Remaniement micorkystique </p>
                <p class="second-color" *ngIf="myExamen.laf.macule.oielGauche.trousmaculaire.present==true">( Macula ) Trous maculaire </p>
                <p class="second-color" *ngFor="let anomaly of myExamen.laf.macule.oielGauche.allAnomalies">( Macula ) {{anomaly.titre}}</p>
              </div>
              <!-- ! end of Macula-->
              <!-- ! Anomalie vasculaire   -->
              <div *ngIf="myExamen.laf && myExamen.laf.anomalieVasculaire && myExamen.laf.anomalieVasculaire.oielDroit.normale.present==false||myExamen.laf.anomalieVasculaire.oielGauche.normale.present==false">
                <p *ngIf="myExamen.laf.anomalieVasculaire.oielGauche.normale.present==true">( Anomalie vasculaire ) Normale</p>
                <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielGauche.arteriosclerose.present==true">( Anomalie vasculaire ) Artériosclérose </p>
                <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielGauche.signedeRH.present==true"> ( Anomalie vasculaire ) Signe de RH</p>
                <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielGauche.signedeRD.present==true">( Anomalie vasculaire ) Signe de RD</p>
                <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielGauche.signedOcclusionVeineuse.present==true"> ( Anomalie vasculaire ) Signe d’Occlusion Veineuse</p>
                <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielGauche.signedOcclusionARTERIELLE.present==true">( Anomalie vasculaire ) Signe d’Occlusion Artérielle</p>
                <p class="second-color" *ngIf="myExamen.laf.anomalieVasculaire.oielGauche.vasculite.present==true"> ( Anomalie vasculaire ) Vasculite </p>
                <p class="second-color" *ngFor="let anomaly of myExamen.laf.anomalieVasculaire.oielGauche.allAnomalies">( Anomalie vasculaire ) {{anomaly.titre}}</p>
              </div>
              <!-- ! end of Anomalie vasculaire   -->
              <!-- ! Rétine -->
              <div *ngIf="myExamen.laf && myExamen.laf.retine && myExamen.laf.retine.oielDroit.normale.present==false||myExamen.laf.retine.oielGauche.normale.present==false">
                <p *ngIf="myExamen.laf.retine.oielGauche.normale.present==true">( Rétine ) Normale</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.blancavecpression.present==true"> ( Rétine ) Blanc avec pression</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.blancsanspression.present==true"> ( Rétine ) Blanc sans pression</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.decollementchorodien.present==true"> ( Rétine ) Décollement choroïdien</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.decollementretine.present==true">( Rétine ) Décollement de rétine</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.degenerescencelattice.present==true"> ( Rétine ) Dégénérescence lattice</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.dehiscenceretinienne.present==true"> ( Rétine ) Déhiscence rétinienne</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.DEP.present==true"> ( Rétine ) DEP</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.DSR.present==true"> ( Rétine ) DSR </p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.exsudat.present==true">( Rétine ) Exsudat </p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.foyer.present==true">( Rétine ) Foyer </p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.masseretinochoroidienne.present==true"> ( Rétine ) Masse retino/choroidienne</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.givre.present==true"> ( Rétine ) Givre</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.hemorragieretinienne.present==true"> ( Rétine ) Hémorragie rétinienne</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.macroanevrisme.present==true"> ( Rétine ) Macroanevrisme</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.microanevrisme.present==true"> ( Rétine ) Microanévrisme</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.nodulecotonneux.present==true"> ( Rétine ) Nodule cotonneux </p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.palissade.present==true"> ( Rétine ) Palissade</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.pigmentation.present==true">( Rétine ) Pigmentation</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.retinoschisis.present==true"> ( Rétine ) Retino schisis</p>
                <p class="second-color" *ngIf="myExamen.laf.retine.oielGauche.tachederp.present==true">( Rétine ) Tâche de rp </p>
                <p class="second-color" *ngFor="let anomaly of myExamen.laf.retine.oielGauche.allAnomalies">( Rétine ) {{anomaly.titre}}</p>
              </div>
              <!-- ! end of Rétine -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Diagnostic spécifique-->
    <h5 class="text-left mt-2" style="text-decoration: underline;" [style.color]="medcin.colorTemplates">Diagnostic spécifique</h5>
    <div class="card" style="border: 1px solid" [style.borderColor]="medcin.colorTemplates">
      <div class="card-body">
        <div class="col-md-12" *ngIf="myExamen.haveSpecificDiagnostic && myExamen.additionalConclusion.length > 0">
          <p class="second-color" *ngFor="let type of myExamen.additionalConclusion">{{type}}</p>
        </div>
      </div>
    </div>
    <!-- Pied de page  -->
    <app-footer-template></app-footer-template>

    <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
    <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.4/dist/umd/popper.min.js"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
  </div>