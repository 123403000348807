import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service'
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { AnomalieService } from 'src/app/services/anomalie/anomalie.service';

@Component({
  selector: 'app-conj',
  templateUrl: './conj.component.html',
  styleUrls: ['./conj.component.css', '../laf.component.css', '../../examen.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(500, style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class ConjComponent implements OnInit {
  //--------------------------------------------------- conj
  conj = false;
  conjListLeft = [
    "Normocolore et intacte",
    "Plaie",
    "Chémosis",
    "Hémorragie sous conjonctivale",
    "Sécrétion",
    "Micro-papille",
    "Macro-papille",
    "Papille géante",
    "Follicule",
    "Fausse membrane",
    "Pemphigoïde",
    "Hyperhémie con",
    "Fibrose conjonctival",
    "Ictérique",
    "Symblépharon",
    "Ankyloblepharon",
    "Cercle périkératique",
    "PTG(stade)",
    "Tangue lite",
    "Tumeur conjonctivale",
    "Granulome conjonctivale",
  ];
  conjListRight = [
    "Normocolore et intacte",
    "Plaie",
    "Chémosis",
    "Hémorragie sous conjonctivale",
    "Sécrétion",
    "Micro-papille",
    "Macro-papille",
    "Papille géante",
    "Follicule",
    "Fausse membrane",
    "Pemphigoïde",
    "Hyperhémie con",
    "Fibrose conjonctival",
    "Ictérique",
    "Symblépharon",
    "Ankyloblepharon",
    "Cercle périkératique",
    "PTG(stade)",
    "Tangue lite",
    "Tumeur conjonctivale",
    "Granulome conjonctivale",
  ];
  conjtaillePlaieLeft: number
  conjtaillePlaieRight: number
  conjTumeurConjLeft: number
  conjTumeurConjRight: number
  //-------------------autres
  oldListConsultations: any[] = []
  myExamen: any
  examRefraction: any
  examLaf: any
  examAnexe: any
  patient: any;
  selectedMotif: any
  timer: any
  minutes: any
  hour: any
  seconds: any
  idExamen: any;
  idPatient: any;

  //!----- anomalies by a doctor

  // Conj : normocolore et intacte
  anomaliesByDoctorConj: any[] = []
  oldlistAnomaliesConj = ["Normocolore et intacte", "Ankyloblepharon", "Cercle périkératique", "Chémosis", "Fausse membrane", "Fibrose conjonctival", "Follicule", "Granulome conjonctivale", "Hémorragie sous conjonctivale", "Hyperhémie conjonctivale", "Ictérique", "Micro-papille", "Macro-papille", "Papille géante", "Pemphigoïde", "Plaie", "PTG(stade)", "Sécrétion", "Symblépharon", "Tumeur conjonctivale"]
  newAnomalieConj = ""
  //!----- end anomalies by a doctor
  // Show More Variables
  showMoreConj = false
  //end of Show More Variables
  displayConj = true
  // send obkect
  objecttoSend = {
    annexeOD: {},
    annexeOG: {},
    inspectionOD: {},
    inspectionOG: {},
    sclereOD: {},
    sclereOG: {},
    conjOD: {},
    conjOG: {},
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    source: "",
    sansCycloSphereDroit: 0, sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0, sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0, avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0, avecCycloCylindreGauche: 0,
    addOD: 0, addOG: 0,
    nextAppointment: "", nextAppointmentDuration: "", nextMotif: ""
  }
  isToastVisible = false
  constructor(private elementRef: ElementRef, private anomalieService: AnomalieService, private sharedDataService: SharedDataService, private patientService: PatientService, private responsiveService: ResponsiveService, private router: Router, private activatedRoute: ActivatedRoute
    , private examenService: ExamenService, private _sanitizer: DomSanitizer, public iziToast: Ng2IzitoastService, private medecinService: MedecinService) {
    let params: any = this.activatedRoute.snapshot.params;
    if (params.id && params.id != undefined && params.id != null && params.id != "undefined") {
      this.patientService.getPatientByIDNewFunction(params.id).subscribe(patient => {
        this.patient = patient;
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    } else {
      this.router.navigate(['/medecin/patients-liste']);
    }
  }

  ngOnInit() {
    const idExamen = this.activatedRoute.snapshot.paramMap.get('idExamen');
    const idPatient = this.activatedRoute.snapshot.paramMap.get('id');
    // * ------------LAF
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getLAFByZone(idExamen, 'laf-conj').subscribe((data: any) => {
        this.examLaf = data
        if (this.examLaf) {
          this.configureDataExamen(idPatient)
        } else {
          this.selectedMotif = localStorage.getItem('motif')
        }
      },
        err => {
          return false;
        });
    }
    this.setupEventListeners();
  }

  setupEventListeners(): void {
    this.elementRef.nativeElement.addEventListener('click', this.onClickOrUpdate.bind(this));
    const inputElements = this.elementRef.nativeElement.querySelectorAll('input');
    inputElements.forEach((input: HTMLInputElement) => {
      input.addEventListener('input', this.onClickOrUpdate.bind(this));
    });
  }

  onClickOrUpdate(event: Event): void {
    // Function to be called on click or update
    this.sharedDataService.updateDataBtnLaf({ 'type': "laf-conj", 'value': this.examLaf.laf.conj });
  }

  configureDataExamen(idPatient) {
    // !Historique LAF
    // this.examenService.getInspectionHistoriques(idPatient,"laf").subscribe((data:any) => {
    //  this.oldListConsultations=data
    //  },
    //    err => {
    //      return false;
    //    });
    //! Conj : normocolore et intacte
    this.anomalieService.getAnomalieByDoctor("laf-conj").subscribe(data => {
      this.anomaliesByDoctorConj = data.results;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste']);
        return false;
      });

  }
  // update examen
  updateExamen(myExamen) {
    this.examenService.updateExamen(myExamen).subscribe((_patient: any) => {
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
    return true;
  }

  showMoreConjSection() {
    this.showMoreConj = !this.showMoreConj
  }

  displayAndHideConj() {
    this.displayConj = !this.displayConj
  }
  //------------------------------------------------- conj
  showConj() {
    this.examLaf.laf.conj.present = false
  }

  addRemoveFromConjRight(value) {
    this.examLaf.laf.conj.oielDroit.normocoloreEtIntacte.present = false
    switch (value) {
      case 'Normocolore et intacte': this.setAllValueToFlase(this.examLaf.laf.conj.oielDroit); this.examLaf.laf.conj.oielDroit.normocoloreEtIntacte.present = true
        break
      case 'Plaie': this.examLaf.laf.conj.oielDroit.plaie.present = !this.examLaf.laf.conj.oielDroit.plaie.present
        break
      case 'Chémosis': this.examLaf.laf.conj.oielDroit.Chemosis.present = !this.examLaf.laf.conj.oielDroit.Chemosis.present
        break
      case 'Hémorragie sous conjonctivale': this.examLaf.laf.conj.oielDroit.hemorragiesousconjonctival.present = !this.examLaf.laf.conj.oielDroit.hemorragiesousconjonctival.present
        break
      case 'Sécrétion': this.examLaf.laf.conj.oielDroit.secretion.present = !this.examLaf.laf.conj.oielDroit.secretion.present
        break
      case 'Micro-papille': this.examLaf.laf.conj.oielDroit.micropapille.present = !this.examLaf.laf.conj.oielDroit.micropapille.present
        break
      case 'Macro-papille': this.examLaf.laf.conj.oielDroit.macropapille.present = !this.examLaf.laf.conj.oielDroit.macropapille.present
        break
      case 'Papille géante': this.examLaf.laf.conj.oielDroit.papillegeante.present = !this.examLaf.laf.conj.oielDroit.papillegeante.present
        break
      case 'Follicule': this.examLaf.laf.conj.oielDroit.follicule.present = !this.examLaf.laf.conj.oielDroit.follicule.present
        break
      case 'Fausse membrane': this.examLaf.laf.conj.oielDroit.faussemembrane.present = !this.examLaf.laf.conj.oielDroit.faussemembrane.present
        break
      case 'Pemphigoïde': this.examLaf.laf.conj.oielDroit.Pemphigoide.present = !this.examLaf.laf.conj.oielDroit.Pemphigoide.present
        break
      case 'Hyperhémie conjonctivale': this.examLaf.laf.conj.oielDroit.hyperhemieconj.present = !this.examLaf.laf.conj.oielDroit.hyperhemieconj.present
        break
      case 'Fibrose conjonctival': this.examLaf.laf.conj.oielDroit.fibroseconjonctival.present = !this.examLaf.laf.conj.oielDroit.fibroseconjonctival.present
        break
      case 'Ictérique': this.examLaf.laf.conj.oielDroit.icterique.present = !this.examLaf.laf.conj.oielDroit.icterique.present
        break
      case 'Symblépharon': this.examLaf.laf.conj.oielDroit.symblepharon.present = !this.examLaf.laf.conj.oielDroit.symblepharon.present
        break
      case 'Ankyloblepharon': this.examLaf.laf.conj.oielDroit.ankyloblepharon.present = !this.examLaf.laf.conj.oielDroit.ankyloblepharon.present
        break
      case 'Cercle périkératique': this.examLaf.laf.conj.oielDroit.cercleperikeratique.present = !this.examLaf.laf.conj.oielDroit.cercleperikeratique.present
        break
      case 'PTG(stade)': this.examLaf.laf.conj.oielDroit.ptgStade.present = !this.examLaf.laf.conj.oielDroit.ptgStade.present
        break
      case 'Tangue lite': this.examLaf.laf.conj.oielDroit.tangueLite.present = !this.examLaf.laf.conj.oielDroit.tangueLite.present
        break
      case 'Tumeur conjonctivale': this.examLaf.laf.conj.oielDroit.tumeurconjonctivale.present = !this.examLaf.laf.conj.oielDroit.tumeurconjonctivale.present
        break
      case 'Granulome conjonctivale': this.examLaf.laf.conj.oielDroit.granulomeconjonctivale.present = !this.examLaf.laf.conj.oielDroit.granulomeconjonctivale.present
        break
    }
    this.setConjToTrue(this.examLaf.laf.conj.oielDroit);
    this.objecttoSend.conjOD = this.examLaf.laf.conj.oielDroit
    this.objecttoSend.source = "conjOD"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;

  }
  addRemoveFromConjLeft(value) {
    this.examLaf.laf.conj.oielGauche.normocoloreEtIntacte.present = false
    switch (value) {
      case 'Normocolore et intacte': this.setAllValueToFlase(this.examLaf.laf.conj.oielGauche); this.examLaf.laf.conj.oielGauche.normocoloreEtIntacte.present = true
        break
      case 'Plaie': this.examLaf.laf.conj.oielGauche.plaie.present = !this.examLaf.laf.conj.oielGauche.plaie.present
        break
      case 'Chémosis': this.examLaf.laf.conj.oielGauche.Chemosis.present = !this.examLaf.laf.conj.oielGauche.Chemosis.present
        break
      case 'Hémorragie sous conjonctivale': this.examLaf.laf.conj.oielGauche.hemorragiesousconjonctival.present = !this.examLaf.laf.conj.oielGauche.hemorragiesousconjonctival.present
        break
      case 'Sécrétion': this.examLaf.laf.conj.oielGauche.secretion.present = !this.examLaf.laf.conj.oielGauche.secretion.present
        break
      case 'Micro-papille': this.examLaf.laf.conj.oielGauche.micropapille.present = !this.examLaf.laf.conj.oielGauche.micropapille.present
        break
      case 'Macro-papille': this.examLaf.laf.conj.oielGauche.macropapille.present = !this.examLaf.laf.conj.oielGauche.macropapille.present
        break
      case 'Papille géante': this.examLaf.laf.conj.oielGauche.papillegeante.present = !this.examLaf.laf.conj.oielGauche.papillegeante.present
        break
      case 'Follicule': this.examLaf.laf.conj.oielGauche.follicule.present = !this.examLaf.laf.conj.oielGauche.follicule.present
        break
      case 'Fausse membrane': this.examLaf.laf.conj.oielGauche.faussemembrane.present = !this.examLaf.laf.conj.oielGauche.faussemembrane.present
        break
      case 'Pemphigoïde': this.examLaf.laf.conj.oielGauche.Pemphigoide.present = !this.examLaf.laf.conj.oielGauche.Pemphigoide.present
        break
      case 'Hyperhémie conjonctivale': this.examLaf.laf.conj.oielGauche.hyperhemieconj.present = !this.examLaf.laf.conj.oielGauche.hyperhemieconj.present
        break
      case 'Fibrose conjonctival': this.examLaf.laf.conj.oielGauche.fibroseconjonctival.present = !this.examLaf.laf.conj.oielGauche.fibroseconjonctival.present
        break
      case 'Ictérique': this.examLaf.laf.conj.oielGauche.icterique.present = !this.examLaf.laf.conj.oielGauche.icterique.present
        break
      case 'Symblépharon': this.examLaf.laf.conj.oielGauche.symblepharon.present = !this.examLaf.laf.conj.oielGauche.symblepharon.present
        break
      case 'Ankyloblepharon': this.examLaf.laf.conj.oielGauche.ankyloblepharon.present = !this.examLaf.laf.conj.oielGauche.ankyloblepharon.present
        break
      case 'Cercle périkératique': this.examLaf.laf.conj.oielGauche.cercleperikeratique.present = !this.examLaf.laf.conj.oielGauche.cercleperikeratique.present
        break
      case 'PTG(stade)': this.examLaf.laf.conj.oielGauche.ptgStade.present = !this.examLaf.laf.conj.oielGauche.ptgStade.present
        break
      case 'Tangue lite': this.examLaf.laf.conj.oielGauche.tangueLite.present = !this.examLaf.laf.conj.oielGauche.tangueLite.present
        break
      case 'Tumeur conjonctivale': this.examLaf.laf.conj.oielGauche.tumeurconjonctivale.present = !this.examLaf.laf.conj.oielGauche.tumeurconjonctivale.present
        break
      case 'Granulome conjonctivale': this.examLaf.laf.conj.oielGauche.granulomeconjonctivale.present = !this.examLaf.laf.conj.oielGauche.granulomeconjonctivale.present
        break
    }
    this.setConjToTrue(this.examLaf.laf.conj.oielGauche);
    this.objecttoSend.conjOG = this.examLaf.laf.conj.oielGauche
    this.objecttoSend.source = "conjOG"
    this.sharedDataService.updateData(this.objecttoSend);
    return true;

  }


  onChangeconjtaillePlaieLeft() {
    if (this.examLaf.laf.conj.oielGauche.plaie.taillet <= 0) {
      this.examLaf.laf.conj.oielGauche.plaie.taille = 0
    }
  }
  onChangeconjtaillePlaieRight() {
    if (this.examLaf.laf.conj.oielDroit.plaie.taille <= 0) {
      this.examLaf.laf.conj.oielDroit.plaie.taille = 0
    }
  }
  onChangeTumeurConjLeft() {
    if (this.examLaf.laf.conj.oielGauche.tumeurconjonctivale.taille <= 0) {
      this.examLaf.laf.conj.oielGauche.tumeurconjonctivale.taille = 0
    }
  }
  onChangeTumeurConjRight() {
    if (this.examLaf.laf.conj.oielDroit.tumeurconjonctivale.taille <= 0) {
      this.examLaf.laf.conj.oielDroit.tumeurconjonctivale.taille = 0
    }
  }
  selectConjptgStadeRight(value) {
    this.examLaf.laf.conj.oielDroit.ptgStade.stade = value
  }
  selectConjptgStadeLeft(value) {
    this.examLaf.laf.conj.oielGauche.ptgStade.stade = value
  }

  //!--------------------Conj : normocolore et intacte
  // Conj dynamic anomaly
  addNewAnomalyConj(event) {
    const showToast = (message, messageColor, titleColor = messageColor, progressBarColor, backgroundColor) => {
      if (!this.isToastVisible) {
        this.isToastVisible = true;
        this.iziToast.show({
          message,
          messageColor,
          titleColor,
          progressBarColor,
          position: 'topRight',
          timeout: 5000,
          backgroundColor,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
          onClosed: () => {
            this.isToastVisible = false;
          }
        });
      }
    };

    if (this.anomaliesByDoctorConj && this.anomaliesByDoctorConj.length > 4) {
      showToast("Actuellement vous avez la possibilité d'ajouter seulement 5 anomalies", '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
      this.newAnomalieConj = "";
      return false;
    }

    const value = event?.target?.value;
    if (value && value.length > 1 && value.length < 100) {
      const anomalyExists = this.anomaliesByDoctorConj.some(x => x.titre?.toLowerCase() === value.toLowerCase());
      const oldAnomalyExists = this.oldlistAnomaliesConj.some(x => x.toLowerCase() === value.toLowerCase());

      if (!anomalyExists && !oldAnomalyExists) {
        this.anomalieService.ajoutAnomalie({
          "titre": value.charAt(0).toUpperCase() + value.slice(1),
          "description": "",
          "zone": "laf-conj",
        }).subscribe((data) => {
          this.newAnomalieConj = "";
          this.anomaliesByDoctorConj.push(data);
          showToast('Bien enregistré', '#386641', '#386641', '#6a994e', '#dde5b6');
        });
        return true;
      } else {
        showToast("L'anomalie existe dans la liste", '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
        this.newAnomalieConj = "";
        return false;
      }
    } else {
      showToast('Merci de saisir au moins 2 caractères', '#800f2f', '#800f2f', '#c9184a', '#ff8fa3');
      this.newAnomalieConj = "";
      return false;
    }
  }

  // Right Conj 
  addRemoveFromConjRightNew(value, id) {
    this.examLaf.laf.conj.oielDroit.normocoloreEtIntacte.present = false
    let truefalseexist = this.examLaf.laf.conj.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.conj.oielDroit.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.conjOD = this.examLaf.laf.conj.oielDroit
      this.objecttoSend.source = "conjOD"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.conj.oielDroit.allAnomalies = this.examLaf.laf.conj.oielDroit.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.conjOD = this.examLaf.laf.conj.oielDroit
      this.objecttoSend.source = "conjOD"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setConjToTrue(this.examLaf.laf.conj.oielDroit)
      return true;
    }

  }
  checkExistAnomalieConjRight(id) {
    return this.examLaf.laf.conj.oielDroit.allAnomalies.some(x => x.idAnomalie == id)
  }
  // left Conj dyna
  addRemoveFromConjLeftNew(value, id) {
    this.examLaf.laf.conj.oielGauche.normocoloreEtIntacte.present = false
    let truefalseexist = this.examLaf.laf.conj.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
    if (!truefalseexist) {
      this.examLaf.laf.conj.oielGauche.allAnomalies.push({ "titre": value, "description": "", "idAnomalie": id })
      this.objecttoSend.conjOG = this.examLaf.laf.conj.oielGauche
      this.objecttoSend.source = "conjOG"
      this.sharedDataService.updateData(this.objecttoSend);
      return true;
    }
    if (truefalseexist) {
      this.examLaf.laf.conj.oielGauche.allAnomalies = this.examLaf.laf.conj.oielGauche.allAnomalies.filter(x => x.idAnomalie != id);
      this.objecttoSend.conjOG = this.examLaf.laf.conj.oielGauche
      this.objecttoSend.source = "conjOG"
      this.sharedDataService.updateData(this.objecttoSend);
      this.setConjToTrue(this.examLaf.laf.conj.oielGauche)
      return true;
    }

  }
  checkExistAnomalieConjLeft(id) {
    return this.examLaf.laf.conj.oielGauche.allAnomalies.some(x => x.idAnomalie == id)
  }
  // !------------------end of Conj : normocolore et intacte
  deleteAnomalie(anomaly, zone) {
    this.anomalieService.deleteAnomalie(anomaly.id).subscribe((data: any) => {
      if (zone === 'laf-conj') {
        this.anomaliesByDoctorConj.splice(this.anomaliesByDoctorConj.indexOf(anomaly), 1)
      }

      this.iziToast.show({
        message: 'Bien supprimé',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
  }
  // conj
  setConjToTrue(myObject) {
    var allPresentFalse = true
    Object.keys(myObject).forEach(key => {
      if (myObject[key].present === true && key != "normocoloreEtIntacte") {
        allPresentFalse = false
      }
    });
    // If all "present" attributes are false, set "test" to true
    if (allPresentFalse) {
      myObject.normocoloreEtIntacte.present = true;
    }
    return myObject
  }

  setAllValueToFlase(obj) {
    return Object.keys(obj).forEach(key => {
      obj[key].present = false;
      obj.allAnomalies = []
    });
  }
}
