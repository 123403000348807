<div class="card">
    <div class="card-body">
        <div class="titleCenter">
            <h4 class="cursor-pointer" title="Afficher/Cacher Conj" (click)="displayAndHideConj()">Conj</h4>
            <div class="arrow arrow-up btnConj" *ngIf="displayConj" title="Cacher Conj" (click)="displayAndHideConj()">▲</div>
            <div class="arrow arrow-up btnConj" *ngIf="!displayConj" title="Afficher Conj" (click)="displayAndHideConj()">▼</div>
        </div>
        <div *ngIf="displayConj" @fade>
            <div class="col-12 sansCyclo">
                <div class="cadreOeilDroit2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" alt="" class="img-oeil" />
                        <h5 id="OeilTitle">Oeil droit</h5>
                    </div>
                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:  examLaf?.laf.conj.oielDroit.normocoloreEtIntacte.present==true}" (click)="addRemoveFromConjRight('Normocolore et intacte')">
                                    Normocolore et intacte
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.conj.oielDroit.ankyloblepharon.present==true}" (click)="addRemoveFromConjRight('Ankyloblepharon')">
                                    Ankyloblepharon
                                </li>
                                <div *ngIf="showMoreConj">
                                    <li [ngClass]="{ selectedLi: examLaf?.laf.conj.oielDroit.cercleperikeratique.present==true}" (click)="addRemoveFromConjRight('Cercle périkératique')">
                                        Cercle périkératique
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.conj.oielDroit.Chemosis.present==true}" (click)="addRemoveFromConjRight('Chémosis')">
                                        Chémosis
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.conj.oielDroit.faussemembrane.present==true}" (click)="addRemoveFromConjRight('Fausse membrane')">
                                        Fausse membrane
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.conj.oielDroit.fibroseconjonctival.present==true}" (click)="addRemoveFromConjRight('Fibrose conjonctival')">
                                        Fibrose conjonctival
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.conj.oielDroit.follicule.present==true}" (click)="addRemoveFromConjRight('Follicule')">
                                        Follicule
                                    </li>
                                    <li [ngClass]="{ selectedLi:  examLaf?.laf.conj.oielDroit.granulomeconjonctivale.present==true}" (click)="addRemoveFromConjRight('Granulome conjonctivale')">
                                        Granulome conjonctivale
                                    </li>
                                    <li [ngClass]="{ selectedLi: examLaf?.laf.conj.oielDroit.hemorragiesousconjonctival.present==true }" (click)="addRemoveFromConjRight('Hémorragie sous conjonctivale')">
                                        Hémorragie sous conjonctivale
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.conj.oielDroit.hyperhemieconj.present==true}" (click)="addRemoveFromConjRight('Hyperhémie conjonctivale')">
                                        Hyperhémie conjonctivale
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi: examLaf?.laf.conj.oielDroit.icterique.present==true}" (click)="addRemoveFromConjRight('Ictérique')">
                                    Ictérique
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.conj.oielDroit.micropapille.present==true}" (click)="addRemoveFromConjRight('Micro-papille')">
                                    Micro-papille
                                </li>
                                <div *ngIf="showMoreConj">
                                    <li [ngClass]="{selectedLi: examLaf?.laf.conj.oielDroit.macropapille.present==true}" (click)="addRemoveFromConjRight('Macro-papille')">
                                        Macro-papille
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.conj.oielDroit.papillegeante.present==true}" (click)="addRemoveFromConjRight('Papille géante')">
                                        Papille géante
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.conj.oielDroit.Pemphigoide.present==true}" (click)="addRemoveFromConjRight('Pemphigoïde')">
                                        Pemphigoïde
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.conj.oielDroit.plaie.present==true}" (click)="addRemoveFromConjRight('Plaie')">
                                        Plaie
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.conj.oielDroit.ptgStade.present==true}" (click)="addRemoveFromConjRight('PTG(stade)')">
                                        PTG(stade)
                                    </li>
                                    <li [ngClass]="{ selectedLi: examLaf?.laf.conj.oielDroit.secretion.present==true}" (click)="addRemoveFromConjRight('Sécrétion')">
                                        Sécrétion
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.conj.oielDroit.symblepharon.present==true}" (click)="addRemoveFromConjRight('Symblépharon')">
                                        Symblépharon
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.conj.oielDroit.tangueLite.present==true}" (click)="addRemoveFromConjRight('Tangue lite')">
                                        Tangue lite
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.conj.oielDroit.tumeurconjonctivale.present==true}" (click)="addRemoveFromConjRight('Tumeur conjonctivale')">
                                        Tumeur conjonctivale
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorConj" [ngClass]="{'selectedLi': checkExistAnomalieConjRight(anomaly.id)}">
                                        <span (click)="addRemoveFromConjRightNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieConjRight(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-conj')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm-new addanaomalybtn" [(ngModel)]="newAnomalieConj" (keyup.enter)="addNewAnomalyConj($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreConjSection() *ngIf="!showMoreConj">Voir plus</span>
                            <span (click)=showMoreConjSection() *ngIf="showMoreConj">Voir moins</span>
                        </div>
                    </div>
                </div>
                <div class="cadreOeilGauche2">
                    <div class="oeilTitreSection">
                        <img src="assets/images/oeil.png" style="position: relative; top: 1px" class="img-oeil" />
                        <h5 id="OeilTitle">Oeil gauche</h5>
                        <br />
                    </div>

                    <div class="row" style="margin-top: 8px">
                        <br />
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:  examLaf?.laf.conj.oielGauche.normocoloreEtIntacte.present==true}" (click)="addRemoveFromConjLeft('Normocolore et intacte')">
                                    Normocolore et intacte
                                </li>
                                <li [ngClass]="{selectedLi:examLaf?.laf.conj.oielGauche.ankyloblepharon.present==true}" (click)="addRemoveFromConjLeft('Ankyloblepharon')">
                                    Ankyloblepharon
                                </li>
                                <div *ngIf="showMoreConj">
                                    <li [ngClass]="{ selectedLi:examLaf?.laf.conj.oielGauche.cercleperikeratique.present==true}" (click)="addRemoveFromConjLeft('Cercle périkératique')">
                                        Cercle périkératique
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.conj.oielGauche.Chemosis.present==true}" (click)="addRemoveFromConjLeft('Chémosis')">
                                        Chémosis
                                    </li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.conj.oielGauche.faussemembrane.present==true}" (click)="addRemoveFromConjLeft('Fausse membrane')">
                                        Fausse membrane
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.conj.oielGauche.fibroseconjonctival.present==true}" (click)="addRemoveFromConjLeft('Fibrose conjonctival')">
                                        Fibrose conjonctival
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.conj.oielGauche.follicule.present==true}" (click)="addRemoveFromConjLeft('Follicule')">
                                        Follicule
                                    </li>
                                    <li [ngClass]="{ selectedLi: examLaf?.laf.conj.oielGauche.granulomeconjonctivale.present==true}" (click)="addRemoveFromConjLeft('Granulome conjonctivale')">
                                        Granulome conjonctivale
                                    </li>
                                    <li [ngClass]="{ selectedLi: examLaf?.laf.conj.oielGauche.hemorragiesousconjonctival.present==true }" (click)="addRemoveFromConjLeft('Hémorragie sous conjonctivale')">
                                        Hémorragie sous conjonctivale
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.conj.oielGauche.hyperhemieconj.present==true}" (click)="addRemoveFromConjLeft('Hyperhémie conjonctivale')">
                                        Hyperhémie conjonctivale
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <ul>
                                <li [ngClass]="{selectedLi:  examLaf?.laf.conj.oielGauche.icterique.present==true}" (click)="addRemoveFromConjLeft('Ictérique')">
                                    Ictérique
                                </li>
                                <li [ngClass]="{selectedLi: examLaf?.laf.conj.oielGauche.micropapille.present==true}" (click)="addRemoveFromConjLeft('Micro-papille')">
                                    Micro-papille
                                </li>
                                <div *ngIf="showMoreConj">
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.conj.oielGauche.macropapille.present==true}" (click)="addRemoveFromConjLeft('Macro-papille')">
                                        Macro-papille
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.conj.oielGauche.papillegeante.present==true}" (click)="addRemoveFromConjLeft('Papille géante')">
                                        Papille géante
                                    </li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.conj.oielGauche.Pemphigoide.present==true}" (click)="addRemoveFromConjLeft('Pemphigoïde')">
                                        Pemphigoïde
                                    </li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.conj.oielGauche.plaie.present==true}" (click)="addRemoveFromConjLeft('Plaie')">
                                        Plaie
                                    </li>
                                    <li [ngClass]="{selectedLi:  examLaf?.laf.conj.oielGauche.ptgStade.present==true}" (click)="addRemoveFromConjLeft('PTG(stade)')">
                                        PTG(stade)
                                    </li>
                                    <li [ngClass]="{ selectedLi:  examLaf?.laf.conj.oielGauche.secretion.present==true}" (click)="addRemoveFromConjLeft('Sécrétion')">
                                        Sécrétion
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.conj.oielGauche.symblepharon.present==true}" (click)="addRemoveFromConjLeft('Symblépharon')">
                                        Symblépharon
                                    </li>
                                    <li [ngClass]="{selectedLi:examLaf?.laf.conj.oielGauche.tangueLite.present==true}" (click)="addRemoveFromConjLeft('Tangue lite')">
                                        Tangue lite
                                    </li>
                                    <li [ngClass]="{selectedLi: examLaf?.laf.conj.oielGauche.tumeurconjonctivale.present==true}" (click)="addRemoveFromConjLeft('Tumeur conjonctivale')">
                                        Tumeur conjonctivale
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <li *ngFor="let anomaly of anomaliesByDoctorConj" [ngClass]="{'selectedLi': checkExistAnomalieConjLeft(anomaly.id)}">
                                        <span (click)="addRemoveFromConjLeftNew(anomaly.titre,anomaly.id)">{{anomaly.titre}}</span>
                                        <img src="assets/images/delete.png" *ngIf="!checkExistAnomalieConjLeft(anomaly.id)" (click)="deleteAnomalie(anomaly,'laf-conj')" title="Supprimer l'anomalie" class="del-anomalie">
                                    </li>
                                    <!-- dynamic anamaly -->
                                    <div>
                                        <input type="text" placeholder="Ajouter une autre anomalie" id="newAnomaly" class="form-control input-sm-new addanaomalybtn" [(ngModel)]="newAnomalieConj" (keyup.enter)="addNewAnomalyConj($event)" [disabled]="isToastVisible">
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <br /><br />
                        <div class="showmore">
                            <span (click)=showMoreConjSection() *ngIf="!showMoreConj">Voir plus</span>
                            <span (click)=showMoreConjSection() *ngIf="showMoreConj">Voir moins</span>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div class="row blocinputs">
                <div class="col-md-6">
                    <div class="row" *ngIf="examLaf?.laf.conj.oielDroit.normocoloreEtIntacte.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Normocolore et intacte</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.normocoloreEtIntacte.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.conj.oielDroit.plaie.present==true">
                        <h5 class="titleDetails">Plaie</h5>
                        <div class="col-lg-6 col-md-6">
                            <label>Localisation</label>
                            <input type="text" class="form-control input-sm-new-new" [(ngModel)]="examLaf?.laf.conj.oielDroit.plaie.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label>Taille</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.conj.oielDroit.plaie.taille" (change)="onChangeconjtaillePlaieRight()"><label id="pos-mm">mm</label>
                        </div>
                        <div class="col-12">
                            <label>Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.plaie.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielDroit.ptgStade.present==true">
                        <h5 class="titleDetails">PTG (stade)</h5>
                        <div class="col-12">
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.conj.oielDroit.ptgStade.stade=='STADE 1: Ptérygion débutant'" (change)="selectConjptgStadeRight('STADE 1: Ptérygion débutant')" />
                            STADE 1: Ptérygion débutant<br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.conj.oielDroit.ptgStade.stade=='STADE 2: Ptérygion à mi distance du bord pupillaire'" (change)="selectConjptgStadeRight('STADE 2: Ptérygion à mi distance du bord pupillaire')" />
                            STADE 2: Ptérygion à mi distance du bord pupillaire<br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.conj.oielDroit.ptgStade.stade=='STADE 3: Ptérygion atteignant le bord pupillaire'" (change)="selectConjptgStadeRight('STADE 3: Ptérygion atteignant le bord pupillaire')" />
                            STADE 3: Ptérygion atteignant le bord pupillaire<br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.conj.oielDroit.ptgStade.stade==' STADE 4: Pterygion depassant le bord pupillaire'" (change)="selectConjptgStadeRight(' STADE 4: Pterygion depassant le bord pupillaire')" />
                            STADE 4: Pterygion depassant le bord pupillaire<br>
                        </div>
                        <div class="col-12">
                            <label>Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.ptgStade.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielDroit.tumeurconjonctivale.present==true">
                        <h5 class="titleDetails">Tumeur conjonctivale</h5>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.conj.oielDroit.tumeurconjonctivale.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Taille</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.conj.oielDroit.tumeurconjonctivale.taille" (change)="onChangeTumeurConjRight()">
                            <label id="pos-mm">mm</label>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label>Aspect</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.conj.oielDroit.tumeurconjonctivale.Aspect" />
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label>Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.tumeurconjonctivale.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielDroit.Chemosis.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Chémosis</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.Chemosis.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielDroit.hemorragiesousconjonctival.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Hémorragie sous conjonctivale</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.hemorragiesousconjonctival.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielDroit.secretion.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Sécrétion</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.secretion.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielDroit.micropapille.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Micro-papille</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.micropapille.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.conj.oielDroit.macropapille.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Macro-papille</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.macropapille.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielDroit.papillegeante.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Papille géante</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.papillegeante.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielDroit.follicule.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Follicule</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.follicule.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielDroit.faussemembrane.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Fausse membrane</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.faussemembrane.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielDroit.Pemphigoide.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Pemphigoïde</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.Pemphigoide.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielDroit.hyperhemieconj.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Hyperhémie conjonctivale</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.hyperhemieconj.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.conj.oielDroit.fibroseconjonctival.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Fibrose conjonctival</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.fibroseconjonctival.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.conj.oielDroit.icterique.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Ictérique</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.icterique.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielDroit.symblepharon.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Symblépharon</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.symblepharon.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielDroit.ankyloblepharon.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Ankyloblepharon</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.ankyloblepharon.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielDroit.cercleperikeratique.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Cercle périkératique</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.cercleperikeratique.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielDroit.granulomeconjonctivale.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Granulome conjonctivale</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielDroit.granulomeconjonctivale.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.conj.oielDroit.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}}</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->

                    <div class="row emptyBloc"></div>
                </div>

                <div class="col-md-6">
                    <div class="row" *ngIf="examLaf?.laf.conj.oielGauche.normocoloreEtIntacte.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Normocolore et intacte</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.normocoloreEtIntacte.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.conj.oielGauche.plaie.present==true">
                        <h5 class="titleDetails">Plaie</h5>
                        <div class="col-lg-6 col-md-6">
                            <label>Localisation</label>
                            <input type="text" class="form-control input-sm-new-new" [(ngModel)]="examLaf?.laf.conj.oielGauche.plaie.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label>Taille</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.conj.oielGauche.plaie.taille" (change)="onChangeconjtaillePlaieLeft()"><label id="pos-mm">mm</label>
                        </div>
                        <div class="col-12">
                            <label>Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.plaie.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielGauche.ptgStade.present==true">
                        <h5 class="titleDetails">PTG (stade)</h5>
                        <div class="col-12">
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.conj.oielGauche.ptgStade.stade=='STADE 1: Ptérygion débutant'" (change)="selectConjptgStadeLeft('STADE 1: Ptérygion débutant')" />
                            STADE 1: Ptérygion débutant<br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.conj.oielGauche.ptgStade.stade=='STADE 2: Ptérygion à mi distance du bord pupillaire'" (change)="selectConjptgStadeLeft('STADE 2: Ptérygion à mi distance du bord pupillaire')" />
                            STADE 2: Ptérygion à mi distance du bord pupillaire<br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.conj.oielGauche.ptgStade.stade=='STADE 3: Ptérygion atteignant le bord pupillaire'" (change)="selectConjptgStadeLeft('STADE 3: Ptérygion atteignant le bord pupillaire')" />
                            STADE 3: Ptérygion atteignant le bord pupillaire<br>
                            <input type="checkbox" class="radiotypeinput" [checked]="examLaf?.laf.conj.oielGauche.ptgStade.stade==' STADE 4: Pterygion depassant le bord pupillaire'" (change)="selectConjptgStadeLeft(' STADE 4: Pterygion depassant le bord pupillaire')" />
                            STADE 4: Pterygion depassant le bord pupillaire<br>
                        </div>
                        <div class="col-12">
                            <label>Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.ptgStade.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielGauche.tumeurconjonctivale.present==true">
                        <h5 class="titleDetails">Tumeur conjonctivale</h5>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Localisation</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.conj.oielGauche.tumeurconjonctivale.localisation">
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label for="">Taille</label>
                            <input type="number" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.conj.oielGauche.tumeurconjonctivale.taille" (change)="onChangeTumeurConjLeft()">
                            <label id="pos-mm">mm</label>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label>Aspect</label>
                            <input type="text" class="form-control input-sm-new" [(ngModel)]="examLaf?.laf.conj.oielGauche.tumeurconjonctivale.Aspect" />
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <label>Description</label>
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.tumeurconjonctivale.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielGauche.Chemosis.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Chémosis</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.Chemosis.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielGauche.hemorragiesousconjonctival.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Hémorragie sous conjonctivale</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.hemorragiesousconjonctival.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielGauche.secretion.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Sécrétion</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.secretion.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielGauche.micropapille.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Micro-papille</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.micropapille.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.conj.oielGauche.macropapille.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Macro-papille</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.macropapille.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielGauche.papillegeante.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Papille géante</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.papillegeante.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielGauche.follicule.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Follicule</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.follicule.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielGauche.faussemembrane.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Fausse membrane</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.faussemembrane.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielGauche.Pemphigoide.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Pemphigoïde</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.Pemphigoide.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielGauche.hyperhemieconj.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Hyperhémie conjonctivale</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.hyperhemieconj.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.conj.oielGauche.fibroseconjonctival.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Fibrose conjonctival</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.fibroseconjonctival.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf="examLaf?.laf.conj.oielGauche.icterique.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Ictérique</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.icterique.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielGauche.symblepharon.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Symblépharon</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.symblepharon.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielGauche.ankyloblepharon.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Ankyloblepharon</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.ankyloblepharon.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielGauche.cercleperikeratique.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Cercle périkératique</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.cercleperikeratique.description">
                        </div>
                    </div>
                    <div class="row margin-t-10" *ngIf=" examLaf?.laf.conj.oielGauche.granulomeconjonctivale.present==true">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">Granulome conjonctivale</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="examLaf?.laf.conj.oielGauche.granulomeconjonctivale.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->
                    <div class="row margin-t-10" *ngFor="let anomaly of examLaf?.laf.conj.oielGauche.allAnomalies">
                        <div class="col-lg-4 col-md-12">
                            <h5 class="titleDetails">{{anomaly.titre}}</h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <input type="text" class="form-control input-sm-new" placeholder="Ajouter une description..." [(ngModel)]="anomaly.description">
                        </div>
                    </div>
                    <!-- dynamique anomalies-->

                    <div class="row emptyBloc"></div>
                </div>
            </div>
        </div>
    </div>
</div>