<div class="row p-r-t-40" *ngIf="myExamen">
    <br><br>
    <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12">
        <p id="p-interrogatoire" [ngClass]="{'p-interrogatoire': isActiveConduiteAtenir}" (click)="blocConduiteAtenir()">Conduite à tenir</p>
        <p id="p-interrogatoire" [ngClass]="{'p-interrogatoire': isActiveExploration}" (click)="blocExploration()">Exploration</p>
        <p id="p-interrogatoire" [ngClass]="{'p-interrogatoire': isActiveCNAM}" (click)="blocCNAM()">CNAM</p>
    </div>
    <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 questions-zone">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <!--! document CAT -->
                <div *ngIf="isActiveConduiteAtenir">
                    <h6 id="title-cnam">Documents de conduite à tenir</h6>

                    <div *ngIf="myExamen.cat">
                        <div *ngIf="myExamen.cat.prescrireVerre.length==0 &&
                        myExamen.cat.prescrireLentille.length==0 &&
                        myExamen.cat.prescrireOrdo.length==0 &&
                        myExamen.cat.prescrireBoBilanOrtho.length==0 &&
                        myExamen.cat.chirurgie.length==0 &&
                        myExamen.cat.examenComplementaire.length==0 &&
                        myExamen.cat.prescrireLaser.length==0 &&
                        myExamen.cat.certificatMedical.length==0 &&
                        myExamen.cat.certificataptitudepermis.length==0 &&
                        myExamen.cat.lettreConfrere.length==0" class="ml-2">Aucun document n'est trouvé</div>

                        <div *ngFor="let prescription of myExamen.cat | keyvalue">
                            <div class="row m-b-10" *ngFor="let item of prescription.value">
                                <div class="row prescription col" (click)="viewPrescirption(item._id)">
                                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                        {{ item.date | date: 'dd-MM-yyyy' }}
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" *ngIf="item.titrePrecrire!=='Prescrire un certificat médical'">
                                        {{ item.titrePrecrire }}
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" *ngIf="item.titrePrecrire=='Prescrire un certificat médical'">
                                        {{ item.types }}
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <i class="preview fa-regular fa-eye" (click)="viewPrescirption(item._id)" title="Prévision"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--! end document CAT -->

                <!-- !exploration -->
                <div *ngIf="isActiveExploration">
                    <h6 id="title-cnam">Documents d'exploration</h6>
                    <p *ngIf="myExamen.documents && myExamen.documents.length ==0" class="ml-2">Aucun document n'est trouvé</p>
                    <!-- old listFiles -->
                    <div class="row m-b-10" *ngFor="let doc of myExamen.documents">
                        <div class="row prescription col" (click)="viewDoc(doc)">
                            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                {{doc.createdAt | date: 'dd-MM-yyyy'}}
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" style="cursor: pointer;">
                                {{doc.file}}
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <a><i class="preview fa-regular fa-eye mr-1" (click)="viewDoc(doc)" title="Prévision"></i></a>
                            <a><i class="preview fa-solid fa-trash ml-2" (click)="deleteOldFiles(doc)" title="Supprimer"></i></a>
                        </div>
                    </div>
                    <!-- old listFiles -->
                    <!--! file upload section -->
                    <div class="d-flex justify-content-center">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="m-t-60">
                                <label for="images" class="drop-container" id="dropcontainer" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
                                    <span class="drop-title">Déposer les fichiers ici</span>
                                    ou
                                    <input type="file" id="images" (change)="droppedFile($event)" multiple required>
                                    <label for="images" class="custom-file-upload">
                                        Choisir un fichier
                                    </label>
                                </label>
                            </div>
                        </div>
                    </div>
                    <!--! file upload section -->
                    <div class="d-flex justify-content-center" style="border:none;">
                        <button class="btn-next" (click)="saveDocuments()"><img src="assets/images/save.png" class="iconBoutton1"></button>
                    </div>
                </div>
                <!-- !end exploration -->

                <!-- ! CNAM -->
                <div *ngIf="isActiveCNAM">
                    <h6 id="title-cnam">Documents de CNAM</h6>
                    <!-- CNAM FILES-->

                    <div class="row m-b-10" *ngFor="let filecnam of filesCnam">
                        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12 prescription" title="Télécharger" (click)="openFileCnam(filecnam)">
                            {{filecnam.filename}}
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                            <i class="preview fa-solid fa-download" (click)="openFileCnam(filecnam)" title="Télécharger"></i>
                        </div>
                    </div>
                    <!-- CNAM FILES-->

                </div>
                <!-- !end CNAM -->
            </div>
        </div>

    </div>

</div>