export const typeConjonctive = {
    "titrePrecrire": "Prescrire une ordonnance type Conjonctive",
    'listDrugs': [
        {
            "nom": "Bitobra",
            "posology": "5/j",
            "duree": "07 jours",
        },
        {
            "nom": "Hyalufresh",
            "posology": "5/j",
            "duree": "07 jours",
        },
        {
            "nom": "Optifucine",
            "posology": "1 app/j",
            "duree": "07 jours",
        }
    ]
}
export const typePostOpCataracte = {
    "titrePrecrire": "Prescrire une ordonnance type post op Cataracte",
    'listDrugs': [
        {
            "nom": "Bitobra",
            "posology": "5/j",
            "duree": "01 mois",
        },
        {
            "nom": "Oflocol",
            "posology": "5/j",
            "duree": "10 jours",
        },
        {
            "nom": "Dicloabak",
            "posology": "1 app/j",
            "duree": "10 jours",
        },
        {
            "nom": "Stredex",
            "posology": "1 app/j",
            "duree": "10 jours",
        },
        {
            "nom": "Pansamaent oculaire",
            "duree": "10 jours",
        },
        {
            "nom": "Hyaluvisc free",
            "posology": "5/j",
            "duree": "02 mois",
        }
    ]
}
export const typeAllergie = {
    "titrePrecrire": "Prescrire une ordonnance type allergie",
    'listDrugs': [
        {
            "nom": "Naabak",
            "posology": "2/j",
            "duree": "01 mois",
        },
        {
            "nom": "Hyaluvisc free",
            "posology": "5/j",
            "duree": "03 mois",
        }
    ]
}
export const typeSechresseOculaire = {
    "titrePrecrire": "Prescrire une ordonnance type sécheresse oculairee",
    'listDrugs': [
        {
            "nom": "Théalose",
            "posology": "5/j",
            "duree": "06 mois",
        },
        {
            "nom": "Gel larme",
            "posology": "1 app/j",
            "duree": "06 mois",
        },
        {
            "nom": "Compresse chaude",
        }
    ]
}
export const typePostOpChirRefractive = {
    "titrePrecrire": "Prescrire une ordonnance type post op Chirurgie Réfractive",
    'listDrugs': [
        {
            "nom": "Oflocol",
            "posology": "3/j",
            "duree": "10 jours",
        },
        {
            "nom": "Théalose",
            "posology": "5/j",
            "duree": "06 mois",
        },
        {
            "nom": "Zotemax",
            "posology": "4/j",
            "duree": "01 mois",
        },
        {
            "nom": "Gel larme",
            "posology": "1 app/j",
            "duree": "01 mois",
        },
        {
            "nom": "Vitamine C 1000 mg",
            "posology": "1 cp/j",
            "duree": "06 mois",
        }
    ]
}