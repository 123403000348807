export const API_CONFIG = {
  apiPathBase: '/',
  // apiPathBase: 'http://localhost:3012/',
  patientByEmail: 'api/patientByEmail',
  register: 'api/register',
  profile: 'api/profile',
  authenticate: 'api/authenticate',
  authenticateMedecin: 'api/authenticate/medecin',
  checkValidation: 'api/check/validation/auth/medecin',
  authenticateSecretaire: 'api/authenticate/secretaire',
  profilMedecin: 'api/profilMedecin',
  profilSecretaire: 'api/profilSecretaire',
  certificat: 'api/certificat',
  consultation: 'api/consultation',
  medecin: 'api/medecin',
  medecinScraped: 'api/medecinScraped',
  ordonnance: 'api/ordonnance',
  patients: 'api/patients',
  patient: 'api/patient',
  rdvs: 'api/rdvs',
  secretaire: 'api/secretaire',
  agenda: 'api/agenda',
  upload: 'api/upload',
  document: 'api/document',
  event: 'api/event',
  pays: 'api/pays',
  image: 'api/image',
  messages: 'api/message',
  medicament: 'api/medicament',
  notification: 'api/notification',
  recaptchaverification: 'api/verify-recaptcha',
  uploadArticle: 'data/files/articles',
  examen: 'api/examen',
  anomalie: 'api/anomalie',
  connectRestApi: 'connect/rest/api',
  motif: 'api/motif',
  medical: 'api/medical',
  traitementEnCours: 'api/traitementEnCours',
  chirugie: 'api/chirugie',
  antecedentParLaser: 'api/antecedentParLaser',
  typeAntecedentFamiliaux: 'api/typeAntecedentFamiliaux',
  habitudeVie: 'api/habitudeVie',
  antecedentGeneral: 'api/antecedentGeneral',
  periodeChirugie: 'api/periodeChirugie',
  catByDoctor: 'api/catByDoctor',
  professions: 'api/professions'
};
export function _getURL(serviceName) {
  return API_CONFIG.apiPathBase + serviceName;
}
// export const loginPatient = 'https://www.patient.medivisto.com/#/login'
// export const uploadFolderRootMedecin = 'http://localhost:3012/api/uploads/medecin/'
// export const uploadFolderRootSecretaire = 'http://localhost:3012/api/uploads/secretaire/'
// export const uploadFolderRootDocument = 'http://localhost:3012/api/uploads/documents/'
// export const uploadFolderRootDocumentPatient = 'https://file-test.medivisto.fr/'

export const loginPatient = 'https://www.patient.medivisto.com/#/login'
export const uploadFolderRootMedecin = _getURL('') + '/uploads/medecin/'
export const uploadFolderRootSecretaire = _getURL('') + '/uploads/secretaire/'
export const uploadFolderRootDocument = _getURL('') + '/uploads/documents/'
export const uploadFolderRootDocumentPatient = _getURL('') + 'data/files/documentsPatient/'
export const redirectNotAuthenticated = 'http://www.website.medivisto.com/connexion'

